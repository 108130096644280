// src/store/slices/permissionsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchPermissions = createAsyncThunk(
  'permissions/fetchPermissions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/permissions');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
  },
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    clearPermissions: (state) => {
      state.permissions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setPermissions, clearPermissions } = permissionsSlice.actions;
export const selectPermissions = (state) => state.permissions.data;


export default permissionsSlice.reducer;