import React, { useState, useEffect, forwardRef, useRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import MaskedInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "../../../../common/helper";

const CustomInput = forwardRef(
  ({ isRequired, isFilled, value, onChange, onBlur, ...props }, ref) => {
    const handleInputChange = (e) => {
      const newValue = e.target.value;
      onChange(e);

      if (newValue === "" || newValue.replace(/[^0-9]/g, "") === "") {
        onChange(null);
      }
    };

    const handleBlur = (e) => {
      const newValue = e.target.value;
      if (
        newValue.includes("_") ||
        newValue.replace(/[^0-9]/g, "").length !== 8
      ) {
        onChange(null);
      }
      if (onBlur) {
        onBlur(e);
      }
    };

    return (
      <MaskedInput
        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        {...props}
        ref={ref}
        required={isRequired}
        value={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
    );
  }
);

function GraphCommonDatePicker({
  calName,
  CalVal,
  HandleChange,
  disabled = false,
  isRequired = false,
  minDate,
  maxDate,
  otherDate = null,
  isEndDate = false,
}) {
  const [DateValueState, setDateValueState] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const inputRef = useRef(null);

  // Get today's date at midnight
  const getTodayDate = () => {
    const today = moment().startOf("day");
    return today;
  };

  // Get default start date (15 days before today)
  const getDefaultStartDate = () => {
    return moment().subtract(15, "days").startOf("day");
  };

  // Initialize with default values
  useEffect(() => {
    if (!CalVal) {
      const defaultDate = isEndDate ? getTodayDate() : getDefaultStartDate();
      handleCalendarChange(defaultDate.toDate());
    }
  }, []);

  const handleCalendarChange = (value) => {
    if (!value) {
      HandleChange(calName, null);
      setDateValueState(null);
      setIsFilled(false);
      return;
    }

    const selectedDate = moment(value);
    const today = getTodayDate();

    // Don't allow future dates
    if (selectedDate.isAfter(today)) {
      HandleChange(calName, today.format("YYYY-MM-DD"));
      setDateValueState(today.toDate());
      setIsFilled(true);
      setIsOpen(false);
      return;
    }

    if (isEndDate) {
      // For end date selection, set start date to 15 days before
      const newStartDate = moment(selectedDate).subtract(15, "days");
      HandleChange("startDate", newStartDate.format("YYYY-MM-DD"));
    } else {
      // For start date selection, set end date to 15 days after
      let newEndDate = moment(selectedDate).add(15, "days");

      // If new end date would be after today, set it to today
      if (newEndDate.isAfter(today)) {
        newEndDate = today;
      }
      HandleChange("endDate", newEndDate.format("YYYY-MM-DD"));
    }

    HandleChange(calName, selectedDate.format("YYYY-MM-DD"));
    setDateValueState(selectedDate.toDate());
    setIsFilled(true);
    setIsOpen(false);
  };

  // Filter dates for the calendar
  const filterDate = (date) => {
    const selectedDate = moment(date);
    const today = getTodayDate();

    // Don't allow future dates
    if (selectedDate.isAfter(today)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!CalVal || CalVal === "unknown") {
      setDateValueState(null);
      setIsFilled(false);
    } else {
      const parsedDate = moment(CalVal);
      if (parsedDate.isValid()) {
        setDateValueState(parsedDate.toDate());
        setIsFilled(true);
      } else {
        setDateValueState(null);
        setIsFilled(false);
      }
    }
  }, [CalVal]);

  return (
    <div
      className={`d-flex align-items-center calendar-input-btn calendar-input-btn-1012 ${
        disabled ? "disabled-date" : ""
      }`}
      style={{
        border: isRequired && !isFilled ? "1px solid red" : "",
      }}
    >
      <DatePicker
        open={isOpen}
        selected={DateValueState}
        onChange={handleCalendarChange}
        inputRef={inputRef}
        filterDate={filterDate}
        disabled={disabled}
        onClickOutside={() => setIsOpen(false)}
        shouldCloseOnSelect={true}
        maxDate={getTodayDate().toDate()}
        customInput={
          <CustomInput
            inputRef={inputRef}
            disabled={disabled}
            isRequired={isRequired}
            isFilled={isFilled}
          />
        }
      />

      <span
        className="cl-name"
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <CalendarIcon />
      </span>
    </div>
  );
}

export default GraphCommonDatePicker;
