import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoutes() {
  const [auth, setAuth] = useState(!!(localStorage.getItem("ross_token") || localStorage.getItem("ross_rtoken")));

  useEffect(() => {
    const handleAuthChange = () => {
      setAuth(!!(localStorage.getItem("ross_token") || localStorage.getItem("ross_rtoken")));
    };

    window.addEventListener("storage", handleAuthChange);
    return () => window.removeEventListener("storage", handleAuthChange);
  }, []);

  return auth ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoutes;
