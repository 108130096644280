
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { FormControlLabel, Radio, Switch } from "@mui/material";

function ContactModalEmail({SaveForm,open,hanldeModal,emailDataList,phoneDataList,dataType,setSubFormData,setDataType,setLoading}) {

    const handleClose = ()=>{
        hanldeModal(false)
        setDataType('')
        setLoading(false)
    }

const updateFieldChanged = index => e =>{

  let newArr = [ ...emailDataList ]; // copying the old datas array


  newArr.map((data,i) => {
    if(index===i){
      data.account_main_contact_email_main = 1;
    }else{
      data.account_main_contact_email_main = 0;
      }
  });



  setSubFormData(newArr);
}

const handleContinue = ()=>{
    handleClose();
    SaveForm();   
}

  return (
    <>

<Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Set Main Email Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <ul>
         {emailDataList.map((single,index)=>(
            <li key={index} > 

            <Form.Group className={ 'col d-block' }>
              <b className={ '' }>Main</b>
              <div className="">
                <FormControlLabel
                  className={ '' }
                  label={`${single.account_main_contact_email}`}
                  value={true}
                  name="main"
                  onChange={ updateFieldChanged(index) }
                  control={ <Radio color="primary" size="medium" checked={single.account_main_contact_email_main} /> }
                />

                
              </div>
            </Form.Group>

        </li>
        ))}
         
         </ul>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={handleContinue}>
          Continue
          </Button>
        </Modal.Footer>
      </Modal>

{/* <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box sx={{ ...style, width: 400 }}>
    {dataType==='Phone' ? <>
        <div className='' >
    {JSON.stringify(phoneDataList)}
        </div>
    </> : <>
    {JSON.stringify(emailDataList)}
    
    </>}
  </Box>
</Modal> */}


    </>
  )
}

export default ContactModalEmail;