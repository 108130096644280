import React, { useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { DecryptToken } from "../../../../common/helper";
import { addNewBreadcrumbs, addNewBreadcrumbsMethod } from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch, useSelector } from "react-redux";

const AedAssignedCard = ({ sections }) => {
  const [aedsAssigned, setAedsAssigned] = useState({});
  const navigate = useNavigate();
  const user = DecryptToken();
  const dispatch = useDispatch();
  const id = "aedsAssigned";
  const account = findSectionById("accounts", sections);

  const handleDetailsDispatch = (type) => {
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: "",
    };
    
    const handleDispatch = () => {
      BreadCrumbsObject.title = "Dashboard";
      dispatch(
        addNewBreadcrumbsMethod({ ...BreadCrumbsObject, url: "/dashboard" })
      );
    };
    handleDispatch();
    if (!account?.account_id) {
      if (Number(isPermission({ type: "dashboard", name: "AccountListing" })) === 1) {
        BreadCrumbsObject.title = "Account Listing";
        dispatch(
          addNewBreadcrumbsMethod({
            ...BreadCrumbsObject,
            url: "/accounts-listing",
          })
        );
      }
    } else if (aedsAssigned?.primary > 1 || aedsAssigned?.backup > 1) {
        BreadCrumbsObject.title = `Equipment`;
        user?.assign_aed &&
        dispatch(
          addNewBreadcrumbs({
            ...BreadCrumbsObject,
            url: `/account-details/${account?.account_id}/Equipment`,
          })
        );
    } else if(Number(aedsAssigned?.primary) === 1 || Number(aedsAssigned?.backup) === 1) {
        BreadCrumbsObject.title = `AED Name Details`;
        if (user?.assign_aed && type === "primary") {
          dispatch(
            addNewBreadcrumbs({
              ...BreadCrumbsObject,
              url: `/account/aed-details/${aedsAssigned?.primary_aed_id}/Details`,
            })
          );
        } else {
          dispatch(
            addNewBreadcrumbs({
              ...BreadCrumbsObject,
              url: `/account/aed-details/${aedsAssigned?.backup_aed_id}/Details`,
            })
          );
        }
      }
  };

  const navigateAedAssigned = (type) => {
    if (!account?.account_id) {
      if (Number(isPermission({ type: "dashboard", name: "AccountListing" })) === 1) {
        handleDetailsDispatch();
        navigate("/accounts-listing");
      }
    } else if(aedsAssigned?.primary > 1 || aedsAssigned?.backup > 1) {
      user?.assign_aed &&
      handleDetailsDispatch();
      navigate(`/account-details/${account?.account_id}/Equipment`, {
        state: {
          from: "dashboard",
        },
      });
    } else if(Number(aedsAssigned?.primary) === 1 || Number(aedsAssigned?.backup) === 1) {
      if (user?.assign_aed && type === "primary") {
        handleDetailsDispatch(type);
        navigate(`/account/aed-details/${aedsAssigned?.primary_aed_id}/Details`);
      } else {
        handleDetailsDispatch(type);
        navigate(`/account/aed-details/${aedsAssigned?.backup_aed_id}/Details`);
      }
    } 
  };

  // const navigateAedAssigned = (type) => {
  //   if (!account?.account_id) {
  //     Number(isPermission({ type: "dashboard", name: "AccountListing" })) ===
  //       1 && navigate("/accounts-listing");
  //   } else if (aedsAssigned?.primary > 1 || aedsAssigned?.backup > 1) {
  //     user?.assign_aed &&
  //       navigate(`/account-details/${account?.account_id}/Equipment`, {
  //         state: {
  //           from: "dashboard",
  //         },
  //       });
  //   } else if (
  //     Number(aedsAssigned?.primary) === 1 ||
  //     Number(aedsAssigned?.backup) === 1
  //   ) {
  //     user?.assign_aed && type === "primary"
  //       ? navigate(
  //           `/account/aed-details/${aedsAssigned?.primary_aed_id}/Details`
  //         )
  //       : navigate(
  //           `/account/aed-details/${aedsAssigned?.backup_aed_id}/Details`
  //         );
  //   }
  // };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setAedsAssigned(matched);
  }, [sections]);

  return (
    <div className="p-4 rounded-lg">
      <h3
        className=" font-bold mb-2 text-center"
        style={{
          fontFamily: "arial",
          fontWeight: "700",
        }}
      >
        Aeds Assigned
      </h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          justifyContent: "center",
          gap: "5%",
          marginTop: "2%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3 
            id="aed-assigned-card"
            className=" font-bold mb-2 text-center"
            style={{
              cursor: Number(aedsAssigned?.primary) > 0 ? "pointer" : "",
            }}
            onClick={() =>
              Number(aedsAssigned?.primary) > 0 &&
              navigateAedAssigned("primary")
            }
          >
            {aedsAssigned?.primary}
          </h3>
          <h6 className="mt-2 mb-2 text-center">Primary</h6>
        </div>

        <div
          style={{
            width: "2px",
            height: "70px",
            backgroundColor: "black",
            margin: "0 10px",
          }}
        />

        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3
            className=" font-bold mb-2 text-center"
            style={{
              cursor: Number(aedsAssigned?.backup) > 0 ? "pointer" : "",
            }}
            onClick={() =>
              Number(aedsAssigned?.backup) > 0 && navigateAedAssigned("backup")
            }
          >
            {aedsAssigned?.backup}
          </h3>
          <h6 className="mt-2 mb-2 text-center">Backup</h6>
        </div>
      </div>
    </div>
  );
};

export default AedAssignedCard;
