import React, { useState } from "react";
import { EditIcon } from "../../../../../common/helper/icons";
import EditMoveAedPlacement from "../EditMoveAedPlacement";

function MoveAedTr({
  item,
  index,
  handleCheckboxChange,
  selectedIds,
  onLoad,
  setAedSiteData,
}) {
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null); // Added state to track the current item

  const handleEditClick = () => {
    setCurrentItem(item); // Set the current item when clicking edit
    setShowModal(true); // Open the modal
  };

  return (
    <>
      <tr key={item.aed_id}>
        <td>
          <label htmlFor={"label_" + item.aed_id}>
            <input
              type="checkbox"
              checked={selectedIds.includes(item.aed_id)}
              onChange={() => handleCheckboxChange(item.aed_id)}
            />{" "}
            &nbsp;
            <span>
              {item.aed_brand_model} 
              {/* {item.aed_id} */}
            </span>
          </label>
        </td>
        <td
          className=""
          style={{
            wordWrap: "break-word",
            overflow: "hidden",
            maxWidth: "150px",
          }}
        >
          {item.serial_number}
        </td>
        <td>
          <div className="d-flex justify-content-between align-content-center">
            <span>{item.placement} </span>
            <button
              type="button"
              className="btn btn-link ml-auto"
              style={{ width: "50px" }}
              onClick={handleEditClick}
            >
              <EditIcon />
            </button>
          </div>
        </td>
      </tr>

      {currentItem && (
        <EditMoveAedPlacement
          show={showModal}
          id={currentItem.aed_id}
          onHide={() => {
            setShowModal(false);
            setAedSiteData([]);
            onLoad();
          }}
          aedDetails={currentItem.placement}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
}

export default MoveAedTr;
