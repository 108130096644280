// // src/components/ProtectedRoute.js
// import React from 'react';
// import {  Route, Routes } from 'react-router-dom';
// import AccountCreate from '../domains/accountManagement/pages/AccountCreate';
// import { withPermissionAndBreadcrumbs } from '../common/hooks/withPermissionAndBreadcrumbs';

import Layout from "../common/components/layout";
import Dashboard from "../domains/dashboard/pages/Dashboard";
import AccountsListing from "../domains/accounts/pages/accounts-listing";
import AdminAccount from "../domains/accounts/pages/AdminAccount";
import AdminSiteEdit from "../domains/sitesManagments/pages/AdminSiteEdit";
import AdminSiteNew from "../domains/sitesManagments/pages/AdminSiteNew";
import SiteMain from "../domains/sitesManagments/pages/SiteMain";
import SitePage from "../domains/sitesManagments/pages";
import EquipmentListing from "../domains/EquipmentManagement/pages/EquipmentListing";
import SiteTrainingNew from "../domains/sitesManagments/pages/SiteTrainingNew";
import PopDetails from "../domains/PopManagement/Pages/PopDetails";
import PopEdit from "../domains/PopManagement/Pages/PopEdit";
import AccountDetails from "../domains/accounts/pages/account-details";
import AccountEdit from "../domains/accounts/pages/account-edit";
import ContactDetails from "../domains/contactManagement/pages/ContactMain";
import EditContact from "../domains/contactManagement/pages/EditContact";
import AddNewContacts from "../domains/contactManagement/pages/AddNewContacts";
import NewStandloneAcce from "../domains/Accessories/pages/NewStandloneAcce";
import SiteTrainingEdit from "../domains/sitesManagments/pages/SiteTrainingEdit";
import SitesListing from "../domains/sitesManagments/pages/SitesListing";
import ContactListing from "../domains/contactManagement/pages/ContactListing";
import AccessoriesListing from "../domains/Accessories/pages/AccessoriesListing";
import AedMain from "../domains/Aed/AedDetails/pages/AedMain";
import MoveAed from "../domains/Aed/MoveAed/pages/MoveAed";
import EditAed from "../domains/Aed/EditAed/pages/EditAed";
import NewAed from "../domains/Aed/NewAed/pages";
import NewPop from "../domains/PopManagement/Pages/NewPop";
import MoveAccessory from "../domains/Aed/MoveAccessory/pages/MoveAccessory";
import AedCheckSelect from "../domains/Aed/AedDetails/components/AedCheckSelect";
import AEDChecksDetails from "../domains/Aed/AedDetails/components/AEDChecksDetails";
import PopList from "../domains/accounts/components/tabs/PopList";
import Pops from "../domains/accounts/components/tabs/Pops";
import AssignAed from "../domains/Aed/AssignAed/pages/AssignAed";
import EditAssignAed from "../domains/Aed/AssignAed/pages/EditAssignedAed";
import AedServiceCheck from "../domains/Aed/ServiceCheck/pages/AedServiceCheck";
import AedServiceCheckService1 from "../domains/Aed/ServiceCheck/pages/AedServiceCheckService1";
import AedServiceCheckService2 from "../domains/Aed/ServiceCheck/pages/AedServiceCheckService2";
import AEDServiceDetails from "../domains/Aed/AedDetails/components/aedService/AEDServiceDetails";
import UserDashboard from "../domains/dashboard/pages/UserDashboard";
import UserProfile from "../domains/profile/pages/UserProfile";
import AdminDetails from "../domains/Support/Pages/Admin/Details";
import NewSupport from "../domains/Support/Component/New-Support";
import AssignAdmin from "../domains/permissionManager/pages/AssignAdmin";
import MultipleAccount from "../domains/permissionManager/pages/MultipleAccount";
import CreateRole from "../domains/permissionManager/pages/CreateRole";
import RoleToUser from "../domains/permissionManager/pages/RoleToUser";
import RoleToPosition from "../domains/permissionManager/pages/RoleToPosition";
import SupportDetails from "../domains/Support/Component/Support-details";
import SupportListing from "../domains/Support/Component/Support-Listing";
import Support from "../domains/Support/Component/SupportTab/Support";
import AdminListing from "../domains/Support/Pages/Admin/Listing";
import NewDahsboard from "../domains/NewDashboard/pages/newDashboard";
import NewNote from "../domains/Notes/Component/New-Note";
import NoteListing from "../domains/Notes/Component/Note-Listing";
import NoteDetails from "../domains/Notes/Component/Note-Details";
import EditNote from "../domains/Notes/Component/Note-Edit";
import SideEquipmentList from "../domains/EquipmentManagement/pages/SideEquipmentList";
import DragDropContainer from "../domains/DNDDashboard/pages/DNDDashboard";
import DNDDashboard from "../domains/DNDDashboard/pages/DNDDashboard";
import Dahsboard from "../domains/DNDDashboard/pages/dashboard";

import AedCheckCardListing from "../domains/Aed/AedDetails/AedCheck/Pages/AedCheckCardListing";
import { Route } from "react-router-dom";
import EmailDetails from "../domains/Email/pages/EmailDetails";
import AccountDocumentUpload from "../domains/Documnents/components/AccountDocumentUpload";
import AccountDocumentDetails from "../domains/Documnents/components/AccountDocumentDetails";
import AccountDocumentEdit from "../domains/Documnents/components/AccountDocumentEdit";

// const ProtectedRoute = () => {

//   return (
//     <Routes>
//       <Route path="/dashboard" element={withPermissionAndBreadcrumbs(AccountCreate,"dashboard")()} />
//       <Route path="/account/create" element={withPermissionAndBreadcrumbs(AccountCreate,"dashboard")()} />
//       <Route path="/account/edit/:accont_id" element={withPermissionAndBreadcrumbs(AccountCreate,"dashboard")()} />
//       <Route path="/account/details/:accont_id" element={withPermissionAndBreadcrumbs(AccountCreate,"dashboard")()} />
//       <Route path="/account/listing/" element={withPermissionAndBreadcrumbs(AccountCreate,"dashboard")()} />
//     </Routes>
//   )
// };

// export default ProtectedRoute;

const ProtectedRoute = [
  {
    title: "Dashboard",
    path: "/dashboard",
    component: (
      <Layout>
        <Dahsboard />
      </Layout>
    ),
  },
  {
    title: "Dashboard",
    path: "/user-dashboard",
    component: (
      <Layout>
        <Dahsboard />
      </Layout>
    ),
  },
  {
    title: "NewDashboard",
    path: "/new-dashboard",
    component: (
      <Layout>
        <NewDahsboard />
      </Layout>
    ),
  },
  {
    title: "DNDDashboard",
    path: "/DND-dashboard",
    component: (
      <Layout>
        <Dahsboard />
      </Layout>
    ),
  },
  {
    title: "UserProfile",
    path: "/user-profile/:contactId",
    component: (
      <Layout>
        <UserProfile />
      </Layout>
    ),
  },
  {
    title: "Accounts",
    path: "/accounts-listing",
    component: (
      <Layout>
        <AccountsListing />
      </Layout>
    ),
  },
  {
    title: "New Standlone Acce",
    path: "/admin-account",
    component: (
      <Layout>
        <AdminAccount />
      </Layout>
    ),
  },
  {
    title: "Account Details",
    path: "/account-details/:accountId",
    component: (
      <Layout>
        <AccountDetails />
      </Layout>
    ),
  },
  {
    title: "Account Details",
    path: "/account-details/:accountId/:tab",
    component: (
      <Layout>
        <AccountDetails />
      </Layout>
    ),
  },
  {
    title: "Account Edit",
    path: "/account/accounts-edit/:accountId",
    component: (
      <Layout>
        <AccountEdit />
      </Layout>
    ),
  },
  {
    title: "Account Edit",
    path: "/account/accounts-edit/:accountId/:tab",
    component: (
      <Layout>
        <AccountEdit />
      </Layout>
    ),
  },
  {
    title: "Account-Create",
    path: "/account/create",
    component: (
      <Layout>
        <Dashboard />
      </Layout>
    ),
  },
  {
    title: "Account-Update",
    path: "/account/update/:account_id",
    component: (
      <Layout>
        <Dashboard />
      </Layout>
    ),
  },
  {
    title: "Account-Details",
    path: "/account/details/:account_id",
    component: (
      <Layout>
        <Dashboard />
      </Layout>
    ),
  },

  // Sites path
  {
    title: "Site-Create",
    path: "/admin-siteNew/:accountId",
    component: (
      <Layout>
        <AdminSiteNew />
      </Layout>
    ),
  },
  {
    title: "Site-Details",
    path: "/account/site-details/:siteId",
    component: (
      <Layout>
        <SiteMain />
      </Layout>
    ),
  },
  {
    title: "Site-Details",
    path: "/account/site-details/:siteId/:tab",
    component: (
      <Layout>
        <SiteMain />
      </Layout>
    ),
  },
  {
    title: "Site-Edit",
    path: "/admin-siteEdit/:accountId/:siteId",
    component: (
      <Layout>
        <AdminSiteEdit />
      </Layout>
    ),
  },
  {
    title: "Site-Edit",
    path: "/admin-siteEdit/:accountId/:siteId/:tab",
    component: (
      <Layout>
        <AdminSiteEdit />
      </Layout>
    ),
  },
  {
    title: "Site-Training-New",
    path: "/account/:accountId/site/:siteId/training/new",
    component: (
      <Layout>
        {" "}
        <SiteTrainingNew />{" "}
      </Layout>
    ),
  },
  {
    title: "Site-Training-Edit",
    path: "/account/:accountId/site/:siteId/:DetailID/training/edit",
    component: (
      <Layout>
        {" "}
        <SiteTrainingEdit />{" "}
      </Layout>
    ),
  },
  {
    title: "Sites Management",
    path: "/sites/",
    component: (
      <Layout>
        <SitePage />
      </Layout>
    ),
  },
  {
    title: "Sites Listing",
    path: "/Admin/Sites-listing",
    component: (
      <Layout>
        <SitesListing />
      </Layout>
    ),
  },

  // Contacts Path
  {
    title: "Contact-Create",
    path: "/account/contacts/new/:accountId",
    component: (
      <Layout>
        <AddNewContacts />
      </Layout>
    ),
  },
  {
    title: "Contact-Details",
    path: "/account/:accountId/contact-details/:contactId",
    component: (
      <Layout>
        <ContactDetails />
      </Layout>
    ),
  },
  {
    title: "Contact-Details",
    path: "/account/:accountId/contact-details/:contactId/:tab",
    component: (
      <Layout>
        <ContactDetails />
      </Layout>
    ),
  },
  {
    title: "Contact-Edit",
    path: "/account/contact-details-edit/:contactId",
    component: (
      <Layout>
        <EditContact />
      </Layout>
    ),
  },
  {
    title: "Contact Listing",
    path: "/Admin/Contact-listing",
    component: (
      <Layout>
        <ContactListing />
      </Layout>
    ),
  },

  // Aed Equipments
  {
    title: "AED-Listing",
    path: "/aed/:accountId",
    component: (
      <Layout>
        <EquipmentListing />
      </Layout>
    ),
  },
  {
    title: "AED-Listing",
    path: "/Admin/Aed-listing",
    component: (
      <Layout>
        <SideEquipmentList />
      </Layout>
    ),
  },
  {
    title: "AED-New",
    path: "/account/new/aed/:accountId/:site_id",
    component: (
      <Layout>
        {" "}
        <NewAed />{" "}
      </Layout>
    ),
  },
  {
    title: "AED-New",
    path: "/account/new/aed/:accountId/:site_id/:tab",
    component: (
      <Layout>
        {" "}
        <NewAed />{" "}
      </Layout>
    ),
  },
  {
    title: "AedDetails",
    path: "/move-aed/:accountId/:siteId",
    component: (
      <Layout>
        {" "}
        <MoveAed />{" "}
      </Layout>
    ),
  },
  {
    title: "Move-Accessory",
    path: "/move-accessory/:accountId/:siteId",
    component: (
      <Layout>
        {" "}
        <MoveAccessory />{" "}
      </Layout>
    ),
  },
  {
    title: "AED-Edit",
    path: "/account/aed/edit/:aedId/:siteId",
    component: (
      <Layout>
        {" "}
        <EditAed />{" "}
      </Layout>
    ),
  },
  {
    title: "AED-Assigned",
    path: "/assign-quipment/:accountId",
    component: (
      <Layout>
        {" "}
        <AssignAed />{" "}
      </Layout>
    ),
  },
  {
    title: "AED-Assigned",
    path: "/assign-quipment/:accountId/:siteId",
    component: (
      <Layout>
        {" "}
        <AssignAed />{" "}
      </Layout>
    ),
  },
  {
    title: "AED-Assigned-Edit",
    path: "/account/assign-equipment/edit/:siteId",
    component: (
      <Layout>
        {" "}
        <EditAssignAed />{" "}
      </Layout>
    ),
  },
  {
    title: "Aed Service Check",
    path: "/account/aed/service-check/:accountId/:siteId",
    component: (
      <Layout>
        {" "}
        <AedServiceCheck />{" "}
      </Layout>
    ),
  },

  {
    title: "Aed Check card List",
    path: "/account/aed/aed-check-card",
    component: (
      <Layout>
        {" "}
        <AedCheckCardListing />{" "}
      </Layout>
    ),
  },
  {
    title: "Aed Service Check Service1",
    path: "/account/aed/service-check/service1/:accountId/:siteId/:aedId",
    component: (
      <Layout>
        <AedServiceCheckService1 />
      </Layout>
    ),
  },
  {
    title: "Aed Service Check Service2",
    path: "/account/aed/service-check/service2/:serviceQuestionID",
    component: (
      <Layout>
        <AedServiceCheckService2 />
      </Layout>
    ),
  },

  // Aed Details
  {
    title: "AedDetails",
    path: "/account/aed-details/:aedId",
    component: (
      <Layout>
        <AedMain />
      </Layout>
    ),
  },
  {
    title: "AedDetails",
    path: "/account/aed-details/:aedId/:tab",
    component: (
      <Layout>
        <AedMain />
      </Layout>
    ),
  },
  {
    title: "AedCheckSelect",
    path: "/account/aed-details/check-select",
    component: (
      <Layout>
        <AedCheckSelect />{" "}
      </Layout>
    ),
  },
  {
    title: "AEDChecksDetails",
    path: "/account/aed-checks-details/:aedId/:checkId",
    component: (
      <Layout>
        <AEDChecksDetails />{" "}
      </Layout>
    ),
  },
  {
    title: "AEDChecksDetails",
    path: "/account/aed-checks-details/:aedId/:checkId/:tab",
    component: (
      <Layout>
        <AEDChecksDetails />{" "}
      </Layout>
    ),
  },
  {
    title: "AED Service Details",
    path: "/account-details/AEDServiceDetails/:aedId/:serviceQuestionId",
    component: (
      <Layout>
        <AEDServiceDetails />,
      </Layout>
    ),
  },

  // account/aed/NewStandloneAcce
  {
    title: "New Standalone",
    path: "/account/aed/NewStandloneAcce/:accountId/:siteId",
    component: (
      <Layout>
        <NewStandloneAcce />
      </Layout>
    ),
  },
  {
    title: "Accessory Listing",
    path: "/Admin/accessory-listing",
    component: (
      <Layout>
        <AccessoriesListing />
      </Layout>
    ),
  },

  // pop
  // { title: "Pop List", path: "/account/new-pop", component: <Layout><Pops /></Layout> },
  {
    title: "New Pop",
    path: "/account/new-pop/:accountId",
    component: (
      <Layout>
        <NewPop />
      </Layout>
    ),
  },
  {
    title: "New Pop",
    path: "/account/new-pop/:accountId?:tab",
    component: (
      <Layout>
        <NewPop />
      </Layout>
    ),
  },
  {
    title: "pop-Details",
    path: "pop/get-pop-performance/:popId/:accountId",
    component: (
      <Layout>
        <PopDetails />
      </Layout>
    ),
  },
  {
    title: "pop-Details",
    path: "pop/get-pop-performance/:popId/:accountId/:tab",
    component: (
      <Layout>
        <PopDetails />
      </Layout>
    ),
  },
  {
    title: "pop-Edit",
    path: "/account/pop-details-edit/:popId/:accountId",
    component: (
      <Layout>
        <PopEdit />
      </Layout>
    ),
  },
  {
    title: "pop-List",
    path: "/pop/pop-list-by-account/:accountId",
    component: (
      <Layout>
        <Pops />
      </Layout>
    ),
  },
  //support
  {
    title: "support-Listing",
    path: "/account/support-listing",
    component: (
      <Layout>
        <AdminListing
          method={"POST"}
          support_api={"support/support-list"}
          isSearchable={true}
        />
      </Layout>
    ),
  },
  {
    title: "support-New",
    path: "/account/new-support/",
    component: (
      <Layout>
        <NewSupport />
      </Layout>
    ),
  },
  {
    title: "support-New",
    path: "/account/new-support/:tab",
    component: (
      <Layout>
        <NewSupport />
      </Layout>
    ),
  },
  {
    title: "support-details",
    path: "/account/supportDetails/:supportId",
    component: (
      <Layout>
        <AdminDetails />
      </Layout>
    ),
  },
  //Notes
  {
    title: "Note-New",
    path: "/account/new-note/",
    component: (
      <Layout>
        <NewNote />
      </Layout>
    ),
  },
  {
    title: "Note-Listing",
    path: "/Admin/Note-listing",
    component: (
      <Layout>
        <NoteListing
          method={"POST"}
          note_api={"notes/notelisting"}
          isSearchable={true}
          fetch_key={"Notes"}
        />
      </Layout>
    ),
  },

  {
    title: "Note-details",
    path: "/account/note-details/:noteId",
    component: (
      <Layout>
        <NoteDetails />
      </Layout>
    ),
  },
  {
    title: "Note-edit",
    path: "/account/edit-note/:noteId",
    component: (
      <Layout>
        <EditNote />
      </Layout>
    ),
  },

  // Emails
  {
    title: "Email-Listing",
    path: "/account/:accountId/email/:emailId",
    component: (
      <Layout>
        <EmailDetails />
      </Layout>
    ),
  },
  //Documents

  {
    title: "New-Document",
    path: "/account-document-upload",
    component: (
      <Layout>
        <AccountDocumentUpload />
      </Layout>
    ),
  },
  {
    title: "Document-Details",
    path: "/account-document-details/:id",
    component: (
      <Layout>
        <AccountDocumentDetails />
      </Layout>
    ),
  },
  {
    title: "Document-edit",
    path: "/account-document-edit/:id",
    component: (
      <Layout>
        <AccountDocumentEdit />
      </Layout>
    ),
  },
  // Permission Manger -
  {
    title: "assign-admin",
    path: "/account/assign-admin",
    component: (
      <Layout>
        <AssignAdmin />
      </Layout>
    ),
  },
  {
    title: "multiple-account-assign",
    path: "/account/multiple-account-permission",
    component: (
      <Layout>
        <MultipleAccount />
      </Layout>
    ),
  },
  {
    title: "create-role",
    path: "/account/assign-create-permission",
    component: (
      <Layout>
        <CreateRole />
      </Layout>
    ),
  },
  {
    title: "role-to-user",
    path: "/account/assign-permission-user",
    component: (
      <Layout>
        <RoleToUser />
      </Layout>
    ),
  },
  {
    title: "role-to-user",
    path: "/account/assign-permission-position",
    component: (
      <Layout>
        <RoleToPosition />
      </Layout>
    ),
  },
];

export default ProtectedRoute;
