import React, { useEffect, useRef, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { fetchPieChartData, findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { Skeleton } from "@mui/material";

// Register required Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const DeliquentCheckHalfPieChart = ({ sections, setSections }) => {
  const [piedata, setPiedata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(350);
  const navigate = useNavigate();
  const account = findSectionById("accounts", sections);

  const fetchData = async () => {
    try {
      const result = await fetchPieChartData(
        sections,
        "deliquentCheck",
        "delinquentChecks"
      );
      setPiedata(result);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        setChartWidth(width);
      }
    });

    resizeObserver.observe(chartContainerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const labels = piedata?.map((item) => item.account_site_name) || [];
  const dataValues = piedata?.map((item) => item.aed_count) || [];
  const hasAEDs = piedata?.some((item) => item.aed_count > 0);

  // Fixed colors for the first 10 elements
  const fixedColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4CAF50",
    "#FF9F40",
    "#9966FF",
    "#C9CBCF",
    "#FF6384",
    "#4BC0C0",
    "#FFCD56",
  ];

  // Options to hide the legend and make it a half pie chart
  const options = {
    rotation: -90, // Start the chart from the top
    circumference: 180, // Half pie
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: hasAEDs, // Only enable tooltips when there are AEDs
      },
    },
  };

  const navigateDeliquentCheck = () => {
    Number(isPermission({ type: "dashboard", name: "EquipmentListing" })) ===
      1 && navigate(`/account-details/${piedata[0]?.account_id}/Equipment`);
  };

  // Prepare data for chart
  const chartData = {
    labels: hasAEDs ? labels : ["No Delinquent Checks"],
    datasets: [
      {
        data: hasAEDs ? dataValues : [1], // Use 1 to ensure chart is visible
        backgroundColor: hasAEDs
          ? [
              ...fixedColors,
              ...Array.from({ length: 10 }, () => {
                const letters = "0123456789ABCDEF";
                let color = "#";
                for (let i = 0; i < 6; i++) {
                  color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
              }),
            ]
          : ["#D3D3D3"], // Light grey when no AEDs
        hoverBackgroundColor: hasAEDs
          ? [
              ...fixedColors,
              ...Array.from({ length: 10 }, () => {
                const letters = "0123456789ABCDEF";
                let color = "#";
                for (let i = 0; i < 6; i++) {
                  color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
              }),
            ]
          : ["#A9A9A9"], // Darker grey on hover
      },
    ],
  };

  const getChartSize = () => {
    const maxSize = Math.min(chartWidth * 0.8, 400);
    return { width: maxSize, height: maxSize };
  };

  const chartSize = getChartSize();

  return (
    <>
      {!loading ? (
        // <div id="deliquentChart-Section">
        <div
          className="support-half-pie-chart"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "1px",
            justifyContent: "center",
            boxSizing: "border-box",
            minHeight: "450px",
          }}
        >
          <h4 className="delinquent-checks-title">Delinquent Checks</h4>
          <div
            className="chart-content"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "5px",
              flex: 1,
              minHeight: 0,
              alignItems: "center",
            }}
          >
            <div
              ref={chartContainerRef}
              className="chart-container"
              style={{
                flex: "1 1 auto",
                minWidth: "100px",
                maxWidth: "90%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: chartSize.width,
                  height: chartSize.height,
                  minWidth: "50px",
                  minHeight: "50px",
                }}
              >
                <Pie data={chartData} options={options} />
              </div>
            </div>
            <div
              className="site-list"
              style={{
                flex: "1 1 auto",
                minWidth: "150px",
                maxWidth: "100%",
                maxHeight: "400px",
                overflowY: "auto",
                padding: "10px",
              }}
            >
              {hasAEDs ? (
                labels.map((label, index) => (
                  <p
                    key={index}
                    className="site-label"
                    style={{
                      color: chartData.datasets[0].backgroundColor[index],
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      margin: "1px 0",
                    }}
                    onClick={() => navigateDeliquentCheck()}
                  >
                    <span
                      style={{
                        width: "12px",
                        height: "12px",
                        backgroundColor:
                          chartData.datasets[0].backgroundColor[index],
                        display: "inline-block",
                        borderRadius: "50%",
                      }}
                    />
                    {label}
                  </p>
                ))
              ) : (
                <p
                  className="site-label"
                  style={{
                    color: "#A9A9A9",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    margin: "1px 0",
                  }}
                >
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: "#A9A9A9",
                      display: "inline-block",
                      borderRadius: "50%",
                    }}
                  />
                  No Delinquent Checks
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        // </div>
        ""
        // <Skeleton
        //   count={5}
        //   width={"100%"}
        //   height={"100%"}
        //   style={{ margin: "1px" }}
        // />
      )}
    </>
  );
};

export default DeliquentCheckHalfPieChart;
