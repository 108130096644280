import React from 'react';

const ImageUploadComponent = ({ file, handleFileChange, handleRemoveFile, cancel }) => {
  return (
    <div className="col py-2">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="heading">Servicing Images Upload</h2>
        <button
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
          className="btn ms-2 file-input-div"
          type="button"
        >
          <img src="/assets/images/upload.svg" alt="upload" />
          <input
            type="file"
            className="hidden-file"
            name="file_name"
            size="sm"
            accept=".jpg, .jpeg, .png, .gif"
            onChange={handleFileChange}
            multiple
          />
          <h2
            className="heading"
            style={{
              minWidth: "150px",
              fontWeight: "lighter",
              fontSize: "21px",
              margin: "0px",
            }}
          >
            Upload Images
          </h2>
        </button>
      </div>
      <div
        className="col relative service-notes-container"
        style={{
          height: "200px",
          width: "100%",
          maxWidth: "100%",
          border: "3px solid #337ab7",
          padding: "1%",
          overflowX: "auto",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        <ul
          style={{
            listStyleType: "none",
            margin: 0,
            padding: 0,
            display: "flex",
            flexDirection: "row",
            columnGap: "15px",
            paddingRight: "20px",
          }}
        >
          {file &&
            file.map((uploadedFile, index) => (
              <li
                key={index}
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={URL.createObjectURL(uploadedFile)}
                  alt={uploadedFile.name}
                  style={{ height: "120px", width: "200px" }}
                />
                <h4
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    maxWidth: "200px",
                  }}
                >
                  {uploadedFile.name}
                </h4>
                <span
                  style={{
                    position: "absolute",
                    right: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRemoveFile(index)}
                >
                  <img src={cancel} height={13} alt="cancel" />
                </span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ImageUploadComponent;