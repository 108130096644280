import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import moment from "moment";
import {
  AEDStorageInfo,
  SortByProperty,
} from "../../../../common/helper/BasicFn";
import CommonDatePicker from "../../../../common/components/DatePickers/CommonDatePicker";
import CustomToggleButton from "../../../../common/components/CustomToggleButton";
import GlobalDropdown from "../../../../common/components/custom-ui/GlobalDropdown";

function EditStorageInformation({
  AccountList,
  formData,
  BrandList,
  setFormData,
  keyName,
  Permissins,
}) {

  const handleInput = (e, selectedText, nameKey) => {
    let val = e.target.value;
    let name = e.target.name;
    const fd = { ...formData };
    fd.alarmed = parseInt(val) === 1 ? 1 : 0; // val === 1 ? 1 : 0;
    fd.alarm_status = parseInt(val) === 1 ? 1 : 0;
    fd[name] = val;
    fd[nameKey] = selectedText;
    setFormData(fd);
  };

  const handleCheckBox = (e) => {
    if (e.target.type == "checkbox") {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.checked }));
    } else {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };
  const [StorageList, setStorageList] = useState([]);
  const onLoad = async () => {
    let data = await AEDStorageInfo();
    if (data) {
      data = SortByProperty(data, "storage_info_name");
      setStorageList(data);
    }
  };
  // React.useEffect(() => {
  //   onLoad();
  // }, []);

  const handleCheckBox2 = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    let checked = e.target.checked;

    const oldData = { ...formData };
    if (checked) {
      oldData[name] = 1;
    } else {
      oldData[name] = 0;
    }
    setFormData(oldData);
  };

  const handleAlarmStatus = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    let checked = e.target.checked;

    const oldData = { ...formData };
    if (checked) {
      oldData[name] = 1;
    } else {
      oldData[name] = 0;
    }
    setFormData(oldData);
  };

  const handleDateChange = (name, val) => {
    const oldData = { ...formData };
    let year = Permissins?.v_battery;
    let expiry_date = moment(val).add(year, "years").format("YYYY-MM-DD");
      // .calendar();
    
    oldData.store_expiry_date = expiry_date;
    oldData.expiry_date = expiry_date;
    oldData[name] = val;
    setFormData(oldData);
  };

  useEffect(() => {
    handleDateChange("v9_Installed_Date", formData?.v9_Installed_Date)
  },[formData?.v9_Installed_Date])

  return (
    <>
      <div className=" bg-gray py-4 px-4 my-2" id="edit_storage_info_section" >
        <h2 className="heading">Storage Information</h2>
        <div className="row">
          {/* {formData?.storage_part_name ? */}
          <div className="col-4 form-group">
            <label htmlFor="">Storage Type</label>
            {/* <select
              className="form-control"
              id=""
              name="storage_type"
              value={formData?.storage_type}
              onChange={handleInput}
            >
              <option value="" key={0} selected>
                --Select One--
              </option>
              {StorageList &&
                StorageList.map((item) => (
                  <option value={item?.storage_info_id}>
                    {item?.storage_info_name}
                  </option>
                ))}
            </select> */}

            <GlobalDropdown
              dropDownName={"storage_type"}
              apiEndpoint={"aed/storage-info"}
              idKey={"storage_info_id"}
              valueKey={"storage_info_name"}
              parentKey={""}
              // onSelect={(e, value) => {
              //   handleInput(e);
              // }}

              onSelect={(e) => {
                const selectedId = e.target.value; // Selected ID
                  const selectedText = e.target.options[e.target.selectedIndex].text;
                  handleInput(e, selectedText, "storage_part_name");
              }}
              shouldFetchOnOpen={false}
              isCache={true}
              initialSelectedValue={formData?.storage_type}
              defaultValue={{
                id: formData?.storage_type,
                value: formData?.storage_part_name,
              }}
            />
          </div>
          {/* : ""} */}

          {formData?.storage_type == 1 ? (
            <div className="col-2">
              <Form.Group>
                <b className={"d-block mb-2"}>Alarmed</b>
                <div className="">
                  <CustomToggleButton
                    ToggleName="alarmed"
                    ToggleValue={formData?.alarmed}
                    changeHandler={handleCheckBox2}
                    defaultValue={formData?.alarm_status}
                  />
                </div>
              </Form.Group>
            </div>
          ) : (
            ""
          )}

          {formData?.storage_type == 1 ? (
            <div className="col-2">
              <Form.Group>
                <b className={"d-block mb-2"}>Alarm Status</b>
                <div className="">
                  <CustomToggleButton
                    ToggleName="alarm_status"
                    ToggleValue={formData?.alarm_status}
                    changeHandler={handleAlarmStatus}
                    defaultValue={formData?.alarm_status}
                  />
                </div>
              </Form.Group>
            </div>
          ) : (
            ""
          )}

          {formData?.alarmed == 1 || formData?.alarmed ? (
            <div className="col-3 form-group">
              <label htmlFor="">9v Installed Date</label>
              <CommonDatePicker
                calName={"v9_Installed_Date"}
                CalVal={formData?.v9_Installed_Date}
                HandleChange={handleDateChange}
                defaultValue={formData?.v9_Installed_Date}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default EditStorageInformation;
