import React from "react";
import CustomToggleButton2 from "../../../../common/components/toggleSwitch/CustomToggleButton2";
import { InfoIcon } from "../../../../common/helper/icons";
import {AEDDetailsSkeleton} from "./AedSkeletons/AEDDetailsSkeleton";

const AEDDetailsContent = ({ formData, handleToggleChange, HIC }) => (
  <table className="table table-striped-columns">
    <tbody>
      <tr>
        <td>Is The AED Present?</td>
        <td>
          <CustomToggleButton2
            ToggleName="is_aed_present"
            ToggleValue={formData?.is_aed_present}
            changeHandler={handleToggleChange}
            is_read_only={true}
          />
        </td>
      </tr>
      {!formData?.is_aed_present ? (
        <tr>
          <td colSpan={3}>
            <textarea
              className="form-control"
              name="is_aed_present_input"
              placeholder=""
              value={formData?.is_aed_present_input}
              onChange={HIC}
              readOnly
            ></textarea>
          </td>
        </tr>
      ):""}

      {formData?.is_aed_present ? (
        <>
          <tr>
            <td>Does the status indicator show ready?</td>
            <td>
              <CustomToggleButton2
                ToggleName="status_indicator"
                ToggleValue={formData?.status_indicator}
                changeHandler={handleToggleChange}
                is_read_only={true}
              />
            </td>
          </tr>
          {!formData?.status_indicator && (
            <tr>
              <td colSpan={3}>
                <textarea
                  className="form-control"
                  placeholder=""
                  value={formData?.status_indicator_input}
                  name="status_indicator_input"
                  onChange={HIC}
                  readOnly
                ></textarea>
              </td>
            </tr>
          )}

          <tr>
            <td>
              Is there a AED rescue kit present and in good condition?
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Hello world!"
                data-tooltip-place="top"
              >
                <InfoIcon />
              </span>
            </td>
            <td>
              <CustomToggleButton2
                ToggleName="rescue_kit_present"
                ToggleValue={formData?.rescue_kit_present}
                changeHandler={handleToggleChange}
                is_read_only={true}
              />
            </td>
          </tr>
          {!formData?.rescue_kit_present && (
            <tr>
              <td colSpan={3}>
                <textarea
                  className="form-control"
                  placeholder=""
                  name="rescue_kit_present_input"
                  value={formData?.rescue_kit_present_input}
                  onChange={HIC}
                  readOnly
                ></textarea>
              </td>
            </tr>
          )}
        </>
      ):""}
    </tbody>
  </table>
);

const AEDDetailsTable = ({
  showAedGeneralInfo,
  formData,
  handleToggleChange,
  HIC,
}) => {
  return showAedGeneralInfo ? (
    <AEDDetailsSkeleton />
  ) : (
    <AEDDetailsContent
      formData={formData}
      handleToggleChange={handleToggleChange}
      HIC={HIC}
    />
  );
};

export default AEDDetailsTable;
