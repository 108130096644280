import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { CallPOSTAPI } from "../../../../common/services/index";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { FormatDate } from "../../../../common/helper/Common";
import { useTable, useSortBy, useRowSelect } from "react-table";
import { Skeleton } from "@mui/material";

const AEDInventoryModal = ({
  inventoryModal,
  setInventoryModal,
  secName,
  api,
  Pindex,
  index,
  setNewFormData,
  Bindex,
  inspection_by,
  AedFormData,
  contact_id,
}) => {
  const aed_brand_id = AedFormData?.[index]?.aed_brand_id;
  const aed_model_id = AedFormData?.[index]?.aed_model_id;

  const [addAedInventoryModal, setAedInventoryModal] = useState(true);
  const [batteryInventory, setBatteryInventory] = useState([]);
  const [padInventory, setPadInventory] = useState([]);
  const [isDNI, setIsDNI] = useState(true);
  const handleClose = () => setInventoryModal(false);
  const [loading, setLoading] = useState(true);
  const [selectedBatteryRows, setSelectedBatteryRows] = useState([]);
  const [selectedPadRows, setSelectedPadRows] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState("");

  const fetchBatteryInventaryData = async () => {
    if (secName) {
      var body = {
        section_name: secName,
        contact_id: contact_id,
        model_id: aed_model_id,
        brand_id: aed_brand_id,
      };
      var response = await CallPOSTAPI(api, body);
      if (response.status === true) {
        var data = response?.data?.data;

        if (data) {
          for (let invent = data.length - 1; invent >= 0; invent--) {
            const element = data[invent];
            let quantityUpdated = false; // Flag to check if quantity is updated
            for (
              let index2 = 0;
              index2 < AedFormData[index].battery_info.length;
              index2++
            ) {
              const element2 = AedFormData[index].battery_info[index2];
              if (element.sbid === element2.inventory_id) {
                element.quantity--;
                quantityUpdated = true;
                setBatteryInventory([...data]);
              }
            }
            if (element.quantity === 0) {
              data.splice(invent, 1);
              setBatteryInventory([...data]);
              quantityUpdated = true;
            }
            if (!quantityUpdated) {
              setBatteryInventory([...data]);
            }
          }
        }
        setLoading(false);
      }
    }
  };

  const fetchPadInventaryData = async () => {
    if (!secName) {
      var body = {
        contact_id: contact_id,
        model_id: aed_model_id,
        brand_id: aed_brand_id,
      };
      var response = await CallPOSTAPI(api, body);
      if (response.status === true) {
        var data = response?.data?.data;

        if (data) {
          for (let invent = data?.length - 1; invent >= 0; invent--) {
            const element = data[invent];
            let quantityUpdated = false; // Flag to check if quantity is updated
            for (
              let index2 = 0;
              index2 < AedFormData[index].all_pads.length;
              index2++
            ) {
              const element2 = AedFormData[index].all_pads[index2];
              if (element.spid === element2.inventory_id) {
                element.quantity--;
                quantityUpdated = true;
                setPadInventory([...data]);
              }
            }
            if (element.quantity === 0) {
              data.splice(invent, 1);
              setPadInventory([...data]);
              quantityUpdated = true;
            }
            if (!quantityUpdated) {
              setPadInventory([...data]);
            }
          }
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBatteryInventaryData();
    fetchPadInventaryData();
  }, []);

  const handleCellClick = (e) => {
    setSelectedRowIndex(e.index);
    let name = secName ? "battery" : "pads";

    if (name === "battery") {
      const selectedBatteryRowData = batteryInventory.filter(
        (row) => row?.sbid === e?.original?.sbid
      );
      setSelectedBatteryRows(selectedBatteryRowData);
    } else {
      const selectedPadRowData = padInventory.filter(
        (row) => row?.spid === e?.original?.spid
      );
      setSelectedPadRows(selectedPadRowData);
    }
  };

  // const handleCellClick = (rowData) => {
  //   const id = secName ? rowData.sbid : rowData.spid;
  //   if (secName) {
  //     setSelectedBatteryRows([rowData]);
  //   } else {
  //     setSelectedPadRows([rowData]);
  //   }
  //   setSelectedRowIndex(id);
  // };

  const handleBatteryRowQnty = () => {
    const Bfd = [...batteryInventory];
    if (Bfd[selectedRowIndex]) {
      Bfd[selectedRowIndex].quantity = Bfd[selectedRowIndex].quantity - 1;
    }
    setBatteryInventory(Bfd);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (secName) {
      handleBatteryRowQnty();
      const fd = [...AedFormData];
      fd[index].battery_info[Bindex].battery_udi =
        selectedBatteryRows[0]?.battery_udi;
      fd[index].battery_info[Bindex].battery_lot =
        selectedBatteryRows[0]?.battery_lot;
      fd[index].battery_info[Bindex].battery_expiration =
        selectedBatteryRows[0]?.battery_expiration;
      fd[index].battery_info[Bindex].manufactured_date =
        selectedBatteryRows[0]?.manufactured_date;
      fd[index].battery_info[Bindex].install_date =
        selectedBatteryRows[0]?.install_date;
      fd[index].battery_info[Bindex].battery_type_id =
        selectedBatteryRows[0]?.battery_type_id;
      fd[index].battery_info[Bindex].inventory_id =
        selectedBatteryRows[0]?.sbid;

      if (selectedBatteryRows[0] && selectedBatteryRows[0].sbid) {
        fd[index]?.useSbidInventory?.push(selectedBatteryRows[0]?.sbid);
      }
      setNewFormData(fd);
    } else {
      const fd = [...AedFormData];
      fd[index].all_pads[Pindex].pad_udi = selectedPadRows[0]?.pad_udi;
      fd[index].all_pads[Pindex].pad_lot = selectedPadRows[0]?.pad_lot;
      fd[index].all_pads[Pindex].pad_expiration =
        selectedPadRows[0]?.pad_expiration;
      fd[index].all_pads[Pindex].pad_type_id = selectedPadRows[0]?.pad_type_id;
      fd[index].all_pads[Pindex].pad_udi = selectedPadRows[0]?.pad_udi;
      fd[index].all_pads[Pindex].inventory_id = selectedPadRows[0]?.spid;

      if (selectedPadRows[0] && selectedPadRows[0].spid) {
        fd[index]?.useSpidInventory?.push(selectedPadRows[0].spid);
      }
      setNewFormData(fd);
    }
    setSelectedBatteryRows([]);
    setSelectedPadRows([]);
    handleClose();
  };

  //   const renderCheckBox = (e) => {
  //     const rowData = e.original;
  //     const is_selected = secName
  //       ? selectedBatteryRows?.find((row) => row.sbid === rowData.sbid)
  //       : selectedPadRows?.find((row) => row.spid === rowData.spid);
  //     return (
  //       <>
  //         <input
  //           type="checkbox"
  //           value={e.value}
  //           name={"select_row"}
  //           checked={is_selected}
  //           onChange={() => handleCellClick(rowData)}
  //         />
  //       </>
  //     );
  //   };

  const renderCheckBox = (row) => {
    const rowData = row.original;
    const id = secName ? rowData.sbid : rowData.spid;
    const isSelected = secName
      ? selectedBatteryRows.some((r) => r.sbid === id)
      : selectedPadRows.some((r) => r.spid === id);

    return (
      <input
        type="checkbox"
        checked={isSelected}
        // onChange={() => handleCellClick(row)}
      />
    );
  };

  const renderDateBatteryCell = (battery) => {
    if (secName) {
      const dateField = battery.manufactured_date
        ? "manufactured_date"
        : battery.battery_expiration
        ? "battery_expiration"
        : "install_date";

      const imageSourceMap = {
        manufactured_date: "/assets/images/BatteryMfgr.svg",
        battery_expiration: "/assets/images/Battery.png",
        install_date: "/assets/images/Battery.png",
      };

      return (
        <div>
          {battery[dateField] && (
            <img
              src={imageSourceMap[dateField]}
              alt={dateField}
              style={{
                width: dateField === "manufactured_date" ? "20px" : undefined,
                height: dateField === "manufactured_date" ? "20px" : undefined,
                marginRight: "5%",
              }}
            />
          )}
          {battery[dateField] ? (
            <span>{FormatDate(battery[dateField])}</span>
          ) : (
            <span>N/A</span>
          )}
        </div>
      );
    }
  };

  const renderDatePadCell = (pad) => {
    const dateField = pad.pad_expiration ? "pad_expiration" : "";

    return (
      <div>
        {pad[dateField] && (
          <img
            src={
              pad.is_pediatric === 1
                ? "/assets/images/child-Vector.png"
                : "/assets/images/people-Group.svg"
            }
            alt={
              pad.is_pediatric === 1
                ? "/assets/images/child-Vector.png"
                : "/assets/images/people-Group.svg"
            }
            style={{
              width: pad.is_pediatric === 1 ? "14px" : undefined,
              height: pad.is_pediatric === 1 ? "22px" : undefined,
              marginRight: "5%",
            }}
          />
        )}
        {pad[dateField] ? (
          <span>{FormatDate(pad[dateField])}</span>
        ) : (
          <span>N/A</span>
        )}
      </div>
    );
  };

  const renderDniCell = (data) => {
    const feild = data.dni;
    return (
      <div>
        {feild === 1 ? (
          <DoneIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        )}
      </div>
    );
  };

  padInventory.sort(
    (a, b) => new Date(a.pad_expiration) - new Date(b.pad_expiration)
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: secName ? "bid" : "pid",
        Cell: ({ row }) => renderCheckBox(row),
      },
      {
        Header: "Part #",
        accessor: secName ? "battery_part" : "pad_part",
        Cell: ({ value }) => (value === "" ? "N/A" : value),
      },
      secName
        ? {
            Header: "Date",
            accessor: "render_date",
            Cell: ({ row }) => renderDateBatteryCell(row.original),
          }
        : {
            Header: "Date",
            accessor: "customDate",
            Cell: ({ row }) => renderDatePadCell(row.original),
          },
      {
        Header: secName ? "Battery Lot" : "Pad Lot",
        accessor: secName ? "battery_lot" : "pad_lot",
        Cell: ({ value }) => (value === "" ? "N/A" : value),
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "DNI",
        accessor: "customDate",
        id: "dni",
        Cell: ({ row }) => renderDniCell(row.original),
      },
    ],
    [secName, selectedBatteryRows, selectedPadRows]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: secName ? batteryInventory : padInventory,
    },
    useSortBy,
    useRowSelect
  );

  return (
    <>
      <Modal
        show={inventoryModal}
        onHide={handleClose}
        dialogClassName="modal-120w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        id="states-modal"
      >
        <Modal.Header>
          <Modal.Title>{inspection_by} Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3">
          <div className="modal-container">
            <div className="my-modal-section">
              <div className="upper-div">
                <table
                  {...getTableProps()}
                  className="table table-bordered table-hover"
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <td
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              width: column.width,
                              backgroundColor: "#999",
                              cursor: "pointer",
                            }}
                            className="tb-td"
                          >
                            <div className="d-flex align-items-center th-d">
                              {column.render("Header")}
                              <span className="ml-1">
                                {!column.disableSortBy && // Only show icon if sorting is not disabled
                                  (column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <SouthIcon size={2} />
                                    ) : (
                                      <NorthIcon size={2} />
                                    )
                                  ) : (
                                    <SouthIcon size={2} /> // Default icon if not sorted
                                  ))}
                              </span>
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {loading ? (
                      [...Array(4)].map((_, i) => (
                        <tr key={i}>
                          {columns.map((col, j) => (
                            <td
                              key={j}
                              style={{
                                backgroundColor:
                                  i % 2 === 0 ? "white" : "#e4e4e4",
                              }}
                              className="tb-td"
                            >
                              <Skeleton
                                width={"90%"}
                                height={15}
                                style={{ margin: "3px" }}
                              />
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : batteryInventory?.length === 0 &&
                      padInventory?.length === 0 ? (
                      <tr>
                        <td
                          colSpan={columns.length}
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontStyle: "italic !important",
                            color: "grey",
                          }}
                        >
                          No Inventory Found.
                        </td>
                      </tr>
                    ) : (
                      rows?.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            onClick={() => handleCellClick(row)}
                          >
                            {row.cells.map((cell) => (
                              <td
                                {...cell.getCellProps()}
                                style={{
                                  backgroundColor:
                                    i % 2 === 0 ? "white" : "#e4e4e4",
                                }}
                                className="tb-td"
                              >
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer> */}
        <div
          className="mt-3 mb-3"
          style={{ display: "flex", marginLeft: "76%", gap: "9%" }}
        >
          <button
            className="Cancel-btn"
            style={{ borderRadius: "7px" }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="submit-btn"
            type="button"
            style={{ borderRadius: "7px" }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
        {/* </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default AEDInventoryModal;
