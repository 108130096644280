import { Skeleton } from '@mui/material';
import React from 'react';

const DataTableSkeleton = () => {
  return (
        <tr>
          <td className="py-1 px-2 border-r-blue">
            <Skeleton width={100} />
          </td>
          <td className="py-1 px-2 border-r-blue">
            <Skeleton width={50} />
          </td>
          <td className="py-1 px-2 border-r-blue">
            <Skeleton width={80} />
          </td>
          <td className="py-1 px-2 border-r-blue">
            <Skeleton width={100} />
          </td>
          <td className="py-1 px-2 border-r-blue">
            <Skeleton width={120} />
          </td>
          <td>
            <Skeleton width={150} />
          </td>
        </tr>
  );
};

export default DataTableSkeleton;
