import React from 'react'
import { useNavigate } from 'react-router-dom'

function NotFound404() {
    const navigate = useNavigate()
    return (
        <div>
            <span onClick={navigate(-1)}>Back</span>
        </div>
    )
}

export default NotFound404
