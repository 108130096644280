import React from "react";
import { Skeleton, Typography, Box } from "@mui/material";

export default function AEDSupportTicketSkeleton(){
  return (
    <Box className="col py-2">
    <h2 className="heading">Support Ticket Issue</h2>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={150}
        sx={{
          border: "3px solid #337ab7",
        }}
      />
    </Box>
  );
};
