import React from 'react';
import GlobalDropdown from '../../../../common/components/custom-ui/GlobalDropdown';

function ExternalRMSInfo({AccountList,
    formData,
    BrandList,
    setFormData,
    RmsDropdown
}) {

    const handleInput = (e,val)=>{
        let value             = e.target.value;
        let name            = e.target.name;
        setFormData((old) => ({ ...old,
          [ name ]: value,
          rms_name: val,
         }));
    }
    

  return (
    <>
    <div className=' bg-gray py-4 px-4 my-2' id='aed_RMS_information_section'>
        <h2 className='heading'>External RMS Information</h2>
        <div className='col-4 form-group' >
            <label htmlFor='' >RMS Brand</label>
            {/* <select className='form-control' id="" name="rms_brand" value={formData?.rms_brand} onChange={handleInput}   >
                <option value="" key={0}  selected >---Select One---</option>
                {RmsDropdown?.map((item,index)=>(
                <option value={item?.rms_brand_id} key={index+1} >{item?.rms_brand_name}</option>
                ))}
            </select> */}

<GlobalDropdown
              dropDownName={"rms_brand"}
              apiEndpoint={"aed/rms-brand"}
              idKey={"rms_brand_id"}
              valueKey={"rms_brand_name"}
              parentKey={""}
              // onSelect={(e) => {
              //   handleInput(e);
              //   // handleBrandChange("brand", e.target.value);
              // }}
              onSelect={(e) => {
                const selectedId = e.target.value; // Selected ID
                const selectedText = e.target.options[e.target.selectedIndex].text;  
              handleInput(e, selectedText);
              }}
              shouldFetchOnOpen={true}
              isCache={true}
              />
        </div>
    </div>
    </>
  )
}

export default ExternalRMSInfo