import React, { useEffect, useState, useMemo, useRef } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  checkLabelsLength,
  fetchPieChartData,
  findSectionById,
} from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { Skeleton } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

const getRandomColor = () =>
  "#" + Math.floor(Math.random() * 16777215).toString(16);

const fixedColors = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4CAF50",
  "#FF9F40",
  "#9966FF",
  "#C9CBCF",
  "#FF6384",
  "#4BC0C0",
  "#FFCD56",
];

function AedOwnedPieChart({ sections, setSections }) {
  const [piedata, setPiedata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartWidth, setChartWidth] = useState(350);
  const chartContainerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchPieChartData(
          sections,
          "aedsOwned",
          "aedsOwned"
        );
        setPiedata(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [sections]);

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        setChartWidth(width);
      }
    });

    resizeObserver.observe(chartContainerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const navigateAedowned = () => {
    Number(isPermission({ type: "dashboard", name: "EquipmentListing" })) ===
      1 && navigate(`/account-details/${piedata[0]?.account_id}/Equipment`);
  };

  const { labels, dataValues, backgroundColor, hasAEDs } = useMemo(() => {
    if (!piedata || piedata.length === 0)
      return {
        labels: ["No AEDs"],
        dataValues: [1],
        backgroundColor: ["#D3D3D3"],
        hasAEDs: false,
      };

    const labels = piedata.map((item) => item.aed_brands_model_name);
    const dataValues = piedata.map((item) => item.aed_count);
    const randomColors = Array.from(
      { length: Math.max(0, labels.length - fixedColors.length) },
      getRandomColor
    );
    const backgroundColor = [...fixedColors, ...randomColors];

    return {
      labels,
      dataValues,
      backgroundColor,
      hasAEDs: dataValues.some((count) => count > 0),
    };
  }, [piedata]);

  const data = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          data: hasAEDs ? dataValues : [1],
          backgroundColor: hasAEDs ? backgroundColor : ["#D3D3D3"],
          hoverBackgroundColor: hasAEDs ? backgroundColor : ["#A9A9A9"],
        },
      ],
    };
  }, [labels, dataValues, backgroundColor, hasAEDs]);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: hasAEDs,
      },
    },
  };

  const getChartSize = () => {
    const maxSize = Math.min(chartWidth * 0.8, 400);
    return { width: maxSize, height: maxSize };
  };

  const chartSize = getChartSize();

  return (
    <>
      {" "}
      {!loading ? (
        <div
          className="aed-owned-pie-chart"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "1px",
            justifyContent: "center",
            boxSizing: "border-box",
            minHeight: "450px",
          }}
        >
          <h4 style={{ margin: "0 0 15px 0" }}>AED Owned</h4>
          <div
            className="chart-content"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "5px",
              flex: 1,
              minHeight: 0,
              alignItems: "center",
            }}
          >
            <div
              ref={chartContainerRef}
              className="chart-container"
              style={{
                flex: "1 1 auto",
                minWidth: "100px",
                maxWidth: "90%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: chartSize.width,
                  height: chartSize.height,
                  minWidth: "50px",
                  minHeight: "50px",
                }}
              >
                <Pie data={data} options={options} />
              </div>
            </div>
            <div
              className="label-list"
              style={{
                flex: "1 1 auto",
                minWidth: "150px",
                maxWidth: "100%",
                maxHeight: "400px",
                overflowY: "auto",
                padding: "10px",
              }}
            >
              {hasAEDs ? (
                labels.map((label, index) => (
                  <div
                    key={index}
                    className="label-item"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      margin: "4px 0",
                      cursor: "pointer",
                    }}
                    onClick={navigateAedowned}
                  >
                    <div
                      style={{
                        flexShrink: 0,
                        width: "12px",
                        height: "12px",
                        backgroundColor: backgroundColor[index],
                        borderRadius: "50%",
                      }}
                    />
                    <span
                      style={{
                        color: backgroundColor[index],
                        fontSize: "1rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {label}
                    </span>
                  </div>
                ))
              ) : (
                <div
                  className="label-item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    margin: "4px 0",
                    color: "#A9A9A9",
                  }}
                >
                  <div
                    style={{
                      flexShrink: 0,
                      width: "12px",
                      height: "12px",
                      backgroundColor: "#A9A9A9",
                      borderRadius: "50%",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    No AEDs
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
        // <Skeleton
        //   count={5}
        //   width={"100%"}
        //   height={50}
        //   style={{ margin: "1px" }}
        // />
      )}
    </>
  );
}

export default AedOwnedPieChart;
