import React from 'react';
import { Skeleton, Table, TableBody, TableCell, TableRow } from '@mui/material';

const InspectionTableSkeleton = () => {
  return (
    <Table className="table">
      <TableBody className="">
        <TableRow>
          <TableCell>
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: "8%",
            }}>
              <span>Inspection Date:</span>
              <Skeleton width={100} />
            </div>
          </TableCell>
          <TableCell style={{ columnGap: "18%" }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "7%",
              alignItems: "center",
            }}>
              <span>Inspection Completed by: </span>
              <Skeleton width={100} />
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Are all AEDS present and in a ready status?</TableCell>
          <TableCell>
            <Skeleton width={50} height={24} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Did you replaced any rescue kits?</TableCell>
          <TableCell>
            <Skeleton width={50} height={24} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Did you replace any alarm batteries?</TableCell>
          <TableCell>
            <Skeleton width={50} height={24} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Did you replace any accessories?</TableCell>
          <TableCell>
            <Skeleton width={50} height={24} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default InspectionTableSkeleton;