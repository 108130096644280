import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const SubAdminMenuBar = ({
  user,
  anchorEl,
  open,
  handleClose,
  moveSubAdminToUser,
  handleSwitchSubAdmin,
  handleLogout,
}) => {
  return (
    <div>
      <Menu
        className="user-dropdown"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: { position: "absolute", backgroundColor: "black" },
        }}
      >
        <>
          {user?.sub_admin == "Work as an Admin" ? (
            <>
              <MenuItem
                onClick={(e) => {
                  moveSubAdminToUser(e);
                  handleClose();
                }}
              >
                User
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem
                onClick={(e) => {
                  handleSwitchSubAdmin(e);
                  handleClose();
                }}
              >
                Admin
              </MenuItem>
            </>
          )}
        </>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default SubAdminMenuBar;
