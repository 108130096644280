import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { DecryptToken } from "../../../helper";

const UserMenuBar = ({
  anchorEl,
  open,
  handleClose,
  moveSubAdminToUser,
  handleSwitchSubAdmin,
  handleLogout,
}) => {
    const navigate = useNavigate();
    const user = DecryptToken();

  return (
    <div>
      <Menu
        className="user-dropdown"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: { position: "absolute", backgroundColor: "black" },
        }}
      >
        <MenuItem
          onClick={(e) => {
            navigate("/user-profile/" + user?.contact_id)
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenuBar;
