import React from "react";
import { Form } from "react-bootstrap";
import CustomToggleButton2 from "../../../../common/components/toggleSwitch/CustomToggleButton2";
import CommonDatePickerForFilter from "../../../../common/components/date-picker/DatePickerForServiceCheck";

const InspectionForm = ({
  formData,
  setFormData,
  handleCalendarChange,
  handleToggleChange,
  technicianData,
  insepectionByError,
  setInsepectionByError,
  formSubmitted,
}) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <td>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "8%",
              }}
            >
              <span>Inspection Date:</span>
              <span>
                <div
                  className="d-flex align-items-center calendar-input-btn"
                  style={{ borderRadius: 4, border: "0.5px solid #ccc" }}
                >
                  <CommonDatePickerForFilter
                    calName="inspection_date"
                    CalVal={formData.inspection_date}
                    HandleChange={(newValue) =>
                      handleCalendarChange(newValue, "inspection_date")
                    }
                    minDate={new Date()}
                    disabled={!formData.inspection_date}
                  />
                </div>
              </span>
            </div>
          </td>
          <td style={{ columnGap: "18%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "7%",
                alignItems: "center",
              }}
            >
              <span>Inspection Completed by: </span>
              <Form.Group className="row" style={{ maxWidth: "250px" }}>
                {Array.isArray(technicianData) && technicianData.length > 0 ? (
                  <select
                    value={
                      formData?.contact_id ||
                      (technicianData.length > 0
                        ? technicianData[0].contact_id
                        : "")
                    }
                    onChange={(e) => {
                      const selectedContactId = e.target.value;
                      const selectedTechnician = technicianData.find(
                        (technician) =>
                          technician.contact_id ===
                          parseInt(e.target.value.trim(), 10)
                      );
                      setFormData((prev) => ({
                        ...prev,
                        contact_id: e.target.value,
                        inspection_by: selectedTechnician
                          ? selectedTechnician?.contact_name
                          : selectedContactId,
                      }));
                      setInsepectionByError(false);
                    }}
                    className={`form-control ${
                      insepectionByError ? "is-invalid" : ""
                    }`}
                    style={{ width: "200px" }}
                  >
                    {technicianData.map((technician) => (
                      <option
                        key={technician.contact_id}
                        value={technician.contact_id}
                      >
                        {technician.contact_name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    value={formData?.contact_id}
                    className={`form-control ${
                      insepectionByError ? "is-invalid" : ""
                    }`}
                    style={{ width: "200px" }}
                  >
                    <option>---Select One---</option>
                  </select>
                )}
                <Form.Control.Feedback type="invalid">
                  Select Inspection Completed by.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </td>
        </tr>
        <tr>
          <td>Are all AEDS present and in a ready status?</td>
          <td>
            <CustomToggleButton2
              ToggleName="aeds_ready_status"
              ToggleValue={formData?.aeds_ready_status}
              changeHandler={handleToggleChange}
              is_read_only={false}
            />
          </td>
        </tr>
        <tr>
          <td>Did you replaced any rescue kits?</td>
          <td>
            <CustomToggleButton2
              ToggleName="rescue_kits_status"
              ToggleValue={formData?.rescue_kits_status}
              changeHandler={handleToggleChange}
              is_read_only={false}
            />
          </td>
        </tr>
        <tr>
          <td>Did you replace any alarm batteries?</td>
          <td>
            <CustomToggleButton2
              ToggleName="alarm_batteries_status"
              ToggleValue={formData?.alarm_batteries_status}
              changeHandler={handleToggleChange}
              is_read_only={false}
            />
          </td>
        </tr>
        <tr>
          <td>Did you replace any accessories?</td>
          <td>
            <CustomToggleButton2
              ToggleName="accessories_status"
              ToggleValue={formData?.accessories_status}
              changeHandler={handleToggleChange}
              is_read_only={false}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InspectionForm;
