import React from 'react'
import SitesTab from '../components/SitesTab'

function SitePage() {
  return (
    <>
        <SitesTab/>
    </>
  )
}

export default SitePage