import React, { useState, useEffect } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { Box } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { DecryptToken, getPermission } from "../../../../common/helper";
import { Skeleton } from "@mui/material";
import {
  AccountContactDetails,
  getPer,
  truncateText,
} from "../../../../common/helper/BasicFn";
import {
  ContactStatus,
  formatPhoneNumber,
} from "../../../../common/helper/Common.js";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import New from "../../../../common/img/New.png";
import NewGlobalTable from "../../../../common/components/custom-ui/NewGlobalTable.jsx";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice.js";
import {
  isPer,
  isPermission,
  isPermissionmission,
} from "../../../../common/helper/PermissionManager.jsx";
import { AccountContactsTab } from "../../../../common/hooks/fieldsConfig.js";
import { handleTabfunction } from "../../../../store/slices/TabSlice.js";
const Contacts = ({ setTabTbldata, is_user }) => {
  const navigate = useNavigate();
  const user = DecryptToken();
  const privilege = getPermission();
  const { accountId } = useParams();
  const [showLoading, setShowLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const dispatch = useDispatch();
  const subAdminPermission = [
    "site-details",
    "contact-tab",
    "equipment-tab",
    "training-tab",
    "inperson-tab",
    "notes-tab",
    "documents-tab",
  ];

  let site_url = is_user ? "/user/site-details/" : "/account/site-details/";

  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "",
  };

  const handleDispatched = (accountId, contact_id, name) => {
    BreadCrumbsObject.title = name + " Details";
    BreadCrumbsObject.tab = "Details";
    BreadCrumbsObject.type = "contact";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/${accountId}/contact-details/${contact_id}/Details`,
      })
    );
  };
  const handleDispatch = (accountId) => {
    BreadCrumbsObject.title = "New Contact Form";
    BreadCrumbsObject.type = "account";
    BreadCrumbsObject.tab = "contact";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/contacts/new/${accountId}`,
      })
    );
  };

  // const fetchContactData = async () => {
  //   setShowLoading(true);

  //   let contactData = await AccountContactDetails({
  //     acid: accountId,
  //     currentPage: currentPage,
  //   });
  //   if (contactData) {
  //     setContactList(contactData?.contact_list);
  //     setTotalPage(contactData?.totalPages || 0);
  //   }
  //   setShowLoading(false);
  // };

  // useEffect(() => {
  //   fetchContactData();
  // }, [currentPage]);

  // useEffect(() => {
  //   if (contactList?.length > 0) {
  //     setTabTbldata((prev) => ({
  //       ...prev,
  //       contact: true,
  //     }));
  //   }
  // }, [contactList]);

  // useEffect(() => {
  //   fetchContactData();
  // }, []);

  const RenderContactName = (data) => {
    return (
      <>
        <span
          className={
            isPermission({ type: "link", name: "contactName" }) === 1
              ? "link"
              : ""
          }
          onClick={() => {
            if (isPermission({ type: "link", name: "contactName" }) === 1) {
              handleDispatched(accountId, data.contact_id, data?.contact_name);
              // dispatch(
              //   handleTabfunction({
              //     value: AccountContactsTab.DETAILS,
              //     type: "contact",
              //   }))
              navigate(
                `/account/${accountId}/contact-details/${data.contact_id}/Details`,
                {
                  state: {
                    // siteTitle: "Contact : " + data?.contact_name,
                    editUrl: `/account/contact-details-edit/${data.contact_id}`,
                    deleteUrl: `/account/contact-details-edit/${data.contact_id}`,
                    type: "Contacts",
                  },
                }
              );
            }
          }}
          title={
            data?.contact_name.length > 20 ? data?.contact_name : undefined // Tooltip only if the text is truncated
          }
        >
          {truncateText(data?.contact_name, 20)}
        </span>
      </>
    );
  };

  const RenderUser = (data) => {
    return (
      <>
        {data?.user ? (
          <CheckIcon color={"success"} />
        ) : (
          <CloseIcon color="error" />
        )}
      </>
    );
  };

  const RenderPhone = (data) => {
    return (
      <>
        {data?.phone?.[0].phone != "" && data?.phone?.[0].phone != "-" && (
          <a
            className="link"
            style={{ textDecoration: "none" }}
            href={"tel:" + data?.phone?.[0].ext + data?.phone?.[0]?.phone}
          >
            {data?.phone?.[0].phone
              ? formatPhoneNumber(data?.phone?.[0].phone)
              : ""}
            {data?.phone?.[0].ext != "" ? "x" + data?.phone?.[0].ext : ""}
          </a>
        )}
      </>
    );
  };

  const RenderEmail = (data) => {
    return (
      <>
        {data?.email.length > 0 && (
          <a
            className="link"
            style={{ textDecoration: "none" }}
            href={"mailto:" + data?.email?.[0].email}
          >
            {data?.email?.[0].email}
          </a>
        )}
      </>
    );
  };

  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  const headerColumns = [
    {
      Header: "Contact",
      accessor: "contact_name",
      Cell: ({ row }) => <div> {RenderContactName(row.original)}</div>,
    },
    {
      Header: "User",
      accessor: "user",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div>{RenderUser(row.original)}</div>
          )}
        </>
      ),
      width: "10%",
      disableSortBy: true,
    },
    {
      Header: "Phone",
      accessor: "phone",
      width: "12%",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div>{RenderPhone(row?.original)}</div>
          )}
        </>
      ),
      disableSortBy: true,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div>{RenderEmail(row.original)}</div>
          )}
        </>
      ),
    },
    {
      Header: "Positions",
      accessor: "account_roles",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div>{row.original?.account_roles.join(", ")} </div>
          )}
        </>
      ),
    },
    {
      Header: "Sites",
      accessor: "main_site",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <>
              <p className={row.original?.status == 1 ? "" : "text-danger"}>
                {ContactStatus[row.original?.status]}
              </p>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    //New Global Table Implentation:
    <>
      <Box className="d-flex justify-content-between align-items-center py-2">
        <h3 className="heading">Account Contacts</h3>
        {Number(isPermission({ type: "btn", name: "newContact" }) === 1) ? (
          <Link
            style={{ textDecoration: "none" }}
            onClick={() => {
              handleDispatch(accountId);
            }}
            to={"/account/contacts/new/" + accountId}
          >
            <img src={New} />
            <span style={{ color: "#0C71C3" }}> New</span>
          </Link>
        ) : (
          ""
        )}
      </Box>
      <NewGlobalTable
        method={"GET"}
        pageSize={20}
        isCache={true}
        keyAPiName="contact_list"
        apiEndPoint={
          Number(getPer()) === 1
            ? `account/account-contacts-list/${accountId}`
            : `user/user-contact-tab/${accountId}`
        }
        headerColumns={headerColumns}
      />
    </>
  );
};

export default Contacts;
