import React from 'react'
import SupportListing from '../../Component/Support-Listing'

function AdminListing({ support_api, fetch_data_key, isSearchable, method }) {
    return (
        <div>

            <SupportListing
                method={method}
                isSearchable={isSearchable}
                fetch_data_key={fetch_data_key}
                support_api={support_api}
            />
        </div>
    )
}

export default AdminListing
