import { Skeleton } from "@mui/material";

const InfoCorrectTableSkeleton = () => (
    <table className="theme-table">
      <tbody>
        <tr>
          <td>
            <Skeleton className="h-4 w-[200px]" />
          </td>
          <td>
            <Skeleton className="h-8 w-[60px]" />
          </td>
        </tr>
      </tbody>
    </table>
  );
  
  const StorageInfoTableSkeleton = () => (
    <table className="theme-table">
      <thead>
        <tr>
          <td>Storage Type</td>
          <td>Alarmed</td>
          <td>Turned On</td>
          <td>Does the Alarm sound?</td>
          <td>9v Expiration</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          {[...Array(5)].map((_, index) => (
            <td key={index}>
              <Skeleton className="h-8 w-full" />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );

  export {InfoCorrectTableSkeleton, StorageInfoTableSkeleton};