// ModalFooter.jsx
import React from "react";

const ModalFooter = ({ handleClose, handleSubmit }) => {
  return (
    <div className="row pb-2 py-2">
      <div className="col-12 content-flex-right">
        <button
          className="btn btn-danger text-uppercase"
          type="button"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="btn btn-success text-uppercase ms-2"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ModalFooter;