import React from 'react';

const BottomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    let pages = [];
    const maxVisiblePages = 5;
    const halfVisible = Math.floor(maxVisiblePages / 2);

    let startPage = Math.max(currentPage - halfVisible, 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  if (totalPages <= 1) return null;

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button
            className="btn btn-light mx-1"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{ background: "#dad7d763" }}
          >
            &lt;
          </button>
        </li>

        {currentPage > 3 && (
          <>
            <li className="page-item">
              <button className="btn btn-light mx-1" onClick={() => onPageChange(1)}>
                1
              </button>
            </li>
            {currentPage > 4 && <li className="page-item"><span className="mx-1">...</span></li>}
          </>
        )}

        {getPageNumbers().map(pageNumber => (
          <li key={pageNumber} className="page-item">
            <button
              className={`btn mx-1 ${currentPage === pageNumber ? 'btn-primary' : 'btn-light'}`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        ))}

        {currentPage < totalPages - 2 && (
          <>
            {currentPage < totalPages - 3 && <li className="page-item"><span className="mx-1">...</span></li>}
            <li className="page-item">
              <button className="btn btn-light mx-1" onClick={() => onPageChange(totalPages)}>
                {totalPages}
              </button>
            </li>
          </>
        )}

        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button
            className="btn btn-light mx-1"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{ background: "#dad7d763" }}
          >
            &gt;
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default BottomPagination;