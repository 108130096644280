import React from "react";
import GlobalDropdown from "../../../../../common/components/custom-ui/GlobalDropdown";
import { printConsole } from "../../../../../common/helper/Common";
const BatteryComp = ({
  formData,
  crrFormData,
  BatteryList,
  handleInputChange,
  crrIndex,
  is_readonly = 0,
}) => {

  printConsole({formData}) // print console.log
  // BatteryList
  return (
    <>
      <div className="col form-group" style={{ maxWidth: "300px" }}>
        <label htmlFor="">Battery Part #</label>
        {/* <select name="battery_type_id" value={is_readonly ?'' :crrFormData?.battery_type_id} class="form-control"
            onChange={(e)=>handleInputChange(e,crrIndex)}
            disabled={is_readonly}
            >
              <option value="" key={0}  selected >--Select One--</option>
              {BatteryList?.map((item,index)=>(
              <option value={item?.battery_type_id} key={index+1} >{item?.battery_part_number}</option>
              ))}
            </select> */}
        
        <GlobalDropdown
          dropDownName={"battery_type_id"}
          apiEndpoint={"equipment/aed-battery-type-by-model/" + formData?.model_name}
          idKey={"battery_type_id"}
          valueKey={"battery_part_number"}
          parentKey={""}
          onSelect={(e) => {
            handleInputChange(e,crrIndex);
          }}
          shouldFetchOnOpen={true}
          isCache={false}
          disabled={is_readonly}
          initialSelectedValue={crrFormData?.battery_type_id}
          defaultValue={{
            id: crrFormData?.battery_type_id,
            value: crrFormData?.battery_part,
          }}
        />
      </div>
    </>
  );
};

export default BatteryComp;
