import React from "react";
import GlobalDropdown from "../../../../../common/components/custom-ui/GlobalDropdown";

const BatteryPakComp = ({
  formData,
  name,
  title,
  crrFormData,
  BatteryList,
  handleInputChange,
  crrIndex,
  toogleKeyName,
  disabled = false,
}) => {
  // BatteryList
  return (
    <>
      {/* <select name={ name } value={ crrFormData?.[ name ] } class="form-control" disabled={ toogleKeyName ? true : disabled }
				onChange={ (e) => handleInputChange(e, crrIndex) }
				// disabled={disabled}
			>
				<option value="" key={ 0 } selected >--Select One--</option>
				{ BatteryList?.map((item, index) => (
					<option value={ item?.battery_type_id} key={ index + 1 } >{ item?.battery_part_number }</option>
				)) }
			</select> */}

      <GlobalDropdown
        dropDownName={"charge_pak_part"}
        apiEndpoint={
          "equipment/aed-battery-type-by-model/" + formData?.model_name
        }
        idKey={"battery_type_id"}
        valueKey={"battery_part_number"}
        parentKey={""}
        onSelect={(e) => {
          handleInputChange(e, crrIndex);
        }}
        shouldFetchOnOpen={true}
        isCache={true}
        disabled={ toogleKeyName ? true : disabled }
        initialSelectedValue={crrFormData?.charge_pak_part}
        defaultValue={{
          id: crrFormData?.charge_pak_part,
          value: crrFormData?.battery_part_number,
        }}
      />
    </>
  );
};

export default BatteryPakComp;

/*
 */
