
import React from 'react';
import LoginPage from '../domains/auth/pages/LoginPage';
import RegisterPage from '../domains/auth/pages/RegisterPage';
import OTPVerificationPage from '../domains/auth/pages/OTPVerificationPage';
import ForgotPasswordPage from '../domains/auth/pages/ForgotPasswordPage';
import TrainingPage from '../domains/Training/pages/TrainingPage';
import CalendarPage from '../domains/Training/pages/CalendarPage';
import Layout from '../domains/auth/pages/Layout';
import VerifyOTP from '../domains/auth/pages/OTPVerificationPage';
import ResetPassword from '../domains/auth/pages/ResetPassword';

const  PublicRoutes = [
  {title: "Login", path: "/",component: <Layout> <LoginPage /> </Layout>},
  {title: "Login", path: "/login",component: <Layout> <LoginPage /> </Layout>},
  {title: "Ragister", path: "/register",component: <Layout> <RegisterPage /> </Layout>},
  {title: "otp-verification", path: "/otp-verification",component: <Layout> <OTPVerificationPage /> </Layout>},
  {title: "forgot-password", path: "/forgot-password",component: <Layout> <ForgotPasswordPage /> </Layout>},
  {title: "training", path: "/training",component: <Layout> <TrainingPage /> </Layout>},
  {title: "calendar", path: "/calendar",component: <Layout> <CalendarPage /> </Layout>},
  {title: "verify-otp", path: "/verify-otp",component: <Layout> <VerifyOTP /> </Layout>},
  // {title: "forgot-password", path: "/forgot-password",component: <Layout> <ForgotPasswordPage /> </Layout>},
  {title: "reset-password", path: "/reset-password",component: <Layout> <ResetPassword /> </Layout>},
]; 

export default PublicRoutes;