// SiteSelector.jsx
import React from "react";
import { Form } from "react-bootstrap";
import GlobalDropdown from "../../../../common/components/custom-ui/GlobalDropdown";

const SiteSelector = ({ selectSite, setSelectedSite }) => {
  return (
    <div className="row" style={{ maxWidth: "300px" }}>
      <Form.Group className="col-md mb-3">
        <Form.Label>Site</Form.Label>
        <GlobalDropdown
          dropDownName={"siteList"}
          apiEndpoint={"acc/fetch-site-inventory"}
          idKey={"site_id"}
          valueKey={"site_name"}
          parentKey={""}
          onSelect={(e) => setSelectedSite(e.target.value)}
          shouldFetchOnOpen={true}
          isCache={true}
        />
      </Form.Group>
    </div>
  );
};

export default SiteSelector;