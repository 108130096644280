import React from "react";
import { Skeleton } from "@mui/material";
const PadInfoSkeleton = () => (
  <>
    <table className="theme-table">
      <tbody>
        <tr>
          <td>
            <Skeleton className="h-4 w-[200px]" />
          </td>
          <td>
            <Skeleton className="h-8 w-[60px]" />
          </td>
        </tr>
      </tbody>
    </table>

    <div className="account-info pb-4">
      <table className="w-100">
        <thead>
          <tr>
            {[
              "Pad Type",
              "Part #",
              "Expiration Date",
              "Pad Lot",
              "Pad UDI",
            ].map((header, index) => (
              <th
                key={index}
                scope="col"
                className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
              >
                <Skeleton variant="text" width={100} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="odd-even-row border-b-blue">
          {[...Array(5)].map((_, rowIndex) => (
            <tr key={rowIndex}>
              {[...Array(5)].map((_, colIndex) => (
                <td key={colIndex} className="border border-2 py-1 px-2">
                  <Skeleton variant="text" width={colIndex === 0 ? 150 : 100} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
);

export default PadInfoSkeleton;
