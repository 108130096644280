import React from 'react'
import SupportDetails from '../../Component/Support-details'

function AdminDetails() {
    return (
        <div>
            <SupportDetails />
        </div>
    )
}

export default AdminDetails
