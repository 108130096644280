import React from 'react';
import Skeleton from "@mui/material/Skeleton";

const AEDChecksTableSkeleton = ({ columns }) => {
  const renderSkeletonRow = () => (
    <tr>
      {columns.map((column, index) => (
        <td key={index}>
          <Skeleton animation="wave" height={20} />
        </td>
      ))}
    </tr>
  );

  return (
    <table className="table table-bordered table-hover">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index} style={{ backgroundColor: "#999" }}>
              <Skeleton animation="wave" height={20} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 5 }).map((_, index) => renderSkeletonRow())}
      </tbody>
    </table>
  );
};

export default AEDChecksTableSkeleton;