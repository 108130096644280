import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { useState } from "react";
import RangeDatePicker from "../../../../common/components/date-picker/RangeDatePicker";
import { BASE, CallPOSTAPI } from "../../../../common/services";
import { printConsole } from "../../../../common/helper/Common";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const RangeDateReportModal = ({
  reportModal,
  setReportModal,
  apiEndPoint = "",
  tab = "",
  accountId,
  siteId,
}) => {
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
  });

  const handleClose = () => {
    setReportModal(false);
    setFormData("");
  };

  const handleExport = async () => {
    if (!formData.startDate && !formData.endDate) {
      toast.error("Ensure that both start and end dates have been selected.");
      return;
    }

    let payload;

    if (siteId) {
      payload = {
        account_id: accountId,
        site_id: siteId,
        tab: tab,
        from: formData.startDate,
        to: formData.endDate,
      };
    } else {
      payload = {
        account_id: accountId,
        tab: tab,
        from: formData.startDate,
        to: formData.endDate,
      };
    }

    const response = await CallPOSTAPI(apiEndPoint, payload);
    printConsole({ status: response?.data?.status }); // print console.log
    if (response?.data?.status) {
      setReportModal(false);
      setFormData("");

      const fileUrl = response?.data?.data;

      if (fileUrl) {
        const downloadLink = document.createElement("a");

        downloadLink.href = `${BASE}/${fileUrl}`;
        downloadLink.download = fileUrl.split("/").pop();
        downloadLink.style.display = "none";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } else {
      setReportModal(false);
      toast.error("No file available for download.");
    }
    setFormData("");
  };

  return (
    <div>
      <Modal open={reportModal} onClose={handleClose}>
        <Box sx={style}>
          <h2 style={{ margin: 0, fontWeight: 600, fontSize: 25 }}>
            Select Date:
          </h2>

          <div className="d-flex mt-4">
            <RangeDatePicker formData={formData} setFormData={setFormData} />
          </div>

          <div
            className="col-12 content-flex-center"
            style={{
              marginTop: "5%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn text-uppercase cancel-button"
              type="button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              onClick={handleExport}
              className="btn text-uppercase ms-4 submit-button"
              type="submit"
            >
              Export
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default RangeDateReportModal;
