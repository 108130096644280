import React from "react";
import CustomToggleButton2 from "../../../../common/components/toggleSwitch/CustomToggleButton2";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  StorageInfoTableSkeleton,
  InfoCorrectTableSkeleton,
} from "./AedSkeletons/StorageInfoTableSkeleton";

const AEDStorageInfoTables = ({
  showLoading,
  formData,
  handleToggleChange,
  FormatDate,
}) => {
    if (showLoading) {
    return (
      <>
        <InfoCorrectTableSkeleton />
        <StorageInfoTableSkeleton />
      </>
    );
  }

  return (
    <>
      <table className="theme-table">
        <tbody>
          <tr>
            <td>
              {formData?.aed_storage_currect
                ? "Is below information correct?"
                : "Is below information correct?"}
            </td>
            <td>
              <CustomToggleButton2
                ToggleName="aed_storage_currect"
                ToggleValue={Number(formData?.aed_storage_currect) === 1 ? 1 : 0}
                changeHandler={handleToggleChange}
                is_read_only={true}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table className="theme-table">
        <thead>
          <tr>
            <td>Storage Type</td>
            <td>Alarmed</td>
            <td>Turned On</td>
            <td>Does the Alarm sound?</td>
            <td>9v Expiration</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {formData?.storage_part_name || "N/A"}
            </td>
            <td>
              {parseInt(formData?.sti_storage_type) !== 1 || formData?.sti_storage_type === "" ? (
               "N/A"
              ) : (
              <>
              {formData?.sti_alarmed ? (
               <CheckIcon sx={{ color: "#00FF00" }} />
              ) : (
               <CloseIcon color="error" />
              )}
              </>
              )}
            </td>
            <td>
              {parseInt(formData?.sti_storage_type) !== 1 || formData?.sti_storage_type == "" ? ( 
                "N/A"
              ) : (
                <>
                {formData?.sti_turned_on ? (
                  <CheckIcon sx={{ color: "#00FF00" }} />
                ) : (
                  <CloseIcon color="error" />
                )}
                </>
              )}
            </td>
            <td>
              {parseInt(formData?.sti_storage_type) !== 1 || formData?.sti_storage_type == "" ? (
                "N/A"
              ) : (
                <>
                {formData?.alarme_sound ? (
                <CheckIcon sx={{ color: "#00FF00" }} />
                ) : (
                <CloseIcon color="error" />
                )}
                </>
              )}
 
            </td>
            <td style={{ width: "400px" }}>
              {parseInt(formData?.sti_storage_type) !== 1 || formData?.sti_storage_type == "" ? ( 
                "N/A"
              ) : (
                <>
                {FormatDate(formData?.sti_expiry_date) === "" ? (
                  "N/A"
                ) : (
                  <>
                {FormatDate(formData?.sti_expiry_date)} 
                  </>
                )}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default AEDStorageInfoTables;
