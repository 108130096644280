import React, { useState } from 'react'
import Listing from '../components/Listing/Listing';

const EquipmentListing = () => {

    const [tabTbldata, setTabTbldata] = useState({
        site: false,
        contact: false,
        equipment: {
          aed: false,
          accessory: false,
        },
        note: false,
        support: false,
      });
    
  return (
    <>
    <Listing
        tabTbldata={tabTbldata} 
        setTabTbldata={setTabTbldata}
    />
    </>
  )
}

export default EquipmentListing;