import React from 'react'
// import './table.scss';
import '../../../domains/accounts/components/skeleton/table/table.scss';
// src/domains/accounts/components/skeleton/table/table.scss
export default function TableSkeleton({tdCount=3,onlyTr=false,trCount=10}) {
    const arr = [];
    for (let index = 0; index < tdCount; index++) {
        arr.push(index);
    }
    const trArr = []
    for (let index = 0; index < trCount; index++) {
        trArr.push(index);
    }
  


    if(onlyTr){
        return <> {trArr.map(it=>(
            <tr className='' style={{height:'40px',weight:"100%"}}>
                {arr.map((tem)=>(
                    <th className="loading">
                        <div className="bar"></div>
                    </th>
                ))}
            </tr>
        ))}</>
        
    }
    return (
        <div className="tableWrapper">
            <table className="table">
                <thead>
                <tr>
                    {arr.map((tem)=>(
                    <th className="loading">
                    <div className="bar"></div>
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                    <tr>
                    {arr.map((tem)=>(
                    <td className="loading">
                        <div className="bar"></div>
                    </td>
                    ))}
                    </tr>
                    <tr>
                    {arr.map((tem)=>(
                    <td className="loading">
                        <div className="bar"></div>
                    </td>
                    ))}
                    </tr>
                    <tr>
                    {arr.map((tem)=>(
                    <td className="loading">
                        <div className="bar"></div>
                    </td>
                    ))}
                    </tr>

                    <tr>
                    {arr.map((tem)=>(
                    <td className="loading">
                        <div className="bar"></div>
                    </td>
                    ))}
                    </tr>

                    <tr>
                    {arr.map((tem)=>(
                    <td className="loading">
                        <div className="bar"></div>
                    </td>
                    ))}
                    </tr>

                    <tr>
                    {arr.map((tem)=>(
                    <td className="loading">
                        <div className="bar"></div>
                    </td>
                    ))}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
