import React, { useCallback, useState, useRef } from "react";
import Moment from "react-moment";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";

const DetailsListTbl = ({ emailId, tableReset }) => {
  const [data, setData] = useState([]);
  const dataRef = useRef([]);

  const headerColumns = [
    {
      Header: "Sender",
      width: "12%",
      disableSortBy: true,
      Cell: () => <span>System</span>,
    },
    {
      Header: "Receiver",
      accessor: "email",
      width: "12%",
      disableSortBy: true,
    },
    {
      Header: "Email Date",
      width: "12%",
      Cell: ({ row }) => (
        <span>
          <Moment date={row?.original.created_date} format="MM/DD/YYYY" />
        </span>
      ),
      disableSortBy: true,
    },
    {
      Header: "Resend/Forward",
      width: "12%",
      Cell: ({ row }) => (
        <span>
          {row.original.resend}/{row.original.forword}
        </span>
      ),
      disableSortBy: true,
    },
  ];

  const handleDataUpdate = useCallback(
    (newData) => {
      if (Array.isArray(newData)) {
        dataRef.current = newData;

        setData(newData);
        return newData;
      }
      return [];
    },
    [dataRef]
  );

  return (
    <div className="details-list-wrapper" style={{
      width: "100%",
      paddingInline: "0px",
      marginBottom: "400px",
    }}>
      <NewGlobalTable
        method="GET"
        isSearchable={false}
        isCache={true}
        pageSize={20}
        customRenderData={handleDataUpdate}
        apiEndPoint={`email/email-by-id/${emailId}`}
        keyAPiName="emailDetails"
        headerColumns={headerColumns}
        isTblUpdated={tableReset}
      />

      {/* {JSON.stringify({ dataRef })} */}

      {dataRef.current.length > 0 ? (
        <div className="email-data mt-4 mb-4">
          <h4 className="heading">Email</h4>
          {console.log({ dataRef })}
          <div className="email-details mb-3">
            {/* <strong>Subject:</strong> {dataRef.current[0].subject} */}
          </div>
          <div className="email-details mb-3">
            {/* <strong>To:</strong> {dataRef.current[0].email} */}
          </div>
          <div
            className="email-body py-4 px-3 border rounded"
            dangerouslySetInnerHTML={{
              __html: dataRef.current ? dataRef.current[0].body : "",
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default React.memo(DetailsListTbl);
