import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CallGETAPI } from '../../../common/services';
import { printConsole } from '../../../common/helper/Common';

// Async thunks to fetch data from the APIs
export const fetchApi1 = createAsyncThunk('combinedData/fetchApi1', async (popId) => {
    const response = await CallGETAPI(`pop/get-pop-performance/${popId}`);
    return response.data;
});

export const fetchApi2 = createAsyncThunk('combinedData/fetchApi2', async (popId) => {
    const response = await CallGETAPI(`pop/get-pop-site-details/${popId}`);
    return response.data;
});

export const fetchApi3 = createAsyncThunk('combinedData/fetchApi3', async (popId) => {
    const response = await CallGETAPI(`pop/get-pop-site-details/${popId}`);
    return response.data;
});

// Initial state
const initialState = {
    data1: null,
    data2: null,
    data3: null,
    combinedData: null,
    status: 'idle',
    error: null,
};

// Slice
const combinedDataSlice = createSlice({
    name: 'combinedData',
    initialState,
    reducers: {
        // optional reducers if needed for manual updates
    },
    extraReducers: (builder) => {
        builder
            // Fetch API 1 data
            .addCase(fetchApi1.fulfilled, (state, action) => {
                state.data1 = action.payload;
            })
            // Fetch API 2 data
            .addCase(fetchApi2.fulfilled, (state, action) => {
                state.data2 = action.payload;
            })
            // Fetch API 3 data
            .addCase(fetchApi3.fulfilled, (state, action) => {
                state.data3 = action.payload;
            })
            // Combine data when all APIs are fetched
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    if (state.data1 && state.data2 && state.data3) {
                        // Combine data from the three APIs
                        state.combinedData = {
                            ...state.data1,
                            ...state.data2,
                            ...state.data3,
                        };
                        state.status = 'fulfilled';
                    }
                }
            )
            // Handle loading and errors
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.status = 'loading';
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.status = 'failed';
                    state.error = action.error.message;
                }
            );
    },
});

// Selectors
export const selectCombinedData = (state) => state.combinedData?.combinedData;
export const selectStatus = (state) => state.combinedData?.status;
export const selectError = (state) => state.combinedData?.error;

// Export the reducer
export default combinedDataSlice.reducer;

// Action to get combined data as a variable
export const getCombinedData = (state) => {
    const combinedData = state.combinedData?.combinedData;
    printConsole('Combined Data:', combinedData); // print console.log
    return combinedData;
};
