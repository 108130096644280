import { Skeleton } from '@mui/material';

const AEDInfoSkeleton = () => (
  <tr>
    <td>AED</td>
    <td><Skeleton className="h-4 w-[100px]" /></td>
    <td><Skeleton className="h-4 w-[100px]" /></td>
    <td><Skeleton className="h-4 w-[100px]" /></td>
    <td><Skeleton className="h-4 w-[100px]" /></td>
  </tr>
);

export default AEDInfoSkeleton;