import React, { useEffect, useState } from "react";
import { CallGETAPI } from "../../../../common/services";
import { useNavigate } from "react-router-dom";
import {
  DecryptToken,
  formatPhoneNumber,
  getPermission,
} from "../../../../common/helper";
import Edit from "../../../../common/img/Edit.png";
import New from "../../../../common/img/New.png";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  addNewBreadcrumbs,
  handleBreadcrumbsClick,
} from "../../../../store/slices/breadcrumbsSlice";
import { getPer } from "../../../../common/helper/BasicFn";
const SiteTranningAddress = ({ siteId, siteData }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const privileges = getPermission();
  const user = DecryptToken();
  const dispatch = useDispatch();
  const handleDelete = async (id) => {
    const res = await CallGETAPI(
      `site/delete-training-address/${id}?site-traningaddress`
    );
    if (res?.status) {
      toast.success(res?.data?.msg);
      fetchData();
    } else {
      toast.error(res?.data?.msg);
    }
  };
  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "site",
  };
  const handleDispatch = (accountId, siteId) => {
    BreadCrumbsObject.title = "New Trainning site Form";
    BreadCrumbsObject.tab = "SITE_DETAILS";
    BreadCrumbsObject.type = "account";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/" + accountId + "/site/" + siteId + "/training/new",
      })
    );
  };
  const handleEditDispatch = (
    account_id,
    account_site_info_id,
    account_alternate_traning_location_id
  ) => {
    BreadCrumbsObject.title = "Edit Trainning Address";
    BreadCrumbsObject.tab = "SITE_DETAILS";
    BreadCrumbsObject.type = "sites";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url:
          "/account/" +
          account_id +
          "/site/" +
          account_site_info_id +
          `/${account_alternate_traning_location_id}/training/edit`,
      })
    );
  };
  const [trainingData, setTraningData] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    const result = await CallGETAPI("site/site-trainning-data/" + siteId);
    if (result?.data?.status) {
      const resultData = result?.data?.data?.trainingLocations;
      setTraningData(resultData);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []); // fetch API Calling
  return (
    <>
      <table className="w-100 no-border">
        <tr className="w-100 bg-blue no-border">
          <th
            colSpan={3}
            className="tbl-title text-left no-border"
            style={{ backgroundColor: "ghostwhite" }}
          >
            Training Address
          </th>
          <th
            colSpan={1}
            className="no-border"
            style={{ textAlign: "end", backgroundColor: "ghostwhite" }}
          >
            {Number(getPer()) === 1 && privileges?.includes("training-new") && (
              <button
                className="bg-transparent new-btn align-right"
                onClick={() => {
                  handleDispatch(
                    siteData.account_id,
                    siteData.account_site_info_id
                  );
                  navigate(
                    "/account/" +
                      siteData.account_id +
                      "/site/" +
                      siteData.account_site_info_id +
                      "/training/new"
                  );
                }}
              >
                <img src={New} style={{ marginRight: "5px" }} />
                New
              </button>
            )}
          </th>
        </tr>
        <tr>
          <th>
            <span>Company Name</span>
          </th>
          <th style={{ width: "200px" }}>
            <span>Phone Number</span>
          </th>
          <th>
            <span>Address</span>
          </th>
          <th>Actions</th>
        </tr>

        <tr>
          <td className="py-1 px-2 tbl-border ">
            {loading ? (
              <>
                <Skeleton
                  count={5}
                  width={"90%"}
                  height={20}
                  style={{ marginLeft: "2px", marginRight: "2px" }}
                />
              </>
            ) : (
              <>{siteData?.account_site_name}</>
            )}
          </td>
          <td className="py-1 px-2 tbl-border ">
            {loading ? (
              <>
                <Skeleton
                  count={5}
                  width={"90%"}
                  height={20}
                  style={{ marginLeft: "2px", marginRight: "2px" }}
                />
              </>
            ) : (
              <>
                {siteData?.account_site_phone && (
                  <a
                    className="link"
                    style={{ textDecoration: "none" }}
                    href={
                      "tel:" +
                      siteData?.account_site_phone_ext +
                      siteData?.account_site_phone
                    }
                  >
                    {siteData?.account_site_phone
                      ? formatPhoneNumber(siteData.account_site_phone)
                      : ""}{" "}
                    {siteData?.account_site_phone_ext
                      ? " X " + siteData?.account_site_phone_ext
                      : ""}
                  </a>
                )}
              </>
            )}
          </td>
          <td className="py-1 px-2 tbl-border ">
            {loading ? (
              <>
                <Skeleton
                  count={5}
                  width={"90%"}
                  height={20}
                  style={{ marginLeft: "2px", marginRight: "2px" }}
                />
              </>
            ) : (
              <>
                {siteData?.account_site_address1}{" "}
                {siteData?.account_site_address2} {siteData?.account_site_city}{" "}
                {siteData?.account_site_state} {siteData?.account_site_country}{" "}
                {siteData?.account_site_zipcode}
              </>
            )}
          </td>
          <td></td>
        </tr>

        {trainingData &&
          trainingData?.map((d, index) => (
            <tr className="" key={index}>
              <td className="py-1 px-2 tbl-border ">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{d?.account_alternate_traning_location_company_name}</>
                )}
              </td>
              <td className="py-1 px-2 tbl-border ">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>
                    {d?.alternative_phone && (
                      <a
                        className="link"
                        style={{ textDecoration: "none" }}
                        href={
                          "tel:" +
                          d?.alternative_phone +
                          "p" +
                          d?.alternative_ext
                        }
                      >
                        {d?.alternative_phone
                          ? formatPhoneNumber(d.alternative_phone)
                          : ""}{" "}
                        {d?.alternative_ext ? " X " + d?.alternative_ext : ""}
                      </a>
                    )}
                  </>
                )}
              </td>
              <td className=" py-1 px-2 tbl-border ">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>
                    {d?.account_alternate_traning_location_address1}
                    {d?.account_alternate_traning_location_address2}{" "}
                    {d?.account_alternate_traning_location_city}{" "}
                    {d?.account_alternate_traning_location_state_name}{" "}
                    {d?.account_alternate_traning_location_country_name}{" "}
                    {d?.account_alternate_traning_location_zipcode}
                  </>
                )}
              </td>
              <td className=" py-1 px-2 tbl-border d-flex">
                <button
                  className="new-btn btn  d-flex edit "
                  onClick={() => {
                    handleEditDispatch(
                      siteData.account_id,
                      siteData.account_site_info_id,
                      d?.account_alternate_traning_location_id
                    );
                    navigate(
                      "/account/" +
                        siteData.account_id +
                        "/site/" +
                        siteData.account_site_info_id +
                        `/${d?.account_alternate_traning_location_id}/training/edit`,
                      {
                        state: {
                          trainingData: trainingData[index],
                        },
                      }
                    );
                  }}
                >
                  <img src={Edit} style={{ width: "20px", height: "20px" }} />
                  Edit
                </button>
                <button
                  className="new-btn btn d-flex delete "
                  onClick={() =>
                    handleDelete(d.account_alternate_traning_location_id)
                  }
                >
                  <img
                    src="/assets/images/trashcan_icon.svg"
                    alt="TrashIcon"
                    style={{ width: "20px", height: "20px" }}
                  />
                  Delete
                </button>
              </td>
            </tr>
          ))}
        {/* </>} */}
      </table>
    </>
  );
};

export default SiteTranningAddress;
