// import { createSlice } from "@reduxjs/toolkit";
// import { printConsole } from "../../common/helper/Common";

// const initialState = {
//   breadcrumbs: [], // Array of breadcrumb objects
//   removedBreadcrumbs: [],
//   stack: [],
// };
// const breadcrumbSlice = createSlice({
//   name: "breadcrumbs",
//   initialState,
//   reducers: {
//     addNewBreadcrumbs: (state, action) => {
//       printConsole("action.payload", action.payload); // print console.log
//       const { title, url } = action.payload;
//       const tab = action.payload.tab || "";
//       const type = action.payload.type || "";
//       const accountName = action.payload.account_Name || "";
//       const accountId = action.payload.accountId || "";
//       const urlParts = url.split("/");
//       const lastPartOfUrl = urlParts[urlParts.length - 1];
//       const existingBreadcrumbIndex = state.breadcrumbs
//         ? state.breadcrumbs.findIndex((breadcrumb) => breadcrumb.url === url)
//         : -1;
//       if (existingBreadcrumbIndex === -1) {
//         if (lastPartOfUrl === "Details") {
//           state.breadcrumbs.push({
//             title,
//             url,
//             tab,
//             type,
//             accountName,
//             accountId,
//           });
//         } else {
//           const similarBreadcrumbIndex = state.breadcrumbs.findIndex(
//             (breadcrumb) => {
//               const breadcrumbUrlParts = breadcrumb.url.split("/");
//               const lastPartOfBreadcrumbUrl =
//                 breadcrumbUrlParts[breadcrumbUrlParts.length - 1];
//               return (
//                 breadcrumbUrlParts.slice(0, -1).join("/") ===
//                   urlParts.slice(0, -1).join("/") &&
//                 lastPartOfBreadcrumbUrl !== "Details"
//               );
//             }
//           );

//           if (similarBreadcrumbIndex !== -1) {
//             state.breadcrumbs[similarBreadcrumbIndex] = {
//               title,
//               url,
//               tab,
//               type,
//               accountName,
//               accountId,
//             };
//           } else {
//             state.breadcrumbs.push({
//               title,
//               url,
//               tab,
//               type,
//               accountName,
//               accountId,
//             });
//           }
//         }
//       } else {
//         state.breadcrumbs = state.breadcrumbs.slice(
//           0,
//           existingBreadcrumbIndex + 1
//         );
//       }
//     },

//     // addNewBreadcrumbs: (state, action) => {
//     //   const { title, url, tab, type, accountName, accountId } = action.payload;

//     //   // Directly push the breadcrumb without any checks
//     //   state.breadcrumbs.push({
//     //     title,
//     //     url,
//     //     tab: tab || "",
//     //     type: type || "",
//     //     accountName: accountName || "",
//     //     accountId: accountId || "",
//     //   });
//     // },

//     addNewBreadcrumbsMethod: (state, action) => {
//       const { title, url, tab, type, accountName, accountId } = action.payload;

//       // Check if breadcrumb with the same URL already exists
//       const existingBreadcrumbIndex = state.breadcrumbs.findIndex(
//         (breadcrumb) => breadcrumb.url === url
//       );

//       if (existingBreadcrumbIndex === -1) {
//         // If breadcrumb doesn't exist, add a new breadcrumb
//         state.breadcrumbs.push({
//           title,
//           url,
//           tab,
//           type,
//           accountName,
//           accountId,
//         });
//       } else {
//         state.breadcrumbs[existingBreadcrumbIndex] = {
//           title,
//           url,
//           tab,
//           type,
//           accountName,
//           accountId,
//         };
//       }
//     },

//     updateBreadcrumbs: (state, action) => {
//       state.breadcrumbs = action.payload;
//     },
//     handleBreadcrumbsClick: (state, action) => {
//       const { accountName, breadcrumbsFromStore } = action.payload;
//       function areStringsEqual(str1, str2) {
//         function cleanString(str) {
//           return str
//             .trim() // Remove leading and trailing spaces
//             .replace(/\s+/g, ""); // Remove all spaces (not just extra spaces between words)
//         }
//         const cleanedStr1 = cleanString(str1);
//         const cleanedStr2 = cleanString(str2);

//         return cleanedStr1 === cleanedStr2;
//       }
//       const name = accountName;
//       const detailsIndex = breadcrumbsFromStore.findIndex((breadcrumb) =>
//         areStringsEqual(breadcrumb.title, name + " Details")
//       );
//       const index = detailsIndex;
//       if (
//         detailsIndex !== -1 &&
//         detailsIndex < breadcrumbsFromStore.length - 1
//       ) {
//         state.breadcrumbs = state.breadcrumbs.slice(0, index + 1);
//       }
//     },
//     removeBreadcrumb: (state, action) => {
//       const urlToRemove = action.payload;
//       const breadcrumbsList = state.breadcrumbs;
//       const breadcrumbToRemove = breadcrumbsList.find(
//         (breadcrumb) => breadcrumb.url === urlToRemove
//       );
//       if (breadcrumbToRemove) {
//         if (
//           !state.removedBreadcrumbs.some(
//             (bc) => bc.url === breadcrumbToRemove.url
//           )
//         ) {
//           state.removedBreadcrumbs.push(breadcrumbToRemove);
//         }
//       } else {
//         printConsole(`Breadcrumb with URL ${urlToRemove} not found.`); // print console.log
//       }

//       state.breadcrumbs = state.breadcrumbs.filter(
//         (breadcrumb) => breadcrumb.url !== urlToRemove
//       );
//     },
//     removeBreadcrumbByUrl: (state, action) => {
//       const urlToRemove = action.payload;

//       state.stack = state.stack.filter(
//         (breadcrumb) => breadcrumb.url !== urlToRemove
//       );
//     },
//     restoreBreadcrumb: (state, action) => {
//       const breadcrumbToRestore = action.payload;
//       const removeList1 = state.removedBreadcrumbs;
//       state.breadcrumbs?.pop();
//       if (breadcrumbToRestore) {
//         state.breadcrumbs.push(breadcrumbToRestore);
//         state.removedBreadcrumbs = state.removedBreadcrumbs.filter(
//           (bc) => bc.url !== action.payload
//         );
//       }
//       const removeList = state.removedBreadcrumbs;
//     },
//     // Clear all breadcrumbs
//     clearBreadcrumbs: (state) => {
//       state.breadcrumbs = [];
//       state.removedBreadcrumbs = [];
//     },
//     addToHistoryStack: (state, action) => {
//       printConsole("action", action.payload); // print console.log
//       const { title, url } = action.payload;
//       const tab = action.payload.tab || "";
//       const type = action.payload.type || "";
//       const accountName = action.payload.accountName || "";
//       const accountId = action.payload.accountId || "";
//       if (state?.stack) {
//         state.stack = [
//           ...state.stack,
//           { title, url, tab, type, accountName, accountId },
//         ];
//       }
//     },
//     clearHistoryStack: (state) => {
//       state.stack = [];
//     },
//   },
// });

// export const {
//   addNewBreadcrumbs,
//   addNewBreadcrumbsMethod,
//   updateBreadcrumbs,
//   handleBreadcrumbsClick,
//   removeBreadcrumb,
//   clearBreadcrumbs,
//   removeBreadcrumbByUrl,
//   restoreBreadcrumb,
//   addToHistoryStack,
//   clearHistoryStack,
// } = breadcrumbSlice.actions;

// export default breadcrumbSlice.reducer;

// --------------------------------------

import { createSlice } from "@reduxjs/toolkit";
import { printConsole } from "../../common/helper/Common";

const initialState = {
  breadcrumbs: [], // Active breadcrumbs
  removedBreadcrumbs: [], // Breadcrumbs removed during navigation
  stack: [], // Additional history stack
};

const breadcrumbSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    addNewBreadcrumbs: (state, action) => {
      const { url } = action.payload;

      // Check if breadcrumb exists in removedBreadcrumbs
      const removedIndex = state.removedBreadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.url === url
      );

      if (removedIndex !== -1) {
        // Remove from removedBreadcrumbs and add to breadcrumbs
        const [restoredBreadcrumb] = state.removedBreadcrumbs.splice(
          removedIndex,
          1
        );
        state.breadcrumbs.push(restoredBreadcrumb);
      } else {
        // Add new breadcrumb if not existing
        const exists = state.breadcrumbs.some(
          (breadcrumb) => breadcrumb.url === url
        );
        if (!exists) {
          state.breadcrumbs.push(action.payload);
        }
      }
    },

    updateBreadcrumbs: (state, action) => {
      // Find breadcrumbs being removed
      const removedBreadcrumbs = state.breadcrumbs.filter(
        (oldBreadcrumb) =>
          !action.payload.some(
            (newBreadcrumb) => newBreadcrumb.url === oldBreadcrumb.url
          )
      );

      // Add removed breadcrumbs to removedBreadcrumbs
      removedBreadcrumbs.forEach((removedBreadcrumb) => {
        if (
          !state.removedBreadcrumbs.some(
            (existing) => existing.url === removedBreadcrumb.url
          )
        ) {
          state.removedBreadcrumbs.push(removedBreadcrumb);
        }
      });

      // Update breadcrumbs
      state.breadcrumbs = action.payload;
    },

    handleBreadcrumbsClick: (state, action) => {
      const { clickedUrl } = action.payload;

      // Find the index of the clicked breadcrumb
      const clickedIndex = state.breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.url === clickedUrl
      );

      if (clickedIndex !== -1) {
        // Remove breadcrumbs after the clicked breadcrumb and add to removedBreadcrumbs
        const removedBreadcrumbs = state.breadcrumbs.slice(clickedIndex + 1);

        // Add removed breadcrumbs to removedBreadcrumbs array if not already present
        removedBreadcrumbs.forEach((breadcrumb) => {
          if (
            !state.removedBreadcrumbs.some((bc) => bc.url === breadcrumb.url)
          ) {
            state.removedBreadcrumbs.push(breadcrumb);
          }
        });

        // Keep only breadcrumbs up to and including the clicked breadcrumb
        state.breadcrumbs = state.breadcrumbs.slice(0, clickedIndex + 1);
      }
    },

    clearBreadcrumbs: (state) => {
      state.breadcrumbs = [];
      state.removedBreadcrumbs = [];
    },

    addToHistoryStack: (state, action) => {
      if (state?.stack) {
        state.stack = [
          ...state.stack,
          {
            ...action.payload,
            tab: action.payload.tab || "",
            type: action.payload.type || "",
            accountName: action.payload.accountName || "",
            accountId: action.payload.accountId || "",
          },
        ];
      }
    },

    clearHistoryStack: (state) => {
      state.stack = [];
    },

    // ----- extra code -----

    addNewBreadcrumbsMethod: (state, action) => {
      const { title, url, tab, type, accountName, accountId } = action.payload;

      // Check if breadcrumb with the same URL already exists
      const existingBreadcrumbIndex = state.breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.url === url
      );

      if (existingBreadcrumbIndex === -1) {
        // If breadcrumb doesn't exist, add a new breadcrumb
        state.breadcrumbs.push({
          title,
          url,
          tab,
          type,
          accountName,
          accountId,
        });
      } else {
        state.breadcrumbs[existingBreadcrumbIndex] = {
          title,
          url,
          tab,
          type,
          accountName,
          accountId,
        };
      }
    },

    // updateBreadcrumbs: (state, action) => {
    //   state.breadcrumbs = action.payload;
    // },
    // handleBreadcrumbsClick: (state, action) => {
    //   const { accountName, breadcrumbsFromStore } = action.payload;
    //   function areStringsEqual(str1, str2) {
    //     function cleanString(str) {
    //       return str
    //         .trim() // Remove leading and trailing spaces
    //         .replace(/\s+/g, ""); // Remove all spaces (not just extra spaces between words)
    //     }
    //     const cleanedStr1 = cleanString(str1);
    //     const cleanedStr2 = cleanString(str2);

    //     return cleanedStr1 === cleanedStr2;
    //   }
    //   const name = accountName;
    //   const detailsIndex = breadcrumbsFromStore.findIndex((breadcrumb) =>
    //     areStringsEqual(breadcrumb.title, name + " Details")
    //   );
    //   const index = detailsIndex;
    //   if (
    //     detailsIndex !== -1 &&
    //     detailsIndex < breadcrumbsFromStore.length - 1
    //   ) {
    //     state.breadcrumbs = state.breadcrumbs.slice(0, index + 1);
    //   }
    // },
    removeBreadcrumb: (state, action) => {
      const urlToRemove = action.payload;
      const breadcrumbsList = state.breadcrumbs;
      const breadcrumbToRemove = breadcrumbsList.find(
        (breadcrumb) => breadcrumb.url === urlToRemove
      );
      if (breadcrumbToRemove) {
        if (
          !state.removedBreadcrumbs.some(
            (bc) => bc.url === breadcrumbToRemove.url
          )
        ) {
          state.removedBreadcrumbs.push(breadcrumbToRemove);
        }
      } else {
        printConsole(`Breadcrumb with URL ${urlToRemove} not found.`); // print console.log
      }

      state.breadcrumbs = state.breadcrumbs.filter(
        (breadcrumb) => breadcrumb.url !== urlToRemove
      );
    },
    removeBreadcrumbByUrl: (state, action) => {
      const urlToRemove = action.payload;

      state.stack = state.stack.filter(
        (breadcrumb) => breadcrumb.url !== urlToRemove
      );
    },
    restoreBreadcrumb: (state, action) => {
      const breadcrumbToRestore = action.payload;
      const removeList1 = state.removedBreadcrumbs;
      state.breadcrumbs?.pop();
      if (breadcrumbToRestore) {
        state.breadcrumbs.push(breadcrumbToRestore);
        state.removedBreadcrumbs = state.removedBreadcrumbs.filter(
          (bc) => bc.url !== action.payload
        );
      }
      const removeList = state.removedBreadcrumbs;
    },
    // Clear all breadcrumbs
    clearBreadcrumbs: (state) => {
      state.breadcrumbs = [];
      state.removedBreadcrumbs = [];
    },
  },
});

export const {
  addNewBreadcrumbs,
  updateBreadcrumbs,
  clearBreadcrumbs,
  restoreLastRemovedBreadcrumb,
  addToHistoryStack,
  clearHistoryStack,
  // /----
  removeBreadcrumb,
  handleBreadcrumbsClick,
  addNewBreadcrumbsMethod,
} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
