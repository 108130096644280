import { Skeleton } from '@mui/material'

export default function TableSkeleton() {
  return (
    <table className="table data-table my-4 theme-table">
      <thead className="thread-style">
        <tr>
          <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
            <Skeleton width={100} height={20} />
          </th>
          <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
            <Skeleton width={100} height={20} />
          </th>
          <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
            <Skeleton width={100} height={20} />
          </th>
          <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
            <Skeleton width={100} height={20} />
          </th>
          <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
            <Skeleton width={100} height={20} />
          </th>
          <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
            <Skeleton width={100} height={20} />
          </th>
          <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
            <Skeleton width={100} height={20} />
          </th>
          <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
            <Skeleton width={100} height={20} />
          </th>
        </tr>
      </thead>
      <tbody className="bordered-table">
        {Array(5)
          .fill()
          .map((_, i) => (
            <tr key={i}>
              <td className="text-center">
                <Skeleton width={150} height={20} />
              </td>
              <td className="text-center">
                <Skeleton width={150} height={20} />
              </td>
              <td className="text-center">
                <Skeleton width={150} height={20} />
              </td>
              <td className="text-center">
                <Skeleton width={150} height={20} />
              </td>
              <td className="text-center">
                <Skeleton width={150} height={20} />
              </td>
              <td className="text-center">
                <Skeleton width={150} height={20} />
              </td>
              <td className="text-center">
                <Skeleton width={150} height={20} />
              </td>
              <td className="text-center">
                <Skeleton width={150} height={20} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
