import React, { useEffect, useState } from "react";
import {
  Form,
  Button as BButton,
  Button as BsButton,
  InputGroup,
} from "react-bootstrap";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services";
import { sortArrAscending } from "../../../common/helper/constants";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import UpdateRoleToPositionModal from "../component/UpdateRoleToPositionModal";
import EditRoleToPositionModal from "../Model/EditRoleToPositionModal";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";

const RoleToPosition = () => {
  const [positions, setPositions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [positionId, setPositionId] = useState("");
  const [active, setActive] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedPositionObj, setSelectedPositionObj] = useState({});
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleobj, setSelectedRoleObj] = useState({});
  const [assignPermissions, setAssignPermissions] = useState([]);
  const [sortedPermissionsList, setSortedPermissionsList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [rowData, setRowData] = useState({});

  const [invalid, setInvalid] = useState({
    position: false,
    role: false,
  });

  const fetchPositions = async () => {
    let response = await CallGETAPI(
      `permission/fetch-position?page=${1}&pageSize=${50}`
    );
    if (response?.status) {
      let sortedArr = sortArrAscending(
        response?.data?.data?.role,
        "position_name"
      );
      setPositions(sortedArr);
    }
  };

  const fetchRoles = async () => {
    let response = await CallGETAPI(
      `permission/get-role?page=${1}&pageSize=${50}`
    );
    if (response?.status) {
      let sortedArr = sortArrAscending(response?.data?.data?.role, "role_name");
      setRoles(sortedArr);
    }
  };

  const fetchAssignPermission = async () => {
    let response = await CallGETAPI(
      "permission/fetch-assign-permission-position"
    );
    if (response?.status) {
      setAssignPermissions(response?.data?.data);
    }
  };

  useEffect(() => {
    let sortedArr = sortArrAscending(assignPermissions, "position_name");
    setSortedPermissionsList(sortedArr);
  }, [assignPermissions]);

  const fillRolesDD = () => {
    return roles?.map((item) => {
      if (item.is_admin_role === 0) {
        return <option value={item?.role_id}>{item?.role_name}</option>;
      }
    });
  };

  const fillPositionsDD = () => {
    return positions?.map((item) => {
      return <option value={item?.position_id}>{item?.position_name}</option>;
    });
  };

  const handleSubmit = async () => {
    let errors = {};

    if (selectedPosition === "") {
      errors.position = true;
    }
    if (selectedRole === "") {
      errors.role = true;
    }

    if (Object.keys(errors).length > 0) {
      setInvalid({ ...invalid, ...errors });
      return;
    }

    setLoading(true);
    let body = { position_id: selectedPosition, role_id: selectedRole };
    let response = await CallPOSTAPI("permission/assign-role-position", body);

    if (response?.status) {
      setSelectedRole("");
      setSelectedPosition("");
      setLoading(false);
      fetchCreateTblData();
      toast.success("Role assigned successfully.");
    } else {
      setSelectedRole("");
      setLoading(false);
      toast.success("Something went wrong.");
    }

    setSelectedPositionObj({});
    setSelectedRoleObj({});
  };

  const handleUpdate = async (data) => {
    setActive(data?.active);
    setPositionId(data?.position_id);
    setUpdateModal(true);
  };

  const renderActions = (data) => {
    return (
      <div style={{ display: "flex", gap: "1%" }}>
        <div>
          {data && data?.active === 1 ? (
            <button
              onClick={() => handleUpdate(data)}
              type="button"
              style={{
                width: 100,
                height: "30px",
                background: "#d32f2f",
                border: "none",
                color: "white",
                borderRadius: 12,
              }}
            >
              Deactivate
            </button>
          ) : (
            <button
              onClick={() => handleUpdate(data)}
              type="button"
              style={{
                width: 100,
                height: "30px",
                border: "none",
                background: "green",
                color: "white",
                borderRadius: 12,
              }}
            >
              Activate
            </button>
          )}
        </div>
        <button
          className="text-primary"
          type="button"
          onClick={() => {
            setEdit(true);
            setRowData(data);
            fetchPositions();
            fetchRoles();
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "none",
            background: "transparent",
          }}
        >
          <img
            src="/assets/images/edit.svg"
            alt="svg"
            style={{ marginRight: "0px" }}
          />
          <span className="ms-2">Edit</span>
        </button>
      </div>
    );
  };

  const renderStatus = (data) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "7px",
        }}
      >
        {data && data?.active == 1 ? (
          <p style={{ color: "green" }}>Active</p>
        ) : (
          <p style={{ color: "#d32f2f" }}>Deactivated</p>
        )}
      </div>
    );
  };

  const headerColumns = [
    {
      Header: "Position Name",
      accessor: "position_name", // Maps to "position_name" field in the data
      dataType: "string", // Data type for consistency
    },
    {
      Header: "Role",
      accessor: "role_name", // Maps to "role_name" field
      dataType: "string", // Data type for consistency
      // Sorting can be enabled or disabled as needed
    },
    {
      Header: "Status",
      accessor: "status", // The field "status" is not explicitly mentioned in the data, but the render function is used
      dataType: "string",
      sortType: "basic", // Enable sorting
      Cell: ({ row }) => renderStatus(row.original), // Custom cell render for the status
    },
    {
      Header: "Actions",
      accessor: "actions", // The "actions" column does not map to a specific data field
      width: 200, // Max width of the column
      dataType: "string", // Data type for consistency
      sortType: "basic", // Enable sorting
      Cell: ({ row }) => renderActions(row.original), // Custom cell render for the actions
    },
  ];

  const [tableReset, setTablReset] = useState(false);
  const fetchCreateTblData = async () => {
    setTablReset(true);
    setTimeout(() => {
      setTablReset(false);
    }, 1000);
  };

  return (
    <>
      <div
        className="mt-4"
        style={{
          position: "relative",
          width: "100%",
          paddingInline: "0px",
          marginBottom: "4%",
        }}
      >
        <Box className="text-left pt-3 pb-1">
          <h4 className="heading">Assign Role to Position</h4>
        </Box>

        {/* Form to assign Role to Position */}
        <div
          className="my-4"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5%",
            marginBottom: "50px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "70%",
              gap: "5%",
            }}
          >
            <div>
              <Form.Group className={"col"} style={{ width: 300 }}>
                <Form.Label>Select Position*</Form.Label>
                <PaginateAutoComplete
                  dropDownName={"role_dropdown"}
                  apiEndpoint="permission/fetch-position"
                  idKey={"position_id"}
                  valueKey={"position_name"}
                  parentKey={"role"}
                  onSelect={(e, value) => {
                    setSelectedPosition(value?.position_id);
                    setInvalid({ ...invalid, position: false });
                    setSelectedPositionObj(value);
                  }}
                  shouldFetchOnOpen={false}
                  isCache={true}
                  className={"react-select-container"}
                  selectDisabled={false}
                  closeMenuSelect={true}
                  multiple={false}
                  showCheckBox={false}
                  showCountsOnly={1}
                  defaultValue={{
                    position_id: selectedPositionObj?.position_id,
                    position_name: selectedPositionObj?.position_name,
                  }}
                  getResults={(res) => {
                    const result = res?.data?.data?.role || [];
                    const totalCountsVar = res?.data?.data?.totalCount || 0;
                    return [result, totalCountsVar];
                  }}
                />
              </Form.Group>
              {invalid.position && (
                <p style={{ color: "#DC3545" }}>Please select position</p>
              )}
            </div>

            <div>
              <Form.Group className={"col"} style={{ width: 300 }}>
                <Form.Label>Select Role*</Form.Label>
                <PaginateAutoComplete
                  dropDownName={"role_dropdown"}
                  apiEndpoint="permission/get-role"
                  idKey={"role_id"}
                  valueKey={"role_name"}
                  parentKey={"role"}
                  onSelect={(e, value) => {
                    setSelectedRole(value?.role_id);
                    setSelectedRoleObj(value);
                    setInvalid({ ...invalid, role: false });
                  }}
                  shouldFetchOnOpen={false}
                  isCache={true}
                  className={"react-select-container"}
                  selectDisabled={false}
                  closeMenuSelect={true}
                  multiple={false}
                  showCheckBox={false}
                  showCountsOnly={1}
                  defaultValue={{
                    role_id: selectedRoleobj?.role_id,
                    role_name: selectedRoleobj?.role_name,
                  }}
                  getResults={(res) => {
                    const result = res?.data?.data?.role || [];
                    const totalCountsVar = res?.data?.data?.totalCount || 0;
                    return [result, totalCountsVar];
                  }}
                />
              </Form.Group>
              {invalid.role && (
                <p style={{ color: "#DC3545" }}>Please select role</p>
              )}
            </div>

            <button
              className="btn btn-success text-uppercase ms-2"
              type="submit"
              style={{ marginTop: 25, height: 40 }}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>

        <Box className="py-4">
          <NewGlobalTable
            method="GET"
            isSearchable={true}
            isCache={true}
            pageSize={50}
            apiEndPoint={"permission/fetch-assign-permission-position"}
            keyAPiName="mappedContactsUpdated"
            headerColumns={headerColumns}
            isTblUpdated={tableReset}
          />
        </Box>

        <UpdateRoleToPositionModal
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
          positionId={positionId}
          active={active}
          fetchAssignPermission={fetchAssignPermission}
          fetchCreateTblData={fetchCreateTblData}
        />

        {edit && (
          <EditRoleToPositionModal
            show={edit}
            setShow={setEdit}
            rowData={rowData}
            fillRolesDD={fillRolesDD}
            fillPositionsDD={fillPositionsDD}
            fetchAssignPermission={fetchAssignPermission}
            fetchCreateTblData={fetchCreateTblData}
            role_list={roles}
          />
        )}
      </div>
    </>
  );
};

export default RoleToPosition;
