import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../css/forwardModal.scss";
import { Form } from "react-bootstrap";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { CallPOSTAPI } from "../../../common/services";

export default function ForwardModal({
  forwardMailModal,
  setForwardMailModal,
  setLoading,
  fetchCreateTblData,
}) {
  const [forwardEmail, setForwardEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const { emailId } = useParams();
  // close modal
  const handleClose = () => setForwardMailModal(false);

  // handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() == false) {
      setValidated(true);
      return;
    }

    // prepare payload data
    let payloadData = {
      id: emailId,
      email: forwardEmail,
    };

    // call result forward
    const result = await CallPOSTAPI("email/forward-email", payloadData);

    // check for status
    if (result?.status) {
      Swal.fire({
        text: result?.data?.msg,
        icon: "success",
      });
      setForwardMailModal(false);
      setLoading(true);
      fetchCreateTblData();
    } else if (!result?.status) {
      Swal.fire({
        text: "Error on forward email",
        icon: "error",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        show={forwardMailModal}
        onHide={handleClose}
        dialogClassName="modal-240w"
        aria-labelledby=""
        size="lg"
        id="forward-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Forward Email</Modal.Title>
        </Modal.Header>

        {/* body */}
        <Modal.Body>
          <div className="modal-container" id="forward-modal-content">
            <div className="my-modal-section">
              {/* main form */}
              <Form
                className=""
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
                id="create-new-account-form"
              >
                <div className="container-fluid">
                  <Form.Group className={"col"}>
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={(e) => {
                        setForwardEmail(e.target.value);
                      }}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      This email field is required
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* bottom buttons */}
                  <div className="row pb-3 py-5">
                    <div className="col-12 content-flex-right">
                      <button
                        className="btn btn-danger text-uppercase"
                        type="button"
                        onClick={() => handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-success text-uppercase ms-2"
                        variant="success"
                        type="submit"
                        // disabled={loading}
                      >
                        {/* {loading ? "Loading..." : "Submit"} */}
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
