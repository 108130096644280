import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { TextField, CircularProgress, Autocomplete, Popper, Paper } from '@mui/material';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { BASE_API } from '../../services';
import _ from 'lodash';
import { printConsole } from '../../helper/Common';

const cache = new Map();

const SmartGlobalDropdown = ({
  apiEndpoint,
  dropDownName = "select",
  parentKey = "",
  idKey = 'id',
  valueKey = 'value',
  isSearchable = false,
  initialSelectedValue = null,
  options = null,
  isMulti = false,
  placeholder = '--Select One--',
  customRender = null,
  groupBy = null,
  isAsync = false,
  pageSize = 20,
  onSelect,
  disabled = false,
  isCache = false,
  tokenKey = 'ross_token',
  isRequired = false,
  minSelection = 0,
  maxSelection = Infinity,
  customValidation = null,
  i18nPrefix = 'dropdown',
  shouldFetchOnOpen = false,
  className = "",
  defaultValue = {},
  useApiSearch = true, // New prop to determine search type
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [validationError, setValidationError] = useState(null);

  const fetchData = useCallback(async (search = '', page = 1, append = false) => {
    if (options) {
      setData(options);
      return;
    }

    const cacheKey = `${apiEndpoint}-${parentKey}-${page}-${search}`;
    if (isCache && cache.has(cacheKey)) {
      const cachedData = cache.get(cacheKey);
      setData((prevData) => (append ? [...prevData, ...cachedData] : cachedData));
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
        throw new Error(t(`${i18nPrefix}.noToken`));
      }
      if (!parentKey) {
        throw new Error(t(`Please Add Parent Key`));
      }

      const response = await axios.get(`${BASE_API}${apiEndpoint}`, {
        params: { search, page, pageSize },
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const resultData = response?.data?.data?.[parentKey].data;
      const totalCountRes = response?.data?.data?.[parentKey].totalCount;

      if (resultData) {
        setData((prevData) => (append ? [...prevData, ...resultData] : resultData));
        if (!append) {
          setTotalCount(totalCountRes || 0);
        }
        if (isCache) {
          cache.set(cacheKey, resultData);
        }
        setHasMore(resultData.length >= pageSize);
      } else {
        setHasMore(false);
      }
      setError(null);
    } catch (err) {
      setError(err.message || t(`${i18nPrefix}.fetchError`));
    } finally {
      setLoading(false);
    }
  }, [apiEndpoint, options, pageSize, isCache, tokenKey, t, i18nPrefix, parentKey]);

  useEffect(() => {
    if (data.length === 0 && shouldFetchOnOpen) {
      fetchData(searchTerm, page);
    }
  }, [data.length, fetchData, shouldFetchOnOpen, searchTerm, page]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      if(term){ 
        setSearchTerm(term);
        if (useApiSearch) {
          setPage(1);
          setData([]); // Clear existing data when searching
          fetchData(term, 1);
        }
      }
    }, 300),
    [fetchData, useApiSearch]
  );

  const handleChange = (event, value) => {
    printConsole({ event, value }); // print console.log
    setSelectedValue(value);
    onSelect(event, value);
    validateSelection(value);
  };

  const handleSearch = (event) => {
    printConsole({ event }); // print console.log
    debouncedSearch(event.target.value);
  };

  const validateSelection = (value) => {
    if (isRequired && (!value || (Array.isArray(value) && value.length === 0))) {
      setValidationError(t(`${i18nPrefix}.required`));
    } else if (isMulti && value.length < minSelection) {
      setValidationError(t(`${i18nPrefix}.minSelection`, { count: minSelection }));
    } else if (isMulti && value.length > maxSelection) {
      setValidationError(t(`${i18nPrefix}.maxSelection`, { count: maxSelection }));
    } else if (customValidation) {
      const customError = customValidation(value);
      setValidationError(customError);
    } else {
      setValidationError(null);
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollHeight - scrollTop <= clientHeight + 1 && hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
      fetchData(searchTerm, page + 1, true); // Fetch next page and append data
    }
  };

  const handleDropdownOpen = () => {
    if (data.length === 0) {
      fetchData(searchTerm, 1);
    }
  };

  // Filter function for local search
  const filteredOptions = useMemo(() => {
    if (!useApiSearch && searchTerm) {
      return data.filter(item => 
        item[valueKey].toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return data;
  }, [data, searchTerm, useApiSearch, valueKey]);

  return (
    <Autocomplete
      multiple={isMulti}
      value={selectedValue}
      options={filteredOptions.map((t) => ({ id: t[idKey], value: t[valueKey] }))}
      getOptionLabel={(option) => option.value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      onChange={handleChange}
      onInputChange={handleSearch}
      disabled={disabled}
      onOpen={handleDropdownOpen}
      PopperComponent={(props) => (
        <Popper {...props} placement="bottom-start">
          <Paper
            {...props.PopperProps}
            onScroll={handleScroll}
            style={{ maxHeight: 300, overflow: 'auto' }}
          >
            {props.children}
            {loading && (
              <div style={{ padding: '10px', textAlign: 'center' }}>
                <CircularProgress size={20} />
              </div>
            )}
          </Paper>
        </Popper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          error={!!validationError}
          helperText={validationError}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id} className="text-black">
          {customRender ? customRender(option) : option.value}
        </li>
      )}
    />
  );
};

export default SmartGlobalDropdown;