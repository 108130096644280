import React, { useState } from "react";
import CommonDatePicker from "./CommonDatePicker";

const RangeDatePicker = ({ formData, setFormData }) => {
  const handleStartDateChange = (name, value) => {
    // Ensure end date is cleared or remains valid if it exists
    if (!formData?.endDate || new Date(value) <= new Date(formData?.endDate)) {
      setFormData((prevData) => ({
        ...prevData,
        startDate: value,
        // Clear end date if new start date is after previous end date
        endDate:
          formData?.endDate && new Date(value) > new Date(formData?.endDate)
            ? null
            : formData?.endDate,
      }));
    }
  };

  const handleEndDateChange = (name, value) => {
    // Only allow end date selection if start date is set
    if (formData?.startDate) {
      if (new Date(value) >= new Date(formData?.startDate)) {
        setFormData((prevData) => ({
          ...prevData,
          endDate: value,
        }));
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label style={{ fontWeight: "bold" }}>From: </label>

        <CommonDatePicker
          calName="start_date"
          CalVal={formData?.startDate || ""}
          HandleChange={handleStartDateChange}
          maxDate={formData?.endDate ? new Date(formData?.endDate) : null}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <label style={{ fontWeight: "bold" }}>To: </label>

        <CommonDatePicker
          calName="end_date"
          CalVal={formData?.endDate || ""}
          HandleChange={handleEndDateChange}
          disabled={!formData?.startDate}
          minDate={formData?.startDate ? new Date(formData?.startDate) : null}
          required
        />
      </div>
    </div>
  );
};

export default RangeDatePicker;
