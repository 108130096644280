// import DashBarGraph from "../components/BarChart/BarGraph";
// import LineGraph from "../components/LineGraph/LineGraph";
// import DashBoardHalfChart from "../components/PieCharts/HalfChart";
// import DashBoardHalfPieChart from "../components/PieCharts/HalfPieChart";
// import DashboardCards from "./dashboardCard";
// import Graphs from "./Graphs";
import { Button as FixedButton } from "@mui/material";
// import Filter from "../components/filter";
import { useState } from "react";

const NewDahsboard = () => {
  return (
    <>
      {/* <DashboardCards /> */}
      {/* <Graphs /> */}
    </>
  );
};

export default NewDahsboard;
