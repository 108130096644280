import React from "react";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import New from "../../../../../common/img/New.png";

export const AddMainRowBtn = ({
  sectionName,
  formData,
  setFormData,
  setShowAddRow,
  formDataKey,
}) => {
  const handleAddMain = async () => {
    const battery = formData[formDataKey];

    const addBatteryRow = [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_uid: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
        is_spare: 0,
        section_name: sectionName,
      },
    ];

    const addChargePakRow = [
      {
        battery_expiration: "",
        battery_lot: "",
        battery_part_number: "",
        battery_serial: "",
        battery_type_id: "",
        battery_udi: "",
        bid: "",
        charge_pak_pad_1_id: "",
        charge_pak_pad_2_id: "",
        charge_pak_uid: "",
        install_9v_date: null,
        install_before_date: null,
        install_date: null,
        is_spare: 0,
        manufactured_date: null,
        pad_1_expiration: "",
        pad_1_lot: "",
        pad_1_part_number: "",
        pad_1_type_id: "",
        pad_2_expiration: "",
        pad_2_lot: "",
        pad_2_part_number: "",
        pad_2_type_id: "",
        section_name: "charge_pack",
      },
    ];

    const addPadRow = [
      {
        is_pediatric: "",
        is_spare: 0,
        pad_expiration: "",
        pad_lot: "",
        pad_part_number: "",
        pad_type_id: "",
        pad_udi: "",
        section_name: sectionName,
      },
    ];

    if (battery?.length < 1) {
      setFormData((prev) => ({
        ...prev,
        [formDataKey]:
          formDataKey === "AdultPadInformation" ||
          formDataKey === "AdultPadPakInfo" ||
          formDataKey === "PediatricPadInformation" ||
          formDataKey === "PediatricPakPadInformation"
            ? addPadRow
            : formDataKey === "ChargePakInformation"
            ? addChargePakRow
            : addBatteryRow,
      }));

      setShowAddRow((prev) => ({ ...prev, [sectionName]: 0 }));
    }
  };

  return (
    <>
      {formData[formDataKey]?.length < 1 ? (
        <>
          <button
            name="addMainRowBtn"
            className="btn text-primary"
            type="button"
            onClick={() => handleAddMain()}
          >
            <img
              src={New}
              alt="svg"
              style={{ marginRight: "1px", width: "20px", height: "20px" }}
            />
            <span className="ms-1">Add Main</span>
          </button>
        </>
      ) : (
        ""
      )}
    </>
  );
};
