import React from "react";
const currentYear = new Date().getFullYear();
export default function Footer() {
  return (
    <>
      <div className="container-fluid  py-2 bg-black">
        <footer className="main-footer my-container mx-auto">
          <div className="row Footer">
            <div className="col-3 Footer-logo-div">
              <img
                className={""}
                src="/assets/images/logo-colored.svg"
                alt="footer-log"
              />
            </div>
            <div className="col-6 text-center">
              <b className="text-white CopyrightDiv">
                Copyright &copy; {currentYear} Rescue One Training for Life Inc.
                All Rights Reserved
              </b>
            </div>
            <div className="col-3 text-right footer-links PrivacyPolicyDiv">
              <ul
                className="text-white list-wrapper nav narbar gap-2 float-right"
                style={{ float: "right" }}
              >
                <li className="text-white">
                  {/* <a href="/privacy-policy"> */}
                  Privacy Policy
                  {/* </a> */}
                </li>
                <li className="text-white"> | </li>
                <li className="text-white">
                  {/* <a href="terms-conditions"> */}
                  Terms and Conditions
                  {/* </a> */}
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
