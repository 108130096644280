import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "../css/outOfServiceModal.scss";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
// import { CallGETAPI, CallPOSTAPI } from "../../../helper/API";
import { toast } from "react-toastify";
import { CallGETAPI, CallPOSTAPI } from "../../../../../common/services";
import { AccountSiteList, GetAccountList } from "../../../../../common/helper/BasicFn";
import GlobalDropdown from "../../../../../common/components/custom-ui/GlobalDropdown";
import PaginateAutoComplete from "../../../../../common/components/custom-ui/PaginateAutoComplete";
// import { GetAccountList } from "../../../helper/BasicFn";

const OutOfServiceSecondModal = ({
  ShowSecondModal,
  setShowSecondModal,
  accountId,
  outofServiceFormData,
  setoutOfServiceLoding,
}) => {
  // const [formData, setFormData] = React.useState({
  //   date_sent_to_manufacturer: "",
  //   loaner_rental_serial: "",
  //   loaner_rental_serial_name: "",
  //   reason: "",
  //   replaced_serial: "",
  //   replaced_serial_name: "",
  //   not_replacing: false,
  // });
  const handleSecondModelClose = () => setShowSecondModal(false);
  const [validated, setValidated] = React.useState(false);
  const [accountList, setAccountList] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState(accountId);
  const [AedSiteList, setAedSiteList] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [formData, setFormData] = useState({
    account_name: "",
  })

  const handleSecondSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      const sendData = {
        account_id: selectedAcc,
        site_id: selectedSite,
        id:
          outofServiceFormData?.replaced_serial ||
          outofServiceFormData?.loaner_rental_serial,
      };
      const res = await CallPOSTAPI("aed/move-replece-aed", sendData);
      // return;
      if (res.data.status) {
        toast.success(res.data.msg);
        handleClose();
        handleSecondModelClose();
        setoutOfServiceLoding(true);
      } else {
        toast.error("Something went Wrong Please Try Again");
      }
    }
  };

  const onChangeAccount = async (id, onload = false) => {
    // const accSiteListres = await CallGETAPI("account/account-site-list/" + id);
    const accSiteListres = await AccountSiteList(id);
    const accSiteList = accSiteListres?.site_details || [];
    setSelectedAcc(id);
    setAedSiteList(accSiteList);
    if (!onload) {
      setSelectedSite("");
    }
  };

  const handleClose = () => {
    setShowSecondModal(false);
  };

  return (
    <>
      <Modal
        show={ShowSecondModal}
        onHide={handleSecondModelClose}
        // dialogClassName="modal-half"
        aria-labelledby="example-custom-modal-styling-title"
        size="md"
        id="outofservice-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center gap-3">
            <span>Moved Aed</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-container" id="outofservice-modal-content">
            <div className="my-modal-section">
              <Form
                class=""
                onSubmit={handleSecondSubmit}
                noValidate
                validated={validated}
                id="outofservice-form"
              >
                <div
                  className="bottom-border-blue py-4 px-2"
                  style={{
                    background: "#eee",
                  }}
                >
                  <div className="col-md-8">
                    <div className="form-group mt-3">
                      <label>Account Name*</label>
                      <span className="drop-icom">▼</span>
                      {/* <select
                        className="form-control"
                        onChange={(e) => onChangeAccount(e.target.value)}
                        // defaultValue={selectedAcc}
                        required
                      >
                        <option value={""} key={0}>
                          --- Select One ---
                        </option>
                        {accountList.map((item, index) => (
                          <option
                            value={item?.account_id}
                            key={index}
                          // selected={parseInt(selectedAcc) === item?.account_id}
                          >
                            {item?.account_name}
                          </option>
                        ))}
                      </select> */}

                      {/* <GlobalDropdown
                        dropDownName={"account_name"}
                        apiEndpoint={"account/account-list-dropdown"}
                        idKey={"account_id"}
                        valueKey={"account_name"}
                        parentKey={"allAccount"}
                        // onSelect={(e) => {
                        //   updatePhoneFields(e, "phone_type_id", index);
                        // }}
                        shouldFetchOnOpen={true}
                        isCache={true}
                      /> */}

<PaginateAutoComplete
                    dropDownName={"account"}
                    apiEndpoint={"account/account-list-dropdown"}
                    idKey={"account_id"}
                    valueKey={"account_name"}
                    parentKey={"allAccount"}
                    onSelect={(e, val) => {
                      const value = e.target.value;
                      // setSelectedAccount({
                      //   accountId: val?.account_id,
                      //   accountName: val?.account_name,
                      // });
                      onChangeAccount(val?.account_id);
                    // setAccountIdSelected(value);
                    }}
                    shouldFetchOnOpen={false}
                    isCache={false}
                    className={"react-select-container"}
                    selectDisabled={false}
                    closeMenuSelect={false}
                    isSearchable={true}
                    isAsync={true}
                    multiple={false}
                    // defaultValue={{
                    //   account_id: selectedAccount?.accountId,
                    //   account_name: selectedAccount?.accountName,
                    // }}
                    />
                    </div>

                    <div className="form-group mt-3">
                      <label>Site Name*</label>
                      <span className="drop-icom">▼</span>
                      <select
                        className="form-control"
                        onChange={(e) => setSelectedSite(e.target.value)}
                        // defaultValue={selectedSite}
                        required
                      >
                        <option value={""} key={0}>
                          --- Select One ---
                        </option>
                        {/* {
                      parseInt(siteId) === 0 ?
                        <option value={0} selected> Pending </option>
                        :
                        ''
                    } */}
                        {AedSiteList.map((item, index) => (
                          <>
                            <option
                              value={item?.account_site_info_id}
                              key={index}
                              selected={
                                parseInt(selectedSite) ===
                                item?.account_site_info_id
                              }
                            >
                              {item?.account_site_name}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {/* <div className="" style={{ marginBottom: "15px" }}>
                  <div
                    className="col-md-12 d-flex"
                    style={{ marginTop: "25px", justifyContent: "right" }}
                  >
                    <Button
                      className={"btn btn-danger mx-4"}
                      variant="danger"
                      style={{ fontSize: "16px" }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className={"btn btn-success"}
                      variant="success"
                      style={{ marginRight: "5px", fontSize: "16px" }}
                      type="submit"
                    // disabled={switchValue.value === true ? false : true}
                    >
                      Submit
                    </Button>
                  </div>
                </div> */}

                <div className="row pb-2 py-2">
                  <div className="col-12 content-flex-right">
                    <button
                      className="btn btn-danger text-uppercase"
                      type="button"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-success text-uppercase ms-2"
                      type="submit"
                      // onClick={(e) => handleSubmit(e)}
                      // disabled={loading}
                    >
                      {/* {loading ? "Loading..." : "Submit"} */}
                      submit
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OutOfServiceSecondModal;
