import React, { useEffect, useState } from "react";
// import "./table.css";
import { useParams } from "react-router-dom";
import New from "../../../../common/img/New.png";
import DataGrid, {
  Scrolling,
  Paging,
  Column,
} from "devextreme-react/data-grid";
import { Link, useNavigate } from "react-router-dom";
import { CallGETAPI } from "../../../../common/services/index";
import moment from "moment";
import { DateFormate } from "../../../../common/helper/TblFn";
import {
  DataSymbol,
  DollarsIcon,
  RoundCheck,
  TruckSymbol,
} from "../../../../common/helper/icons";
import { resetAllPops } from "../../../../store/slices/EquipmentSlice";
import { useDispatch } from "react-redux";
import TableSkeleton from "../skeleton/table/TableSkeleton";
import { GetProfile, getPermission } from "../../../../common/helper/Common";
import { DecryptToken } from "../../../../common/helper/BasicFn";
import PopList from "./PopList";

export default function Pops({
  // accountId,
  site_id = 0,
  contact_id = 0,
  type = "ACCOUNT",
}) {
  const {
    accountId,
    // pop_id, userAccountId
  } = useParams();
  // const accountId = userAccountId;

  let redirectUrl = `/account/new-pop/` + accountId;
  const [loading, setLoading] = useState(true);
  const [popList, setPopList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = DecryptToken();
  const privilege = getPermission();
  // const {pop_id} = useParams();
  // console.log(pop_id)

  const fetchData = async () => {
    // account/pop-list-by-account/1

    if (!accountId) {
      return "";
    }
    setLoading(true);
    const res = await CallGETAPI("pop/pop-list-by-account/" + accountId);

    if (res.data.status) {
      const resultData = res.data.data;
      setPopList(resultData);
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   fetchData();
  // }, []);
  // console.log(accountId);

  // const fetchData = async () => {
  //   try {
  // const [popData,setPopData]  = useState([]);
  //   const [loading, setLoading] = useState(true);

  // let sendUrl = 'notes/account-pop/'+accountId;

  // let response = await CallGETAPI(sendUrl);
  // let resultData = response?.data?.data || [];

  // setPopData(resultData)
  // setLoading(false);
  // } catch (error) {
  // console.error('Error fetching data: ', error);
  // setLoading(false);
  // }

  // useEffect(() => {
  //   fetchData();
  // },[])
  const renderCoverage = (e) => {
    const coverage = e.value;
    const rowData = e.data;
    if (coverage === "Invalid Date") {
      return "";
    }
    // const check =  (rowData?.contract_year) ? moment(coverage).add(parseInt(rowData?.contract_year),'years').format(DateFormate) : "";
    const check = moment(rowData?.contract_start)
      .add(1, "year")
      .format(DateFormate);
    const check2 = moment(rowData?.contract_start).format(DateFormate);
    // if(check==='Invalid date'){
    //   return '';
    // }

    return (
      <div>
        {check2} {check ? " - " + check : ""}
      </div>
    );
  };

  const renderContact = (e) => {
    if (!e.value) {
      return "";
    }
    const valData = JSON.parse(e.value);
    if (!Array.isArray(valData?.contract_officer)) {
      return "";
    }

    return (
      <>
        <ul className="list-unstyled">
          {valData?.contract_officer?.map((it, i) => (
            <li key={i}>
              <Link
                className="link"
                style={{ textDecoration: "none" }}
                to={`/account/${accountId}/contact-details/${it?.contact_id}`}
              >
                {it?.contact_name}
              </Link>
            </li>
          ))}
        </ul>
      </>
    );
  };
  const renderStatus = (e) => {
    if (parseInt(e.value) === 1) {
      return "Active";
    }

    if (parseInt(e.value) === 0) {
      return "Inactive";
    }

    if (parseInt(e.value) === 2) {
      return "Cancelled";
    }
    // return e.value===1?'Active':'Inactive';
  };

  const renderQbInvoice = (e) => {
    const data = e.data;
    // invoice_paid
    return (
      <>
        <span className="text-primary">
          {data?.invoice_paid ? <DollarsIcon /> : ""} &nbsp;
        </span>
        {e.value}
      </>
    );
  };
  const renderContract = (e) => {
    const data = e.data;
    const checkIcon = JSON.parse(data?.contract_pricing_equipment) || 0;
    return (
      <>
        {checkIcon ? <DataSymbol /> : ""}
        {data?.shipping === "Charges" ? <TruckSymbol /> : ""} {e.value}
        {/* {data?.contract_pricing_equipment ? <TruckSymbol/>:''} {e.value} */}
        {data?.req && <p className="m-0">Req: {data.req}</p>}
        {data?.order && <p className="m-0 ">Order: {data?.order}</p>}
        {data?.modification && (
          <p className="m-0 ">Mod: {data?.modification}</p>
        )}
      </>
    );
  };

  let profile = GetProfile(); //JSON.parse(localStorage.getItem("ross-profile"));
  let is_user = false;

  if (profile?.user_type > 1 && profile?.user_type != 2) {
    is_user = true;
  }

  const prefix = is_user ? "/user" : "/account";

  const handleClick = (e, accountId) => {
    if (is_user === true) {
      navigate(`/user/pop-details/${e.data?.pop_id}/${accountId}`);
    } else {
      navigate(`/account/pop-details/${e.data?.pop_id}/${accountId}`);
    }
  };

  return (
    <>
      <PopList accountId={accountId} />
    </>
  );
}
