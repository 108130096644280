import React, { useCallback, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CallGETAPI, CallPOSTAPI } from "../../../../common/services";
import SubHeadingOther from "../../../../common/components/SubHeadingOther";
import EditAssignAedTbl from "../components/EditAssignAedTbl";

const EditAssignAed = () => {
  const navigate = useNavigate();
  const { siteId } = useParams();
  const { state } = useLocation();
  const { accountId } = state || {};
  const [equipment_data, set_equipment_data] = useState(null);
  const [contact_list, set_contact_list] = useState([]);
  const [allAedId, setAllAedId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const aedIdNew = searchParams.get("aed_id");
  const [equipment, setEquipment] = useState([]);
  const [assignedData, setAssignedData] = useState();
  const location = useLocation();
  const assignedAedData = location?.state?.assignAedData;

  const redirectPath = location?.state ? location?.state?.redirectPath : "";
  const accountName = location?.state ? location?.state?.accountName : "";
  const Title = (assignedAedData?.[0]?.account_name || accountName) ?? "";

  const filterDataBySiteId = () => {
    let data = assignedAedData?.find((item) => {
      return Number(item?.site_id) === Number(siteId);
    });

    return data?.data;
  };

  useEffect(() => {
    if (siteId) {
      setAssignedData(filterDataBySiteId());
    }
  }, [siteId]);

  // const getContactsData = async () => {
  //   setLoading(true);
  //   const response = await CallGETAPI("account/account-contacts-list/", accountId);

  //   if (response?.status) {
  //     set_contact_list(response.data.data.contact_list);
  //     setLoading(false);
  //   }
  //   setLoading(false);
  // };

  const getContactsData = useCallback(async () => {
    setLoading(true);
    const response = await CallGETAPI(
      "account/account-contacts-list/",
      accountId
    );

    if (response?.status) {
      set_contact_list(response.data.data.contact_list);
      setLoading(false);
    }
    setLoading(false);
  }, [accountId]);

  useEffect(() => {
    if (accountId) {
      // getAccountData();
      getContactsData();
    }
  }, [accountId, getContactsData]);

  return (
    <>
      <div
        className="mt-4 mb-5"
        style={{ width: "100%", paddingInline: "0px" }}
      >
        <SubHeadingOther
          title={Boolean(Title) ? `Account: ${Title}` : ""}
          hideNew={true}
          hideHierarchy={true}
          hideInstructor={true}
          subHeading={true}
          bottomLinks={false}
        />

        <div className="mb-5">
          <div
            className="row"
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "30%",
              justifyContent: "space-between",
              marginBottom: "2%",
            }}
          >
            <h1
              style={{
                color: "#000",
                textAlign: "center",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                width: "520px",
                margin: "0px",
              }}
            >
              {equipment_data?.site_name}
            </h1>
          </div>

          <EditAssignAedTbl
            redirectPath={redirectPath}
            accountId={accountId}
            showLoading={loading}
            data={assignedData}
            fromAed={location?.state?.fromAed}
          />
        </div>
      </div>
    </>
  );
};

export default EditAssignAed;
