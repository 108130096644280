import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accounts: [],
  status: 'idle',
  error: null,
};

const accountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    addaccount: (state, action) => {
      state.accounts.push(action.payload);
    },
    setaccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { addaccount, setaccounts, setStatus, setError } = accountSlice.actions;

export default accountSlice.reducer;
