import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import "./tab.scss";
import { isPermission } from "../../helper/PermissionManager";

const CustomTabBar = ({
  tabs,
  onTabChange,
  from,
  activeTab: propActiveTab,
  navProps = {},
  navItemProps = {},
  navLinkProps = {},
  // type="site"
}) => {
  const activeTab =
    propActiveTab !== undefined ? propActiveTab : internalActiveTab;
  const [internalActiveTab, setInternalActiveTab] = useState(activeTab || "Details");



  const handleSelect = (selectedKey) => {
    if (propActiveTab === undefined) {
      setInternalActiveTab(selectedKey);
    }
    onTabChange(selectedKey);
  };

  return (
    <Nav
      variant="tabs"
      className="bg-primary custom-tab"
      // className="text-light tab-button"
      activeKey={activeTab}
      onSelect={handleSelect}
      {...navProps}
    >

      {tabs.map((tab) => {
        if (!tab) return null; // Ensure tab is defined

        if (Number(isPermission({ type: from, name: tab.id, })) === 1) {
          return (
            <Nav.Item
              key={tab.id} // Ensure tab.id is unique
              {...navItemProps}
              {...tab.itemProps}
              className="tab-item"
            >
              <Nav.Link
                eventKey={tab.id}
                {...navLinkProps}
                {...tab.linkProps}
                className="tab-item-a"
              >
                {tab.title}
              </Nav.Link>
            </Nav.Item>
          );
        }

        return null; // Explicitly return null if no permission
      })}
    </Nav>
  );
};

export default CustomTabBar;
