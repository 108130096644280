import React from "react";
import PadInfoTableSkeleton from "../../AedSkeletons/PadInfoTableSkeleton";
import CustomToggleButton2 from "../../../../../../common/components/toggleSwitch/CustomToggleButton2";

const PadsInfoDetailsTbl = ({
  aedPadTypeList,
  RenderDate,
  formData,
  isLoading,
  pads_info_toggle,
}) => {
  const print_aed_pad_type = (bid) => {
    if (bid === "unknown") return "unknown";
    let findName = aedPadTypeList.find(
      (item) => parseInt(item?.pad_type_id) === parseInt(bid)
    );
    return findName?.pad_part_number || bid;
  };

  if (isLoading) {
    return <PadInfoTableSkeleton />;
  }

  return (
    <>
      <table className="theme-table">
        <tr>
          <td>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                {!pads_info_toggle
                  ? "Please Correct Below Information"
                  : "Is below information correct?"}
              </div>
              <div
                style={{
                  display: "flex",
                  // marginLeft: "74.1%"
                }}
              >
                <CustomToggleButton2
                  ToggleName="pads_info_toggle"
                  ToggleValue={pads_info_toggle}
                  changeHandler={() => {}}
                  is_read_only={1}
                />
              </div>
            </div>
          </td>
        </tr>
      </table>

      <table className="w-100">
        <thead>
          <tr className="">
            <th
              scope="col"
              className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
            >
              Pad Type
            </th>
            <th
              scope="col"
              className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
            >
              Part #{" "}
            </th>
            <th
              scope="col"
              className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
            >
              Expiration Date
            </th>
            <th
              scope="col"
              className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
            >
              Pad Lot{" "}
            </th>
            <th
              scope="col"
              className="border border-2 py-1 px-2 bg-tbl-border border-t-blue"
            >
              Pad UDI
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(formData) &&
            formData.map((PadItem, index) => (
              <tr key={index}>
                <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                  <RenderType
                    section_name={PadItem?.section_name}
                    is_spare={PadItem?.is_spare}
                  />
                </td>
                <td className={"px-2"}>
                  {print_aed_pad_type(PadItem?.pad_part_number)}
                </td>
                <td className={"px-2"}>
                  {" "}
                  {PadItem?.pad_expiration === "unknown"
                    ? "unknown"
                    : RenderDate(PadItem?.pad_expiration, true)}{" "}
                </td>
                <td className={"px-2"}> {PadItem?.pad_lot} </td>
                <td className={"px-2"}>
                  {" "}
                  {PadItem?.pad_udi ? PadItem?.pad_udi : "N/A"}{" "}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

const RenderType = ({ section_name, is_spare }) => {
  if (is_spare) {
    return (
      <>
        {section_name === "spare_adult_pad_info"
          ? "Spare Adult"
          : "Spare Pad-Pak"}
      </>
    );
  }
  return <>{section_name === "adult_pad_info" ? "Main Adult" : "Pad-Pak"}</>;
};

export default PadsInfoDetailsTbl;
