import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  Form,
  Button as BButton,
  Button as BsButton,
  InputGroup,
  Button,
} from "react-bootstrap";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";
import CustomToggleButton2 from "../../../common/components/toggleSwitch/CustomToggle2";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services";
import { toast } from "react-toastify";
import { sortArrAscending } from "../../../common/helper/constants";
function CreateRoleForm({ fetchCreateTblData }) {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [rowData, setRowData] = useState("");
  const [formKey, setFormKey] = useState(0);
  const [roleData, setRoleData] = useState([]);
  const [sortedNewRoleDataArr, setSortedNewRoleDataArr] = useState([]);
  const [permissionsValidations, setPermisionsValidation] = useState(false);

  const [isInvalid, setIsInvalid] = useState({
    role_name: false,
    permissions: false,
    role_type: false,
  });

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const [formData, setFormData] = useState({
    role_name: "",
    permissions: [],
    is_admin_role: false,
    role_type: "",
  });

  const handleToggleChange = () => {
    if (formData.is_admin_role) {
      setFormData({ ...formData, is_admin_role: 0 });
    } else {
      setFormData({ ...formData, is_admin_role: 1 });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};

    if (formData.role_name.trim() === "") {
      errors.role_name = true;
    }
    if (formData.permissions.length === 0) {
      errors.permissions = true;
    }

    if (formData.role_type.trim() === "") {
      errors.role_type = true;
    }

    if (Object.keys(errors).length > 0) {
      setIsInvalid({ ...isInvalid, ...errors });
      return;
    }

    setLoading(true);
    let permissionArr = formData?.permissions;
    let finalPermissionsArr = permissionArr.map(
      (permission) => permission.value
    );
    let body = { ...formData, permissions: finalPermissionsArr + "" };

    // const res = await CallPOSTAPI("admin/create-permission-group", body);
    const res = await CallPOSTAPI("permission/create-role", body);
    if (res?.status) {
      setLoading(false);
      toast.success("Role Created Successfully");
      fetchCreateTblData();
      setFormData({ role_name: "", permissions: [] });
      setFormKey((prevKey) => prevKey + 1);
      setValidated(false);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const res = await CallGETAPI("/permission/get-permission");
  //     setRoleData(res?.data?.data || []);

  //     const roleDataV = res.data.data;

  //     const nrda = roleDataV?.permissions?.map((obj) => ({
  //       label: obj.permissions,
  //       value: obj.pr_id,
  //     }));
  //     let sortedArr = sortArrAscending(nrda, "label");
  //     setSortedNewRoleDataArr(sortedArr);
  //     // setNewRoleDataArr(nrda);
  //   } catch (error) {
  //     console.error("Error fetching role data:", error);
  //   }
  // };

  const handlePermissionsChange = (e) => {
    setFormData({
      ...formData,
      permissions: e.target.formatted,
    });
    setValidated(false);
    setPermisionsValidation(false);
  };

  useEffect(() => {
    // fetchData();
  }, []);

  return (
    <>
      <Box className="text-left pt-3 pb-1">
        <h4 className="heading">Create Role</h4>
      </Box>

      <Form
        key={formKey}
        className=""
        onSubmit={handleSubmit}
        noValidate
        validated={validated}
        id="create-new-equipment-form"
      >
        <div
          className="my-4"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "fit-content",
              gap: "5%",
            }}
          >
            <Form.Group className={"col"} style={{ width: 400 }}>
              <Form.Label>Enter Role Name*</Form.Label>
              <Form.Control
                placeholder="Enter Role Name"
                // style={{ borderColor: isInvalid?.role_name ? '#DC3545' : '' }}
                type="text"
                name="role_name"
                value={formData?.role_name}
                onChange={(e) => {
                  handleChange(e, "role_name");
                  setIsInvalid({ ...isInvalid, role_name: false });
                }}
                required
                style={{ height: "58px" }}
                // isInvalid={validated && formData.role_name === ""}
              />
              {isInvalid?.role_name && (
                <Form.Control.Feedback type="" className="text-danger mt-1">
                  Role name is required.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className={"col"} style={{ width: 300 }}>
              <Form.Label>Select Permissions*</Form.Label>

              {/* <MultiSelect
                  valueRenderer={renderSelectTitle}
                  options={sortedNewRoleDataArr}
                  value={formData?.permissions}
                  hasSelectAll={false}
                  onChange={(e) => {
                    handlePermissionsChange(e);
                    setIsInvalid({ ...isInvalid, permissions: false });
                  }}
                  labelledBy="Select"
                /> */}
              <PaginateAutoComplete
                dropDownName={"permissions"}
                apiEndpoint="permission/get-permission"
                idKey={"pr_id"}
                valueKey={"permissions"}
                parentKey={"permissions"}
                onSelect={(e, value) => {
                  handlePermissionsChange(e);
                  setIsInvalid({ ...isInvalid, permissions: false });
                }}
                shouldFetchOnOpen={false}
                isCache={true}
                className={"react-select-container"}
                selectDisabled={false}
                closeMenuSelect={false}
                multiple={true}
                showCheckBox={true}
                showCountsOnly={1}
                defaultValue={
                  formData?.permissions?.length > 0
                    ? formData.permissions?.map((it) => ({
                        pr_id: it.value,
                        permissions: it.label,
                      }))
                    : null
                }
                getResults={(res) => {
                  const result = res?.data?.data?.permissions || [];
                  const totalCountsVar = res?.data?.data?.totalCount || [];
                  return [result, totalCountsVar];
                }}
              />
              {isInvalid?.permissions && (
                <Form.Control.Feedback type="" className="text-danger mt-1">
                  Permissions are required.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className={"col"} style={{ width: 200 }}>
              <Form.Label>Select Role Type*</Form.Label>
              <select
                className="form-control"
                value={formData?.role_type}
                name="role_type"
                placeholder="Enter Role Type"
                style={{ height: "58px" }}
                onChange={(e) => {
                  handleChange(e, "role_type");
                  setIsInvalid({ ...isInvalid, role_type: false });
                }}
              >
                <option value="">--Select One--</option>
                <option value="account_level">Account Level</option>
                <option value="site_level">Site Level</option>
                <option value="enhancement">Enhancement</option>
              </select>
              {isInvalid?.role_type && (
                <Form.Control.Feedback type="" className="text-danger mt-1">
                  Role type is required.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Form.Label>Admin Role</Form.Label>
              <CustomToggleButton2
                ToggleName="admin_role_status"
                ToggleValue={formData?.is_admin_role}
                changeHandler={handleToggleChange}
                is_read_only={false}
              />
            </div>

            <button
              className="btn btn-success text-uppercase ms-2"
              type="submit"
              style={{ marginTop: 25, height: 40 }}
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default CreateRoleForm;
