import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";
import './CloseModal.scss'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    textAlign: 'center',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

function AlertExample({ show, handleClose, msg, handleOk, loading }) {
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const [validated, setValidated] = useState(false);
    const [closingReason, setClosingReason] = useState('');



    const handleConfirm = (e) => {
        e.preventDefault();  // Prevent default form submission
        if (!closingReason.trim()) {
            setValidated(true);
            return;
        }
        handleOk(closingReason);
        setTimeout(() => {

            setValidated(false);
        }, 2000); // Simulating a delay of 2 seconds (adjust as needed)

    };


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Close Support</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form
                        className=""
                        onSubmit={handleConfirm}
                        noValidate
                        validated={validated}
                        id="create-new-respond-support-form"
                    >
                        <div className="container-fluid">

                            <Form.Group className="col" controlId="closingReason">
                                <Form.Label>Reason for Closing*</Form.Label>
                                <textarea
                                    id="closingReason"
                                    value={closingReason}
                                    rows={6}
                                    required
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        fontSize: '14px',
                                        borderColor: validated && !closingReason ? 'red' : '#ced4da',
                                        borderRadius: '4px',
                                        resize: 'none',
                                    }}
                                    placeholder="Enter reason for closing"
                                    onChange={(e) => setClosingReason(e.target.value)}
                                />
                                {validated && !closingReason && (
                                    <Form.Control.Feedback type="invalid" style={{ display: 'block', color: 'red', textAlign: 'left' }}>
                                        The information field is required
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            {/* bottom buttons */}
                            <div className="col-md-12 d-flex" style={{ marginTop: "25px", justifyContent: "right" }}>
                                <button
                                    className="btn btn-danger mx-3"
                                    variant="danger"
                                    style={{ fontSize: "14px" }}
                                    onClick={handleClose}
                                    type="button"
                                >
                                    Cancel
                                </button>

                                <button
                                    className="btn btn-success"
                                    variant="success"
                                    style={{ fontSize: "14px" }}
                                    type="submit"
                                    disabled={loading}  // Disable button while loading
                                >
                                    {loading ? 'Loading...' : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </Form>

                </Modal.Body>

            </Modal>
        </>
    );
}

export default AlertExample;