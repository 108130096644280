// src/hooks/usePermission.js
import { useSelector } from 'react-redux';
import { DecryptToken, getPermission } from '../helper';
import { printConsole } from '../helper/Common';

export const usePermission = (requiredPermission) => {
  const permissions = useSelector((state) => state.permissions.data);
  return !!permissions[requiredPermission];
}; 


export const useHasPermission = (requiredPermission) => {
  return true;
  // const { permissions } = usePermissions();
  // return permissions.includes(requiredPermission);
};

export const isSubAdminPermission = (subAdminPermission)=>{
  const user = DecryptToken();
  const privilege = getPermission();
  const permissionArray = privilege.split(",");
  let hasPermission = 0;
  for (let i = 0; i < permissionArray.length; i++) {
      const permission = permissionArray[i];
      if(Number(user?.user_type === 0) || (Number(user?.user_type === 2) && permission === subAdminPermission  && user?.sub_admin != "")){
          hasPermission = 1;
      }
  }
  return hasPermission;
}

export const isUserPermission = (UserPermission)=>{
  const user = DecryptToken();
  const privilege = getPermission();
  const permissionArray = privilege?.split(",");
  let hasPermission = 0;
  for (let i = 0; i < permissionArray?.length; i++) {
      const permission = permissionArray[i];
      if(permission ===UserPermission && Number(user?.user_type ) === 3){
          hasPermission = 1;
      }
  }
  return hasPermission;
}

export const isPermission = (UserPermission)=>{
  const user = DecryptToken();
  const privilege = getPermission();
  const permissionArray = privilege?.split(",");
  let hasPermission = 0;
  if(Number(user?.user_type ) === 0){
      return 1;
  }
  for (let i = 0; i < permissionArray?.length; i++) {
      const permission = permissionArray[i];
      if(permission ===UserPermission && Number(user?.user_type ) === 3){
          hasPermission = 1;
      }
  }
  return hasPermission;   
}

export const linkTabsPermission = (userPermission) => {
  const user = DecryptToken();
  const privilege = getPermission();
  const permissionArray = privilege?.split(",");

  if (Number(user?.user_type) !== 3 && ((Number(user?.user_type) !== 2) && user?.sub_admin == "") && (Number(user?.user_type) !== 0)) {
      return 0;
  }
    //   console.log({permissionArray})
  let hasPermission = 0;
  if(permissionArray?.length < 1){ 
  for (let i = 0; i < userPermission?.length; i++) {
      for (let j = 0; j < permissionArray?.length; j++) {
          printConsole(userPermission[i]) // print console.log
          // console.log(permissionArray[j])
          if (userPermission[i] === permissionArray[j]) {
              hasPermission = 1;
              break;
          }
      }
      if (hasPermission === 1) {
          break;
      }
  } 
  } else {
      hasPermission = 1;
  }

  return hasPermission;
}