import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { BASE_API } from '../../services';
import _ from 'lodash';

const cache = new Map();

const AEDBrandModalMultiDropdown = ({
  apiEndpoint,
  dropDownName = "select",
  parentKey = "",
  idKey = 'model_id',
  valueKey = 'model_name',
  isSearchable = false,
  initialSelectedValue = null,
  options = null,
  isMulti = false,
  placeholder = 'Select...',
  onSelect,
  disabled = false,
  isCache = false,
  tokenKey = 'ross_token',
  isRequired = false,
  minSelection = 0,
  maxSelection = Infinity,
  customValidation = null,
  i18nPrefix = 'dropdown',
  shouldFetchOnOpen = true,
  className = "",
  defaultValue = {},
  prepareOptions = null,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedValues, setSelectedValues] = useState(initialSelectedValue || []);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (shouldFetchOnOpen) {
      fetchData();
    }
  }, []);

  const fetchData = useCallback(async () => {
    // ... (keep the fetchData implementation as before)
  }, [apiEndpoint, options, isCache, tokenKey, t, i18nPrefix, parentKey, prepareOptions, idKey, valueKey]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    let newSelectedValues;
    if (isMulti) {
      newSelectedValues = selectedValues.some(v => v[idKey] === option[idKey])
        ? selectedValues.filter(v => v[idKey] !== option[idKey])
        : [...selectedValues, option];
    } else {
      newSelectedValues = [option];
      setIsOpen(false);
    }
    setSelectedValues(newSelectedValues);
    onSelect(newSelectedValues);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredData = data.map(group => ({
    ...group,
    options: group.options.filter(option => 
      option[valueKey].toLowerCase().includes(searchTerm)
    )
  })).filter(group => group.options.length > 0);

  return (
    <div ref={dropdownRef} className={`custom-dropdown ${className}`}>
      <div 
        className={`custom-dropdown__header ${isOpen ? 'open' : ''} ${disabled ? 'disabled' : ''}`} 
        onClick={!disabled ? handleToggle : undefined}
      >
        {selectedValues.length > 0 
          ? selectedValues.map(v => v[valueKey]).join(', ') 
          : <span className="custom-dropdown__placeholder">{placeholder}</span>}
      </div>
      {isOpen && !disabled && (
        <div className="custom-dropdown__menu">
          {isSearchable && (
            <input 
              type="text" 
              placeholder="Search..." 
              onChange={handleSearch} 
              className="custom-dropdown__search"
            />
          )}
          {loading ? (
            <div className="custom-dropdown__loading">Loading...</div>
          ) : (
            filteredData.map((group, groupIndex) => (
              <div key={groupIndex} className="custom-dropdown__group">
                <div className="custom-dropdown__group-label">{group.label}</div>
                {group.options.map((option, optionIndex) => (
                  <div 
                    key={optionIndex} 
                    className={`custom-dropdown__item ${selectedValues.some(v => v[idKey] === option[idKey]) ? 'selected' : ''}`}
                    onClick={() => handleSelect(option)}
                  >
                    {option[valueKey]}
                  </div>
                ))}
              </div>
            ))
          )}
        </div>
      )}
      {error && <div className="custom-dropdown__error">{error}</div>}
    </div>
  );
};

export default AEDBrandModalMultiDropdown;