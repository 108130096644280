// ----- New Contact-Tab Listing ---- //
import React, { useState, useEffect } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { Box } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { DecryptToken, getPermission } from "../../../../common/helper";
import { Skeleton } from "@mui/material";
import {
  ContactStatus,
  formatPhoneNumber,
} from "../../../../common/helper/Common.js";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import New from "../../../../common/img/New.png";
import { CallGETAPI } from "../../../../common/services/index.js";
import NewGlobalTable from "../../../../common/components/custom-ui/NewGlobalTable.jsx";
import { truncateText } from "../../../../common/helper/BasicFn.js";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice.js";
import { isPermission } from "../../../../common/helper/PermissionManager.jsx";

const ContactListing = ({ setTabTbldata, is_user, accountId, siteId }) => {
  const navigate = useNavigate();
  const filterData = useSelector((state) => state.accountlistfilter.filterData);
  const [showLoading, setShowLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [contactList, setSiteContactList] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const subAdminPermission = [
    "site-details",
    "contact-tab",
    "equipment-tab",
    "training-tab",
    "inperson-tab",
    "notes-tab",
    "documents-tab",
  ];

  // fetch on load
  // const fetchOnlaod = async () => {
  //   setShowLoading(true);
  //   if (is_user) {
  //     let contactData = await CallGETAPI(`account/site-contacts-list/${siteId}?page=${currentPage}`);
  //     setSiteContactList(contactData?.data?.data?.contact_list || []);
  //   } else {
  //     let ContactData = await CallGETAPI(
  //       `site/site-contacts-list/${siteId}?page=${currentPage}`
  //     );
  //     if (ContactData) {
  //       setSiteContactList(ContactData?.data?.data || []);
  //     }
  //   }
  //   setShowLoading(false);
  // };

  // on load
  // useEffect(() => {
  //   fetchOnlaod();
  // }, [currentPage]);

  // useEffect(() => {
  //   if (contactList?.length > 0) {
  //     setTabTbldata((prev) => ({
  //       ...prev,
  //       contact: true,
  //     }));
  //   }
  // }, [contactList]);

  const BreadCrumbsObject = {
    title: "",
    // type: "site",
    // tab: "CONTACT_LISTING",
  };
  const handleNewDispatch = (accountId) => {
    BreadCrumbsObject.title = "New Contact Form";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/contacts/new/${accountId}`,
      })
    );
  };
  const handleDispatched = (accountId, contact_id, name) => {
    BreadCrumbsObject.title = name + " Details";
    // BreadCrumbsObject.tab = "Details";
    // BreadCrumbsObject.type = "contact";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/${accountId}/contact-details/${contact_id}/Details`,
      })
    );
  };
  const RenderContactName = (data) => {
    return (
      <>
        <span
          className={
            Number(isPermission({ type: "link", name: "contactName" })) === 1
              ? "link"
              : ""
          }
          onClick={() => {
            if (
              Number(isPermission({ type: "link", name: "contactName" })) === 1
            ) {
              handleDispatched(accountId, data.contact_id, data?.contact_name);
              navigate(
                `/account/${accountId}/contact-details/${data.contact_id}/Details`,
                {
                  state: {
                    // siteTitle: "Contact : " + data?.contact_name,
                    editUrl: `/account/contact-details-edit/${data.contact_id}`,
                    deleteUrl: `/account/contact-details-edit/${data.contact_id}`,
                    type: "Contacts",
                  },
                }
              );
            }
          }}
        >
          {truncateText(data?.contact_name, 20)}
        </span>
      </>
    );
  };

  const RenderUser = (data) => {
    return (
      <>
        {data?.user ? (
          <CheckIcon color={"success"} />
        ) : (
          <CloseIcon color="error" />
        )}
      </>
    );
  };

  const RenderPhone = (data) => {
    return (
      <>
        {data?.phone?.[0].phone != "" && data?.phone?.[0].phone != "-" && (
          <a
            className="link"
            style={{ textDecoration: "none" }}
            href={"tel:" + data?.phone?.[0].ext + data?.phone?.[0]?.phone}
          >
            {data?.phone?.[0].phone
              ? formatPhoneNumber(data?.phone?.[0].phone)
              : ""}
            {data?.phone?.[0].ext != "" ? "x" + data?.phone?.[0].ext : ""}
          </a>
        )}
      </>
    );
  };

  const RenderEmail = (data) => {
    return (
      <>
        {data?.email.length > 0 && (
          <a
            className="link"
            style={{ textDecoration: "none" }}
            href={"mailto:" + data?.email?.[0].email}
          >
            {data?.email?.[0].email}
          </a>
        )}
      </>
    );
  };

  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };

  const headerColumns = [
    {
      Header: "Contact",
      accessor: "contact_name",
      Cell: ({ row }) => <div> {RenderContactName(row.original)}</div>,
      width: "15%",
    },
    {
      Header: "User",
      accessor: "user",
      width: "5%",
      Cell: ({ row }) => (
        <div>{RenderUser(row.original)}</div>
      ),
      disableSortBy: true,
    },
    {
      Header: "Phone",
      accessor: "phone",
      width: "15%",
      Cell: ({ row }) => (
        <div>{RenderPhone(row?.original)}</div>
      ),
      disableSortBy: true,
    },
    {
      Header: "Email",
      accessor: "email",
      width: "20%",
      Cell: ({ row }) => (
        <div>{RenderEmail(row.original)}</div>
      ),
    },
    {
      Header: "Positions",
      accessor: "account_roles",
      width: "8%",
      Cell: ({ row }) => (
        <div>{row.original?.account_roles.join(", ")} </div>
      ),
    },
    {
      Header: "Sites",
      accessor: "main_site",
      width: "15%"
    },
    {
      Header: "Status",
      accessor: "status",
      width: "8%",
      Cell: ({ row }) => (
        <p className={row.original?.status == 1 ? "" : "text-danger"}>
          {ContactStatus[row.original?.status]}
        </p>
      ),
    },
  ];

  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: "Contact",
  //       accessor: "contact_name",
  //       Cell: ({row}) => (
  //         <div> {RenderContactName(row.original)}</div>
  //       ),
  //       width: "20%",
  //     },
  //     {
  //       Header: "User",
  //       accessor: "user",
  //       Cell: ({ row }) => (
  //         <>
  //           {showLoading ? (
  //             <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
  //           ) : (
  //             <div>{RenderUser(row.original)}</div>
  //           )}
  //         </>
  //       ),
  //       width: "10%",
  //       disableSortBy: true,
  //     },
  //     {
  //       Header: "Phone",
  //       accessor: "phone",
  //       Cell: ({ row }) => (
  //         <>
  //           {showLoading ? (
  //             <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
  //           ) : (
  //             <div>{RenderPhone(row?.original)}</div>
  //           )}
  //         </>
  //       ),
  //       width: "10%",
  //       disableSortBy: true,
  //     },
  //     {
  //       Header: "Email",
  //       accessor: "email",
  //       Cell: ({ row }) => (
  //         <>
  //           {showLoading ? (
  //             <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
  //           ) : (
  //             <div>{RenderEmail(row.original)}</div>
  //           )}
  //         </>
  //       ),
  //       width: "15%",
  //     },
  //     {
  //       Header: "Positions",
  //       accessor: "account_roles",
  //       Cell: ({ row }) => (
  //         <>
  //           {showLoading ? (
  //             <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
  //           ) : (
  //             <div>{row.original?.account_roles.join(", ")} </div>
  //           )}
  //         </>
  //       ),
  //       width: "15%",
  //     },
  //     {
  //       Header: "Sites",
  //       accessor: "main_site",
  //       // Cell: ({ row }) => (
  //       //   <>
  //       //     {showLoading ? (
  //       //       <Skeleton width={'90%'} height={20} style={{ margin: "10px" }} />
  //       //     ) : (
  //       //       row.original.account_site_email
  //       //     )}
  //       //   </>
  //       // ),
  //       width: "15%",
  //     },
  //     {
  //       Header: "Status",
  //       accessor: "status",
  //       Cell: ({ row }) => (
  //         <>
  //           {showLoading ? (
  //             <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
  //           ) : (
  //             <>
  //               <p className={row.original?.status == 1 ? "" : "text-danger"}>
  //                 {ContactStatus[row.original?.status]}
  //               </p>
  //             </>
  //           )}
  //         </>
  //       ),
  //       width: "15%",
  //     },
  //   ],
  //   [showLoading]
  // );

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   prepareRow,
  //   page,
  //   canPreviousPage,
  //   canNextPage,
  //   pageOptions,
  //   pageCount,
  //   gotoPage,
  //   nextPage,
  //   previousPage,
  //   setPageSize,
  //   setGlobalFilter,
  //   state: { pageIndex, pageSize, globalFilter },
  // } = useTable(
  //   {
  //     columns,
  //     data: contactList,
  //     initialState: { pageIndex: 0, pageSize: 20 },
  //   },
  //   useGlobalFilter,
  //   useSortBy,
  //   usePagination
  // );

  return (
    // <div className="bg-light base-table">
    //     <Box className="d-flex justify-content-between align-items-center py-2">
    //        <h3 className="heading">Site Contacts</h3>
    //          <div></div>
    //          {(user?.user_type == 0 ||
    //           (user?.user_type == 2 &&
    //             user?.sub_admin != "" &&
    //             privilege?.includes("contacts-new"))) && (
    //           <Link
    //             style={{ textDecoration: "none" }}
    //             to={"/account/contacts/new/" + accountId}
    //           >
    //             <img src={New} />
    //             <span style={{ color: "#0C71C3" }}> New</span>
    //           </Link>
    //         )}
    //       </Box>

    //   <div className="table-responsive">
    //     <table
    //       {...getTableProps()}
    //       className="table table-bordered table-hover"
    //     >
    //       <thead>
    //         {headerGroups.map((headerGroup) => (
    //           <tr {...headerGroup.getHeaderGroupProps()}>
    //             {headerGroup.headers.map((column) => (
    //               <td
    //                 {...column.getHeaderProps(column.getSortByToggleProps())}
    //                 style={{ width: column.width, backgroundColor: "#999" }}
    //                 className="tb-td"
    //               >
    //                 <div className="d-flex align-items-center th-d">
    //                   {column.render("Header")}
    //                   <span className="ml-1">
    //                     {!column.disableSortBy &&
    //                       (column.isSorted ? (
    //                         column.isSortedDesc ? (
    //                           <SouthIcon size={2} />
    //                         ) : (
    //                           <NorthIcon size={2} />
    //                         )
    //                       ) : (
    //                         <SouthIcon size={2} />
    //                       ))}
    //                   </span>
    //                 </div>
    //               </td>
    //             ))}
    //           </tr>
    //         ))}
    //       </thead>

    //       <tbody {...getTableBodyProps()}>
    //         {showLoading || !page
    //           ? [...Array(5)].map((_, i) => (
    //               <tr key={i}>
    //                 {columns.map((col, j) => (
    //                   <td
    //                     key={j}
    //                     style={{
    //                       backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
    //                     }}
    //                     className="tb-td"
    //                   >
    //                     <Skeleton
    //                       width={"90%"}
    //                       height={20}
    //                       style={{ margin: "10px" }}
    //                     />
    //                   </td>
    //                 ))}
    //               </tr>
    //             ))

    //             : (!showLoading && page.length < 1) ? (
    //               <tr>
    //                 <td
    //                   colSpan={columns.length}
    //                   style={{
    //                     width: "100%",
    //                     textAlign: "center",
    //                     fontStyle: "italic",
    //                     color: "grey",
    //                   }}
    //                 >
    //                   No Data Found.
    //                 </td>
    //               </tr>
    //             ) : (
    //               page.map((row, i) => {
    //                 prepareRow(row);
    //                 return (
    //                   <tr {...row.getRowProps()} key={i}>
    //                     {row.cells.map((cell, j) => (
    //                       <td
    //                         {...cell.getCellProps()}
    //                         key={j}
    //                         style={{
    //                           backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
    //                         }}
    //                         className="tb-td"
    //                       >
    //                         {showLoading ? (
    //                           <Skeleton
    //                             width={"90%"}
    //                             height={20}
    //                             style={{ margin: "10px" }}
    //                           />
    //                         ) : (
    //                           cell.render("Cell")
    //                         )}
    //                       </td>
    //                     ))}
    //                   </tr>
    //                 );
    //               })
    //             )}
    //       </tbody>
    //     </table>
    //   </div>

    //   {contactList?.length > 0 && (
    //     <>
    //       <div className="mt-4 d-flex justify-content-end align-items-center">
    //         <div className="d-flex align-items-center ml-auto">
    //           {Array.from({ length: totalPage }, (_, i) => i + 1).map(
    //             (page) => (
    //               <button
    //                 key={page}
    //                 onClick={() => handlePageChange(page)}
    //                 className={`btn btn-light mx-1 ${
    //                   currentPage === page ? "active" : ""
    //                 }`}
    //               >
    //                 {page}
    //               </button>
    //             )
    //           )}
    //         </div>
    //       </div>
    //       {/* <hr style={{ border: "2px solid #0d6efd" }} /> */}
    //     </>
    //   )}

    // </div>

    // Global Table Implementations.
    <>
      <Box className="d-flex justify-content-between align-items-center py-2">
        <h3 className="heading">Site Contacts</h3>
        {Number(isPermission({ type: "btn", name: "newContact" })) === 1 ? (
          <>
            <Link
              style={{ textDecoration: "none" }}
              // onClick={handleDispatch(accountId)}
              onClick={() => {
                handleNewDispatch(accountId);
              }}
              to={"/account/contacts/new/" + accountId}
            >
              <img src={New} />
              <span style={{ color: "#0C71C3" }}> New</span>
            </Link>
          </>
        ) : (
          ""
        )}
      </Box>
      <NewGlobalTable
        method="GET"
        isCache={true}
        // keyAPiName="contact_list"
        apiEndPoint={`site/site-contacts-list/${siteId}`}
        headerColumns={headerColumns}
      />
    </>
  );
};

export default ContactListing;
