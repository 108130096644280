import React from 'react';
import CustomToggleButton2 from '../../../../common/components/toggleSwitch/CustomToggleButton2';
import { Form } from 'react-bootstrap';

const PresentReadyInfo = ({ 
  AedItem, 
  serviceQuestionData, 
  handleToggleChange, 
  handleChange, 
  handleReplacementSerial, 
  handleInputChange, 
  aedList, 
  siteId, 
  fillReplacementSerialDD,
  index 
}) => {
  return (
    <>
      <table className="theme-table">
        <thead>
          <tr>
            <td className="border border-r-blue" style={{ width: "25%" }}>Replacing</td>
            <td className="border border-r-blue" style={{ width: "25%" }}>Replacement Serial #</td>
            <td className="border border-r-blue" style={{ width: "25%" }}>Loaner</td>
            <td className="border border-r-blue" style={{ width: "25%" }}>Loaner Serial #</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <CustomToggleButton2
                ToggleName="ReplacingAeds"
                ToggleValue={AedItem?.ReplacingAeds}
                changeHandler={(e) => handleToggleChange("ReplacingAeds", e.target.checked, index)}
                is_read_only={serviceQuestionData?.aeds_ready_status === false ? false : ""}
              />
            </td>
            <td>
              <select
                name="ReplacementSerial"
                value={AedItem?.ReplacementAedId ? AedItem?.ReplacementAedId : ""}
                onChange={(e) => handleChange(e, index)}
                style={{ width: "100%", background: "transparent", border: "none" }}
                disabled={AedItem?.ReplacingAeds === 0}
              >
                <option>-Select one-</option>
                {aedList
                  ?.filter((item) => item?.aed_details?.site_id === siteId)
                  .map((item, key) => (
                    <option key={key + 1} value={item?.aed_details?.aed_id}>
                      {item?.aed_details?.serial_number}
                    </option>
                  ))}
              </select>
            </td>
            <td>
              <CustomToggleButton2
                ToggleName="loaner_toggle"
                ToggleValue={AedItem?.loaner_toggle}
                changeHandler={(e) => handleToggleChange("loaner_toggle", e.target.checked, index)}
                is_read_only={serviceQuestionData?.aeds_ready_status === false ? false : ""}
              />
            </td>
            <td>
              <select
                name="loanerSerial"
                value={AedItem?.loanerSerial ? AedItem?.loanerSerial : ""}
                onChange={(e) => handleReplacementSerial(e, index)}
                style={{ width: "100%", background: "transparent", border: "none" }}
                disabled={AedItem?.loaner_toggle === 0}
              >
                <option>-Select one-</option>
                {fillReplacementSerialDD()}
              </select>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="theme-table" style={{ marginBottom: "3%" }}>
        <thead>
          <tr>
            <td className="border border-r-blue">Comment</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Group controlId="validationCustom03">
                <Form.Control
                  type="text"
                  required
                  name="servicing_notes"
                  placeholder="Enter text here."
                  value={AedItem?.servicing_notes}
                  style={{ height: "auto", width: "100%", border: "none" }}
                  onChange={(e) => handleInputChange(e, index)}
                />
                <Form.Control.Feedback type="invalid">
                  This Field is Required
                </Form.Control.Feedback>
              </Form.Group>
            </td>
          </tr>
        </tbody>
      </table>
      {AedItem?.isPresentError ? (
        <p style={{ color: "red" }}>This is a compulsory field</p>
      ) : ("")}
    </>
  );
};

export default PresentReadyInfo;