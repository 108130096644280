// cache.js
class DropdownCache {
    constructor(maxSize = 100, defaultExpiration = 5 * 60 * 1000) { // 5 minutes default
      this.cache = new Map();
      this.maxSize = maxSize;
      this.defaultExpiration = defaultExpiration;
    }
  
    cleanExpiredItems() {
      const now = Date.now();
      for (const [key, item] of this.cache.entries()) {
        if (now - item.timestamp > this.defaultExpiration) {
          this.cache.delete(key);
        }
      }
    }
  
    getKey(endpoint, parentKey, page, search) {
      return `${endpoint}-${parentKey}-${page}-${search}`;
    }
  
    get(key) {
      const item = this.cache.get(key);
      if (!item) return null;
  
      if (Date.now() - item.timestamp > this.defaultExpiration) {
        this.cache.delete(key);
        return null;
      }
      return item;
    }
  
    set(key, data, totalCount) {
      this.cleanExpiredItems();
  
      if (this.cache.size >= this.maxSize) {
        // Remove oldest entry
        const oldestKey = this.cache.keys().next().value;
        this.cache.delete(oldestKey);
      }
  
      this.cache.set(key, {
        data,
        totalCount,
        timestamp: Date.now()
      });
    }
  
    has(key) {
      const item = this.get(key);
      return item !== null;
    }
  
    clear() {
      this.cache.clear();
    }
  
    clearForEndpoint(endpoint) {
      const keysToDelete = Array.from(this.cache.keys())
        .filter(key => key.startsWith(endpoint));
      keysToDelete.forEach(key => this.cache.delete(key));
    }
  }
  
  export const dropdownCache = new DropdownCache();
  
//   // api.js
//   import axios from 'axios';
//   import { BASE_API } from '../../services';
  
//   export const fetchDropdownData = async ({
//     apiEndpoint,
//     search = '',
//     page = 1,
//     pageSize = 20,
//     tokenKey = 'ross_token',
//     signal
//   }) => {
//     const token =    .getItem(tokenKey);
    
//     try {
//       const response = await axios.get(`${BASE_API}${apiEndpoint}`, {
//         params: { search, page, pageSize },
//         headers: { 'Authorization': `Bearer ${token}` },
//         signal
//       });
//       return response;
//     } catch (error) {
//       if (axios.isCancel(error)) {
//         console.log('Request cancelled');
//       } else {
//         console.error('Error fetching dropdown data:', error);
//       }
//       throw error;
//     }
//   };