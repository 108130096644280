import { Skeleton } from "@mui/material";

const BatteryInfoTableSkeleton = ({ BatteryExpTbl }) => (
    <>
      <table className="theme-table">
        <tbody>
          <tr>
            <td>
              <Skeleton className="h-4 w-[200px]" />
            </td>
            <td>
              <Skeleton className="h-8 w-[60px]" />
            </td>
          </tr>
        </tbody>
      </table>
  
      <table className="theme-table">
        <thead>
          <tr className="">
            <th
              scope="col"
              className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
            >
              Main #
            </th>
            {BatteryExpTbl?.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
              >
                {item?.title} #
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(3)].map((_, rowIndex) => (
            <tr key={rowIndex}>
              {[...Array(BatteryExpTbl.length + 1)].map((_, colIndex) => (
                <td key={colIndex}>
                  <Skeleton className="h-8 w-full" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  export default BatteryInfoTableSkeleton;