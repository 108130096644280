import React, { useEffect, useState } from "react"; // Import useEffect and useState
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { fetchBarGraph } from "../Services";

function DashBarGraph({ sections }) {
  const [data, setData] = useState([]); // State to hold the fetched data
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State to manage error messages

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchBarGraph(sections);
        setData(response); // Set the response data directly to data state
      } catch (error) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []); // Added sections to the dependency array

  return (
    <div
      className="col"
      style={{
        marginTop: "10px",
        padding: "10px",
        backgroundColor: "transparent",
        marginBottom: "20px",
        border: "2px solid black",
        alignItems: "center",
      }}
    >
      <h2>AED Tech Assignments</h2>
      <div className="row">
        <div className="container mt-5">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={data} // Use the fetched data
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="contact_name" />{" "}
              {/* Use contact_name as the X-axis label */}
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="site_count" fill="#8884d8" name="Site Count" />{" "}
              {/* Use site_count for bar data */}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default DashBarGraph;
