import { Skeleton } from "@mui/material";

const AEDDetailsSkeleton = () => (
    <table className="table table-striped-columns">
      <tbody>
        <tr>
          <td><Skeleton className="h-4 w-[200px]" /></td>
          <td><Skeleton className="h-8 w-[60px]" /></td>
        </tr>
        <tr>
          <td colSpan={2}><Skeleton className="h-20 w-full" /></td>
        </tr>
        <tr>
          <td><Skeleton className="h-4 w-[200px]" /></td>
          <td><Skeleton className="h-8 w-[60px]" /></td>
        </tr>
        <tr>
          <td colSpan={2}><Skeleton className="h-20 w-full" /></td>
        </tr>
        <tr>
          <td><Skeleton className="h-4 w-[200px]" /></td>
          <td><Skeleton className="h-8 w-[60px]" /></td>
        </tr>
        <tr>
          <td colSpan={2}><Skeleton className="h-20 w-full" /></td>
        </tr>
      </tbody>
    </table>
  );

  export {AEDDetailsSkeleton}