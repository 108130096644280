import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
// import { CallGETAPI } from "../../../../common/services";
// import { addNewBreadcrumbs, removeBreadcrumb } from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
// import FinalNewBreadcrumbs from "../../../../common/components/breadcrumbsComp/FinalNewBreadcrumbs";
import { CallGETAPI } from "../../../../../common/services";
import FinalNewBreadcrumbs from "../../../../../common/components/breadcrumbsComp/FinalNewBreadcrumbs";
import {
  addNewBreadcrumbs,
  removeBreadcrumb,
} from "../../../../../store/slices/breadcrumbsSlice";
import ScrollTopButton from "../../../../../common/components/ScrollTopButton";
import { printConsole } from "../../../../../common/helper/Common";

const AedCheckCardListing = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedaccountRows, setselectedaccountRows] = useState([]);
  const [selectedaccountBackupRows, setselectedaccountBackupRows] = useState(
    []
  );
  const [selectedBackupRows, setSelectedBackupRows] = useState([]);
  const [serviceCheckTblData, setServiceCheckTblData] = useState([]);
  const { accountId, siteId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const siteName = location.state ? location.state.siteName : null;
  const [newTblData, setNewTblData] = useState([]);
  const [backupTbl, setBackupTbl] = useState([]);
  const primary = location?.state?.Primary;
  const backup = location?.state?.Backup;
  const PrimaryAedId = location?.state?.PrimaryAedId || [];
  const BackupAedId = location?.state?.BackupAedId || [];
  const fetchData = async () => {
    try {
      const res = await CallGETAPI(`user/assigned-aed-list`);
      const ld = res?.data?.data;
      const { primaryAed, backupAed } = ld;
      printConsole({ resultcheck: primaryAed, backupAed }); // print console.log
      setNewTblData(primaryAed);
      setBackupTbl(backupAed);
      setServiceCheckTblData(ld);

      // Pre-select checkboxes based on aedId array
      const primarySelected = [];
      const primaryAccountSelected = [];
      const backupSelected = [];
      const backupAccountSelected = [];

      // Check primary AEDs
      primaryAed.forEach((aed) => {
        if (PrimaryAedId.includes(aed.aed_id)) {
          primarySelected.push(aed.aed_id);
          primaryAccountSelected.push(aed.account_id);
        }
      });

      // Check backup AEDs
      backupAed.forEach((aed) => {
        if (BackupAedId.includes(aed.aed_id)) {
          backupSelected.push(aed.aed_id);
          backupAccountSelected.push(aed.account_id);
        }
      });

      setSelectedRows(primarySelected);
      setselectedaccountRows(primaryAccountSelected);
      setSelectedBackupRows(backupSelected);
      setselectedaccountBackupRows(backupAccountSelected);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [accountId, siteId]);

  const toggleRowSelection = (id, accId) => {
    printConsole({ id, accId }); // print console.log
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
    setselectedaccountRows((prevSelected) =>
      prevSelected.includes(accId)
        ? prevSelected.filter((rowId) => rowId !== accId)
        : [...prevSelected, accId]
    );
  };
  const toggleRowBackupSelection = (id, accId) => {
    printConsole({ id, accId }); // print console.log
    setSelectedBackupRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
    setselectedaccountBackupRows((prevSelected) =>
      prevSelected.includes(accId)
        ? prevSelected.filter((rowId) => rowId !== accId)
        : [...prevSelected, accId]
    );
  };

  const togglePrimaryAllRows = (checked) => {
    if (checked) {
      setSelectedRows(newTblData.map((row) => row.aed_id));
      setselectedaccountRows(newTblData.map((row) => row.account_id));
    } else {
      setSelectedRows([]);
      setselectedaccountRows([]);
    }
  };
  const BreadCrumbsObject = {
    title: "",
  };
  const handleAedDispatch = (selectedRowId) => {
    BreadCrumbsObject.title = "Aed Check Form";
    // BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/aed-details/check-select`,
        title: "Aed Check Form",
      })
    );
  };
  const toggleBackupAllRows = (checked) => {
    if (checked) {
      setSelectedBackupRows(backupTbl.map((row) => row.aed_id));
      setselectedaccountBackupRows(backupTbl.map((row) => row.account_id));
    } else {
      setSelectedBackupRows([]);
      setselectedaccountBackupRows([]);
    }
  };

  const handleRemoveDispatch = () => {
    const editPageUrl =
      "/account/aed/service-check/" + accountId + "/" + siteId;
    dispatch(removeBreadcrumb(editPageUrl));
  };
  // const BreadCrumbsObject = {
  //     title: ""
  // }
  // const handleServiceDispatch = (selectedRowId) => {
  //     BreadCrumbsObject.title = "Service Check Form";
  //     // BreadCrumbsObject.account_Name = accountName;
  //     BreadCrumbsObject.accountId = accountId;

  //     dispatch(addNewBreadcrumbs({
  //         ...BreadCrumbsObject, url: `/account/aed/service-check/service1/${accountId}/${siteId}/aedId?aedId=${selectedRowId}`
  //     }))

  // }

  // print console.log
  printConsole({
    selectedBackupRows,
    selectedaccountRows,
    selectedaccountBackupRows,
  });
  const handleSubmit = () => {
    try {
      if (selectedRows.length > 0 || selectedBackupRows.length > 0) {
        const selectedRowId = [...selectedRows, ...selectedBackupRows];
        const selectedaccountId = [
          ...selectedaccountRows,
          ...selectedaccountBackupRows,
        ];
        const currentId = selectedRowId?.shift();
        const currentaccountId = selectedaccountId?.shift();
        handleAedDispatch();
        navigate(
          `/account/aed-details/check-select?aed_id=${currentId}&account_id=${currentaccountId}`,
          {
            state: {
              key: "aedCheck",
              selectedRowId: selectedRowId,
              selectedaccountId: selectedaccountId,
            },
          }
        );
      } else {
        console.error("Please select a row before submitting.");
        toast.error("Please select an AED before submitting.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Please select an AED before submitting.");
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type",
        width: "13%",
        Cell: () => <span>AED</span>,
      },
      {
        Header: "Brand/Model",
        accessor: "aed_brands_model_name",
      },
      {
        Header: "Serial #",
        accessor: "serial_number",
      },
      {
        Header: "Asset#",
        accessor: "asset",
      },
      {
        Header: "Last Check",
        accessor: "last_check",
        Cell: ({ value }) => {
          if (!value) return "-";
          const date = new Date(value);
          if (isNaN(date.getTime())) return "-";
          return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          }).format(date);
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: newTblData,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const tableBackupInstance = useTable(
    {
      columns,
      data: backupTbl,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  // Destructure with unique names for both instances
  const {
    getTableProps: getPrimaryTableProps,
    getTableBodyProps: getPrimaryTableBodyProps,
    headerGroups: primaryHeaderGroups,
    prepareRow: preparePrimaryRow,
    rows: primaryRows,
  } = tableInstance;

  const {
    getTableProps: getBackupTableProps,
    getTableBodyProps: getBackupTableBodyProps,
    headerGroups: backupHeaderGroups,
    prepareRow: prepareBackupRow,
    rows: backupRows,
  } = tableBackupInstance;

  printConsole({ tableInstance }); // print console.log
  return (
    <>
      <FinalNewBreadcrumbs />
      {primary && (
        <div className="mt-4" style={{ width: "100%" }}>
          <h2 className="heading">Primary Assigned Equipment</h2>

          <div className="table-responsive">
            <table
              {...getPrimaryTableProps()}
              className="table table-bordered table-hover"
            >
              <thead>
                {primaryHeaderGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th
                      style={{
                        width: "5%",
                        textAlign: "center",
                        backgroundColor: "rgb(153, 153, 153)",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          selectedRows.length === newTblData.length &&
                          newTblData.length > 0
                        }
                        onChange={(e) => togglePrimaryAllRows(e.target.checked)}
                      />
                    </th>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{ backgroundColor: "#999", width: column.width }}
                        className="tb-td"
                      >
                        <div className="d-flex align-items-center th-d">
                          {column.render("Header")}
                          <span className="ml-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SouthIcon size={2} />
                              ) : (
                                <NorthIcon size={2} />
                              )
                            ) : (
                              <SouthIcon size={2} style={{ opacity: 0.3 }} />
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getPrimaryTableBodyProps()}>
                {loading ? (
                  [...Array(10)].map((_, i) => (
                    <tr key={i}>
                      {[...Array(5)].map((col, j) => (
                        <td
                          key={j}
                          style={{
                            backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                          }}
                          className="tb-td"
                        >
                          <Skeleton animation="wave" height={20} />
                        </td>
                      ))}
                    </tr>
                  ))
                ) : newTblData.length === 0 ? (
                  <tr
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      fontStyle: "italic",
                      color: "grey",
                    }}
                  >
                    <td colSpan={columns.length + 1}>No data found</td>
                  </tr>
                ) : (
                  primaryRows.map((row) => {
                    preparePrimaryRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        <td
                          style={{
                            width: "5%",
                            textAlign: "center",
                            backgroundColor:
                              row.index % 2 === 0 ? "white" : "#e4e4e4",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(row.original.aed_id)}
                            onChange={() =>
                              toggleRowSelection(
                                row.original.aed_id,
                                row.original.account_id
                              )
                            }
                          />
                        </td>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor:
                                row.index % 2 === 0 ? "white" : "#e4e4e4",
                            }}
                            className="tb-td"
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {backup && (
        <div className="mt-4" style={{ width: "100%" }}>
          <h2 className="heading" style={{}}>
            Backup Assigned Equipment
          </h2>

          <div className="table-responsive">
            <table
              {...getBackupTableProps()}
              className="table table-bordered table-hover"
            >
              <thead>
                {backupHeaderGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th
                      style={{
                        width: "5%",
                        textAlign: "center",
                        backgroundColor: "rgb(153, 153, 153)",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          selectedBackupRows.length === backupTbl.length &&
                          backupTbl.length > 0
                        }
                        onChange={(e) => toggleBackupAllRows(e.target.checked)}
                      />
                    </th>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{ backgroundColor: "#999", width: column.width }}
                        className="tb-td"
                      >
                        <div className="d-flex align-items-center th-d">
                          {column.render("Header")}
                          <span className="ml-1">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <SouthIcon size={2} />
                              ) : (
                                <NorthIcon size={2} />
                              )
                            ) : (
                              <SouthIcon size={2} style={{ opacity: 0.3 }} />
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getBackupTableBodyProps()}>
                {loading ? (
                  [...Array(10)].map((_, i) => (
                    <tr key={i}>
                      {[...Array(5)].map((col, j) => (
                        <td
                          key={j}
                          style={{
                            backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                          }}
                          className="tb-td"
                        >
                          <Skeleton animation="wave" height={20} />
                        </td>
                      ))}
                    </tr>
                  ))
                ) : backupTbl.length === 0 ? (
                  <tr
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      fontStyle: "italic",
                      color: "grey",
                    }}
                  >
                    <td colSpan={columns.length + 1}>No data found</td>
                  </tr>
                ) : (
                  backupRows.map((row) => {
                    prepareBackupRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        <td
                          style={{
                            width: "5%",
                            textAlign: "center",
                            backgroundColor:
                              row.index % 2 === 0 ? "white" : "#e4e4e4",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedBackupRows.includes(
                              row.original.aed_id
                            )}
                            onChange={() =>
                              toggleRowBackupSelection(
                                row.original.aed_id,
                                row.original.account_id
                              )
                            }
                          />
                        </td>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              backgroundColor:
                                row.index % 2 === 0 ? "white" : "#e4e4e4",
                            }}
                            className="tb-td"
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="row pb-3 py-4" style={{ marginBottom: "6%" }}>
        <div className="col-12 content-flex-right">
          <button
            className="btn text-uppercase cancel-button"
            type="button"
            onClick={() => {
              handleRemoveDispatch();
              navigate(-1);
            }}
          >
            Cancel
          </button>
          <button
            className="btn text-uppercase ms-4 submit-button"
            type="button"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>

      <ScrollTopButton />
    </>
  );
};

export default AedCheckCardListing;
