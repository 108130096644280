// InventoryDisplay.jsx
import React from "react";
import InventoryListTbl from "../components/common/InventoryListTbl";

const InventoryDisplay = ({
  loading,
  selectSite,
  batteryInventory,
  padInventory,
  secName,
  AedFormData,
  aed_brand_id,
  aed_model_id,
  contact_id,
  selectedBatteryRows,
  setSelectedBatteryRows,
  setSelectedPadRows,
  setSelectedRowIndex,
  selectedPadRows,
  setModalHeight,
}) => {
  return (
    <div className="upper-div">
      {loading && selectSite === "" ? (
        <>{/* <Loading /> */}</>
      ) : (
        <div style={{ position: "relative", maxWidth: "750px" }}>
          {selectSite !== "" && (
            <InventoryListTbl
              tableData={
                batteryInventory?.length > 0
                  ? batteryInventory
                  : padInventory?.length > 0
                  ? padInventory
                  : []
              }
              type={batteryInventory?.length > 0 ? "battery" : "pad"}
              secName={secName}
              selectSite={selectSite}
              AedFormData={AedFormData}
              aed_brand_id={aed_brand_id}
              aed_model_id={aed_model_id}
              contact_id={contact_id}
              batteryInventory={batteryInventory}
              padInventory={padInventory}
              selectedBatteryRows={selectedBatteryRows}
              setSelectedBatteryRows={setSelectedBatteryRows}
              setSelectedPadRows={setSelectedPadRows}
              setSelectedRowIndex={setSelectedRowIndex}
              selectedPadRows={selectedPadRows}
              setModalHeight={setModalHeight}
              showLoading={loading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default InventoryDisplay;