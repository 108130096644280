import React, { useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";

const AedsCard = ({ sections }) => {
  const [aeds, setAeds] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = "aeds";
  const account = findSectionById("accounts", sections);

  const handleDetailsDispatch = (accountId, item) => {
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: "",
    };

    const handleDispatch = () => {
      BreadCrumbsObject.title = "Dashboard";
      dispatch(
        addNewBreadcrumbs({
          ...BreadCrumbsObject,
          url: "/dashboard",
          type: "dashboard",
        })
      );
    };
    handleDispatch();
    if (!account?.account_id) {
      if (
        Number(
          isPermission({ type: "dashboard", name: "EquipmentListing" })
        ) === 1
      ) {
        BreadCrumbsObject.title = "AED Listing";
        dispatch(
          addNewBreadcrumbs({
            ...BreadCrumbsObject,
            url: "/Admin/Aed-listing",
          })
        );
      }
    } else if (Number(aeds?.totalcount) > 1) {
      if (
        Number(
          isPermission({ type: "dashboard", name: "EquipmentListing" })
        ) === 1
      ) {
        BreadCrumbsObject.title = item;
        dispatch(
          addNewBreadcrumbs({
            ...BreadCrumbsObject,
            url: `/account-details/${accountId}/Equipment`,
          })
        );
      }
    } else {
      if (
        Number(isPermission({ type: "dashboard", name: "ContactDetails" })) ===
        1
      ) {
        BreadCrumbsObject.title = `AED Name ${item}`;
        dispatch(
          addNewBreadcrumbs({
            ...BreadCrumbsObject,
            url: `/account/aed-details/${aeds?.aed_id}/Details`,
          })
        );
      }
    }
  };

  const navigateAeds = () => {
    if (!account?.account_id) {
      if (
        Number(
          isPermission({ type: "dashboard", name: "EquipmentListing" })
        ) === 1
      ) {
        handleDetailsDispatch();
        navigate("/Admin/Aed-listing");
      }
    } else if (Number(aeds?.totalcount) > 1) {
      if (
        Number(
          isPermission({ type: "dashboard", name: "EquipmentListing" })
        ) === 1
      ) {
        handleDetailsDispatch(account?.account_id, "Equipment");
        navigate(`/account-details/${account?.account_id}/Equipment`);
      }
    } else {
      if (
        Number(isPermission({ type: "dashboard", name: "AedDetails" })) === 1
      ) {
        handleDetailsDispatch(account?.account_id, "Details");
        navigate(`/account/aed-details/${aeds?.aed_id}/Details`);
      }
    }
  };

  // const navigateAeds = () => {
  //   //  const account = findSectionById("accounts", sections);
  //   if (!account?.account_id) {
  //     Number(isPermission({ type: "dashboard", name: "EquipmentListing" })) ===
  //       1 && navigate("/Admin/Aed-listing");
  //   } else if (Number(aeds?.totalcount) > 1) {
  //     Number(isPermission({ type: "dashboard", name: "EquipmentListing" })) ===
  //       1 && navigate(`/account-details/${account?.account_id}/Equipment`);
  //   } else {
  //     Number(isPermission({ type: "dashboard", name: "AedDetails" })) === 1 &&
  //       navigate(`/account/aed-details/${aeds?.aed_id}/Details`);
  //   }
  // };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setAeds(matched);
  }, [sections]);

  return (
    <div
      className="p-4 rounded-lg"
      style={{ cursor: Number(aeds?.totalcount) > 0 ? "pointer" : "" }}
      onClick={() => Number(aeds?.totalcount) > 0 && navigateAeds()}
    >
      <h3 id="aeds-card" className="text-center text-lg font-bold mb-2">
        {aeds?.totalcount}
      </h3>
      <p className="text-center">Aeds</p>
    </div>
  );
};

export default AedsCard;
