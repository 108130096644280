import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { BASE_API } from '../../services';
import _ from 'lodash';
import "./selectbox.scss";

const cache = new Map();

const CustomAEDBrandModalMultiDropdown = ({
  apiEndpoint,
  dropDownName = "select",
  parentKey = "",
  idKey = 'id',
  valueKey = 'value',
  isSearchable = false,
  initialSelectedValue = null,
  options = null,
  isMulti = false,
  placeholder = 'Select...',
  customRender = null,
  groupBy = null,
  isAsync = false,
  pageSize = 20,
  onSelect,
  disabled = false,
  isCache = false,
  tokenKey = 'ross_token',
  isRequired = false,
  minSelection = 0,
  maxSelection = Infinity,
  customValidation = null,
  i18nPrefix = 'dropdown',
  shouldFetchOnOpen = true,
  className = "",
  defaultValue = {},
  selectDisabled = false,
  isMultiSelect = false,
  closeMenuOnSelect = true,
  prepareOptions = null,
  showTags = true,
  showSelectedCount = false,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedValues, setSelectedValues] = useState(initialSelectedValue || []);
  useEffect(()=>{
    setSelectedValues(initialSelectedValue)
  },[initialSelectedValue])
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const dropdownRef = useRef(null);
  const hasLoadedData = useRef(false);


  
  const renderSelectedContent = () => {
    if (selectedValues.length === 0) {
      return <span  style={styles.placeholder}>{placeholder}</span>;
    }

    if (showSelectedCount) {
      return <span className='text-black'>
            {selectedValues.length} selected
      </span>

    }

    if (showTags) {
      return renderTags();
    }

    return selectedValues.map(v => v[valueKey]).join(', ');
  };

  const renderTags = () => (
    <div style={styles.tags}>
      {selectedValues.map(v => (
        <span key={v[idKey]} style={styles.tag}>
          {v[valueKey]}
          <button 
            type="button" 
            style={styles.tagRemove}
            aria-label="Remove"
            onClick={(e) => { 
              e.stopPropagation(); 
              handleRemoveTag(v); 
            }}
          >×</button>
        </span>
      ))}
    </div>
  );


  useEffect(() => {
    if (shouldFetchOnOpen && !hasLoadedData.current) {
      fetchData();
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [shouldFetchOnOpen]);

  const fetchData = useCallback(async () => {
    if (options) {
      setData(options);
      return;
    }

    const cacheKey = `${apiEndpoint}`;
    if (isCache && cache.has(cacheKey)) {
      setData(cache.get(cacheKey));
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem(tokenKey);
      if (!token) throw new Error(t(`${i18nPrefix}.noToken`));
      if (!parentKey) throw new Error(t(`Please Add Parent Key`));

      const response = await axios.get(`${BASE_API}${apiEndpoint}`, {
        params: { pageSize },
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const resultData = response?.data?.data?.[parentKey];
      // console.log({resultData});return "";
      if (resultData) {
        const preparedData = prepareOptions ? prepareOptions(resultData, idKey, valueKey) : resultData;
        setData(preparedData);
        if (isCache) cache.set(cacheKey, preparedData);
        hasLoadedData.current = true;
      }
      setError(null);
    } catch (err) {
      setError(err.message || t(`${i18nPrefix}.fetchError`));
    } finally {
      setLoading(false);
    }
  }, [apiEndpoint, options, pageSize, isCache, tokenKey, t, i18nPrefix, parentKey, prepareOptions, idKey, valueKey]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
      if (!hasLoadedData.current) {
        fetchData();
      }
    }
  };

  const handleSelect = (option) => {
    let newSelectedValues;
    if (isMultiSelect) {
      newSelectedValues = selectedValues.some(v => v[idKey] === option[idKey])
        ? selectedValues.filter(v => v[idKey] !== option[idKey])
        : [...selectedValues, option];
    } else {
      newSelectedValues = [option];
    }
    setSelectedValues(newSelectedValues);
    onSelect(newSelectedValues);
    validateSelection(newSelectedValues);

    if (closeMenuOnSelect && !isMultiSelect) {
      setIsOpen(false);
    }
  };

  const handleRemoveTag = (optionToRemove) => {
    const newSelectedValues = selectedValues.filter(v => v[idKey] !== optionToRemove[idKey]);
    setSelectedValues(newSelectedValues);
    onSelect(newSelectedValues);
    validateSelection(newSelectedValues);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const validateSelection = (value) => {
    if (isRequired && (!value || value.length === 0)) {
      setValidationError(t(`${i18nPrefix}.required`));
    } else if (isMultiSelect && value.length < minSelection) {
      setValidationError(t(`${i18nPrefix}.minSelection`, { count: minSelection }));
    } else if (isMultiSelect && value.length > maxSelection) {
      setValidationError(t(`${i18nPrefix}.maxSelection`, { count: maxSelection }));
    } else if (customValidation) {
      const customError = customValidation(value);
      setValidationError(customError);
    } else {
      setValidationError(null);
    }
  };

//   const renderTags = () => (
//     <div style={styles.tags}>
//       {selectedValues.map(v => (
//         <span key={v[idKey]} style={styles.tag}>
//           {v[valueKey]}
//           <button 
//             type="button" 
//             style={styles.tagRemove}
//             aria-label="Remove"
//             onClick={(e) => { 
//               e.stopPropagation(); 
//               handleRemoveTag(v); 
//             }}
//           >×</button>
//         </span>
//       ))}
//     </div>
//   );

  const filteredData = data.map(group => ({
    ...group,
    options: group.options.filter(option => 
      option[valueKey].toLowerCase().includes(searchTerm)
    )
  })).filter(group => group.options.length > 0);

  return (
    <div ref={dropdownRef} style={{...styles.dropdown, ...(disabled ? styles.disabled : {})}} className='custom-dropdown-box'>
      <div 
        style={{...styles.header, ...(isOpen ? styles.headerOpen : {})}}
        onClick={handleToggle}
      >
        {/* {selectedValues.length > 0 
          ? (showTags ? renderTags() : selectedValues.map(v => v[valueKey]).join(', '))
          : <span style={styles.placeholder}>{placeholder}</span>} */}
          {renderSelectedContent()}

      </div>
      {isOpen && !disabled && (
        <div style={styles.menu}>
          {isSearchable && (
            <input 
              type="text" 
              placeholder={t('Search...')}
              onChange={handleSearch} 
              style={styles.search}
              name={dropDownName}
            />
          )}
          {loading ? (
            <div className='loading-div'>{t('Loading...')}</div>
          ) : (
            <div className='option-list'>
              {filteredData.map((group, groupIndex) => (
                <div key={groupIndex} className='option-group-div'>
                  <div  
                    className='option-label'
                  >{group.label}</div>
                  {group.options.map((option, optionIndex) => (
                    <div 
                      key={optionIndex} 
                    //   style={{
                    //     ...styles.item,
                    //     ...(selectedValues.some(v => v[idKey] === option[idKey]) ? styles.itemSelected : {})
                    //   }}
                      onClick={() => handleSelect(option)}
                      className={`option-item ${selectedValues.some(v => v[idKey] === option[idKey]) ? 'selected-item': ''}`}
                    >
                      {customRender ? customRender(option) : option[valueKey]}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {error && <div className='text-danger' >{error}</div>}
      {validationError && <div className='text-danger'>{validationError}</div>}
    </div>
  );
};

const styles = {
  dropdown: {
    position: 'relative',
    width: '100%',
  },
  header: {
    cursor: 'pointer',
    minHeight: '38px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '0.25rem 0.5rem',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    backgroundColor: '#fff',
  },
  headerOpen: {
    borderColor: '#80bdff',
    boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
  },
  placeholder: {
    color: '#6c757d',
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.25rem',
    maxWidth: '100%',
  },
  tag: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#e9ecef',
    color: '#000',
    fontSize: '0.875rem',
    padding: '0.25rem 0.5rem',
    borderRadius: '0.25rem',
    marginRight: '0.25rem',
    marginBottom: '0.25rem',
  },
  tagRemove: {
    background: 'none',
    border: 'none',
    color: '#000',
    fontSize: '1.25rem',
    lineHeight: '1',
    padding: '0 0.25rem',
    marginLeft: '0.25rem',
    cursor: 'pointer',
  },
  menu: {
    position: 'absolute',
    top: '100%',
    left: '0',
    zIndex: '1000',
    width: '100%',
    maxHeight: '300px',
    overflowY: 'auto',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '0.25rem',
    boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.175)',
    padding: '0.5rem',
    marginTop: '0.25rem',
  },
  search: {
    width: '100%',
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    marginBottom: '0.5rem',
  },
  options: {
    marginTop: '0.5rem',
  },
  group: {
    marginBottom: '0.5rem',
  },
  groupLabel: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    color: '#000',
  },
  item: {
    padding: '0.375rem 0.75rem',
    cursor: 'pointer',
    borderRadius: '0.25rem',
  },
  itemSelected: {
    backgroundColor: '#e9ecef',
  },
  disabled: {
    opacity: '0.65',
    pointerEvents: 'none',
  },
  error: {
    color: '#dc3545',
    fontSize: '0.875rem',
    marginTop: '0.25rem',
  },
  validationError: {
    color: '#dc3545',
    fontSize: '0.875rem',
    marginTop: '0.25rem',
  },
  loading: {
    color: '#000',
    textAlign: 'center',
    padding: '0.5rem',
  },
};

export default CustomAEDBrandModalMultiDropdown;