import React, { useState } from "react";
import { Button as FixedButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { Alert, Box, Snackbar } from "@mui/material";
import FilterModel from "../../NewDashboard/model/FilterModel";
// import FilterModel from "../model/FilterModel";

const Filter = ({
  sections,
  permission,
  setSections,
  initialDashboardState,
  fetchOnLoad,
}) => {
  const [open, setOpen] = useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseModel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenModel(false);
  };

  return (
    <>
      <FixedButton className="btn-style-filter" onClick={handleDrawerOpen}>
        Advanced Filters
      </FixedButton>
      <Drawer
        sx={{
          width: "400px",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "400px",
            boxSizing: "border-box",
            background: "#000",
          },
        }}
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
      >
        {/* filter component  */}
        <FilterModel
          sections={sections}
          setSections={setSections}
          accountListingPage={true}
          setOpen={setOpen}
          handleDrawerClose={handleDrawerClose}
          initialDashboardState={initialDashboardState}
          fetchOnLoad={fetchOnLoad}
          permission={permission}
        />
      </Drawer>

      <Snackbar
        open={openModel}
        autoHideDuration={3000}
        onClose={handleCloseModel}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={handleCloseModel}
        >
          Record Deleted SuccessFully!!!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Filter;
