import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const MainAdminMenuBar = ({
  user,
  anchorEl,
  open,
  handleClose,
  moveSubAdminToUser,
  handleSwitchSubAdmin,
  handleLogout,
}) => {
  return (
    <div>
      <Menu
        className="user-dropdown"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: { position: "absolute", backgroundColor: "black" },
        }}
      >
        <>
        
        </>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default MainAdminMenuBar;
