import React from 'react';
import { Skeleton } from '@mui/material';

const SupportTicketTableSkeleton = () => (
  <table
    className="theme-table"
    style={{
      maxHeight: "400px",
      overflowY: "auto",
      padding: "0px",
    }}
  >
    <tbody>
      <tr>
        <td>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Skeleton variant="text" width={250} height={24} />
          </div>
        </td>
        <td>
          <div style={{ display: "flex", marginLeft: "23.8%" }}>
            <Skeleton variant="rectangular" width={50} height={24} />
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="text" width={150} height={24} />
        </td>
        <td>
          <Skeleton variant="rectangular" width="100%" height={80} />
        </td>
      </tr>
    </tbody>
  </table>
);

export default SupportTicketTableSkeleton;