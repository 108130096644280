import React from 'react';
import AEDInfoSkeleton from './AedSkeletons/AEDInfoSkeleton';

const AEDInfoContent = ({ brandName, aedData,modelName="" }) => (
  <tr>
    <td>AED</td>
    <td>
      {brandName && brandName} 
      {modelName ? " / " + modelName : ""}
    </td>
    <td>{aedData?.serial_number}</td>
    <td>{aedData?.asset}</td>
    <td>{aedData?.placement}</td>
  </tr>
);



const AEDInfoTable = ({ showGeneralInfo, brandName, modelName, aedData, showDetails=true }) => {
  return (
    <div className="table-main-20002">
      <table className="table">
        <thead className="border heading">
          <tr>
            <th>Type</th>
            <th>Brand / Modal</th>
            <th>Serial #</th>
            <th>Asset #</th>
            <th>Placement</th>
          </tr>
        </thead>
        <tbody>
          {showGeneralInfo ? (
              <AEDInfoSkeleton />
        ) : (
          showDetails ? <AEDInfoContent brandName={brandName} aedData={aedData} modelName={modelName} /> : 
          <AEDInfoContentForSelect brandName={brandName} aedData={aedData} modelName={modelName} />
          )}
        </tbody>
      </table>
    </div>
  );
};


const AEDInfoContentForSelect = ({ brandName, modelName,aedData }) => (
  <tr>
                  <td>AED</td>
                  <td>
                    {brandName}{" "}
                    {modelName ? " / " + modelName : ""}
                  </td>
                  <td>{aedData?.serial_number}</td>
                  <td>{aedData?.asset}</td>
                  <td>{aedData?.placement}</td>
                </tr>
);

export default AEDInfoTable;