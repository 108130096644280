import React, { useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchForm from "./SearchForm";
import { useNavigate } from "react-router-dom";
import Loading from "../Loader/Loading";
// import { user } from "../../helper/BasicFn";
import { DecryptToken } from "../../helper";
import { getPer } from "../../helper/BasicFn";

const Header = ({ handleShow }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = DecryptToken();
  // const handleImageClick = () => {
  //   setLoading(true);
  //   navigate("/dashboard");
  //   setLoading(false);
  //   // setTimeout(() => {
  //   //   setLoading(false);
  //   //  navigate("/dashboard");
  //   // }, 1000);
  // };
  return (
    <>
      <Navbar style={{ background: "#000" }} variant="dark" expand="lg">
        <Container fluid className="my-container">
          <div
            className="d-flex "
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <Navbar.Brand style={{ display: "block" }}>
              <span
                variant="outline-light"
                style={{
                  border: "none",
                  width: "20px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                className="me-2"
                onClick={handleShow}
              >
                <FontAwesomeIcon icon={faBars} />
              </span>

              <img
                src="/assets/images/red-logo.svg"
                alt={"Ross-Logo"}
                style={{
                  width: "26rem",
                  cursor: Number(getPer()) === 1 ? "pointer" : "",
                }}
                onClick={() =>
                  Number(getPer()) === 1 ? navigate("/dashboard") : ""
                }
              />
            </Navbar.Brand>
            <div className="auto">
              <SearchForm />
            </div>
          </div>

          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <div
              className="d-flex align-items-right NavbarUserIconDiv"
              // onClick={(e) => handleClick(e)}
              // onClick={handleClick}
              style={{ position: "relative", cursor: "pointer" }}
            >
              {/* <Person2Icon sx={{ color: "white" }} fontSize="large" /> */}
              <FontAwesomeIcon icon="fa-solid fa-user" />

              {/* <div style={{ color: "white" }} className="NavbarUserEmailDiv">
                <div> {user?.name}</div>
                <div className="Email">{user?.email} Check</div>
              </div> */}
              <FontAwesomeIcon icon="fa-solid fa-caret-down" />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {loading && (
        <>
          <div className="showloading">
            <Loading />
          </div>
        </>
      )}
    </>
  );
};

export default Header;
