import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import {
  DecryptToken,
  formatPhoneNumber,
  getPermission,
} from "../../../../common/helper";
// import { AccountSiteList, user } from "../../../../common/helper/BasicFn.js";
import { Skeleton } from "@mui/material";
import { linkTabsPermission } from "../../../../common/helper/permission";
import New from "../../../../common/img/New.png";
import NewGlobalTable from "../../../../common/components/custom-ui/NewGlobalTable.jsx";
import { getPer, truncateText } from "../../../../common/helper/BasicFn";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice.js";
import { handleTabfunction } from "../../../../store/slices/TabSlice.js";
import { AccountSiteTab } from "../../../../common/hooks/fieldsConfig.js";
import { isPermission } from "../../../../common/helper/PermissionManager.jsx";

const Sites = ({ setTabTbldata, is_user, accountName }) => {
  const navigate = useNavigate();
  const filterData = useSelector((state) => state.accountlistfilter.filterData);
  const user = DecryptToken();
  const privilege = getPermission();
  const { accountId } = useParams();

  const [showLoading, setShowLoading] = useState(false);
  const [siteDataList, setSiteDataList] = useState([]);
  const [siteData, setSiteData] = useState({});
  const dispatch = useDispatch();
  const subAdminPermission = [
    "site-details",
    "contact-tab",
    "equipment-tab",
    "training-tab",
    "inperson-tab",
    "notes-tab",
    "documents-tab",
  ];
  // is_user ? "/user/site-details/" :
  let site_url = "/account/site-details/";

  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "",
  };

  const handleDetailsDispatch = (siteId, site_name, accountName, accountId) => {
    BreadCrumbsObject.title = site_name + " Details";
    BreadCrumbsObject.tab = "Details";
    BreadCrumbsObject.type = "site";
    BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/site-details/${siteId}/Details`,
      })
    );
  };

  const handleNewSiteDispatch = (accountId) => {
    BreadCrumbsObject.title = " New Site Form";
    BreadCrumbsObject.tab = "sites";
    BreadCrumbsObject.type = "account";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/admin-siteNew/" + accountId,
      })
    );
  };

  const RenderPhone = (data) => (
    <>
      {data?.account_site_phone && (
        <a
          className="link"
          style={{ textDecoration: "none" }}
          href={
            "tel:" + data?.account_site_phone_ext + data?.account_site_phone
          }
        >
          {data?.account_site_phone
            ? formatPhoneNumber(data?.account_site_phone)
            : ""}{" "}
          {data?.account_site_phone_ext
            ? " X " + data?.account_site_phone_ext
            : ""}
        </a>
      )}
    </>
  );

  const RenderSitePOC = (data) => {
    return (
      <>
        <span
          style={{ color: "black" }}
          className={
            Number(isPermission({ type: "link", name: "sitePoc" })) === 1
              ? "link"
              : ""
          }
          onClick={() => {
            if (Number(isPermission({ type: "link", name: "sitePoc" })) === 1) {
              navigate(
                "/account/" +
                  accountId +
                  "/contact-details/" +
                  data.account_site_info_id,
                {
                  state: {
                    siteTitle: "Contact : " + data?.account_site_poc,
                    accountName: accountName,
                    accountId: accountId,
                    editUrl:
                      "/account/contact-details-edit/" +
                      data.account_site_info_id,
                    deleteUrl:
                      "/account/contact-details-edit/" +
                      data.account_site_info_id,
                  },
                }
              );
            }
          }}
        >
          {data?.account_site_poc}
        </span>
      </>
    );
  };

  const RenderEquipment = (data) => (
    <span className="py-1 px-2 tbl-border">
      {data?.aedCount}/{data?.equipmentCount}
    </span>
  );

  const RenderSiteName = (data) => {
    return (
      <>
        {/* <td className="py-1 px-2 tbl-border border-r-blue"> */}

        <span
          className={
            Number(isPermission({ type: "link", name: "mainSite" })) === 1
              ? "link"
              : ""
          }
          onClick={() => {
            if (
              Number(isPermission({ type: "link", name: "mainSite" })) === 1
            ) {
              handleDetailsDispatch(
                data.account_site_info_id,
                data?.account_site_name,
                accountName,
                accountId
              );
              // dispatch(
              //   handleTabfunction({
              //     value: AccountSiteTab.Details,
              //     type: "site",
              //   })
              // );
              navigate(site_url + data.account_site_info_id + "/Details", {
                state: {
                  siteTitle: "Site: " + data?.account_site_name,
                  accountName: accountName,
                  accountId: accountId,
                  ...(is_user
                    ? {}
                    : {
                        editUrl:
                          "/account/site-details-edit/" +
                          data.account_site_info_id,
                        deleteUrl:
                          "/account/site-details-edit/" +
                          data.account_site_info_id,
                      }),
                },
              });
            }
          }}
          title={
            data?.account_site_name.length > 20
              ? data?.account_site_name
              : undefined // Tooltip only if the text is truncated
          }
        >
          {truncateText(data?.account_site_name, 100)}
        </span>
      </>
    );
  };

  const headerColumns = [
    {
      Header: "Main Site",
      accessor: "account_site_name",
      Cell: ({ row }) => RenderSiteName(row.original),
    },
    {
      Header: "Equipment",
      accessor: "equipmentCount",
      width: "5%",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div>{RenderEquipment(row.original)}</div>
          )}
        </>
      ),
      disableSortBy: true,
    },
    {
      Header: "Training",
      accessor: "account_site_training",
      width: "5%",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            row.original.account_site_training
          )}
        </>
      ),
      disableSortBy: true,
    },
    {
      Header: "Site POC",
      accessor: "account_site_poc",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div style={{ color: "blue" }}>{RenderSitePOC(row.original)}</div>
          )}
        </>
      ),
    },
    {
      Header: "Phone",
      accessor: "account_site_phone",
      // width: "15%",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div style={{ color: "blue" }}>{RenderPhone(row.original)}</div>
          )}
        </>
      ),
    },
    {
      Header: "Email",
      accessor: "account_site_email",
      // width: "20%",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <a
              className="link"
              href={"mailto:" + row.original.account_site_email}
              style={{ textDecoration: "none" }}
            >
              {" "}
              {row.original.account_site_email}
            </a>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Box
        className="d-flex justify-content-between align-items-center mt-2"
        style={{ marginBottom: "0px !important" }}
      >
        <h3 className="heading">Account Site Information</h3>
        <div style={{ display: "flex", flexDirection: "row", gap: "0px" }}>
          {Number(isPermission({ type: "btn", name: "newSite" })) ? (
            <Link
              style={{ textDecoration: "none", marginRight: "10px" }}
              to={"/admin-siteNew/" + accountId}
              onClick={() => handleNewSiteDispatch(accountId)}
            >
              <img src={New} />
              <span style={{ color: "#0C71C3" }}> New</span>
            </Link>
          ) : (
            ""
          )}
        </div>
      </Box>

      <NewGlobalTable
        isCache={true}
        pageSize={20}
        method="GET"
        apiEndPoint={
          Number(getPer()) === 1
            ? `site/account-site-list/${accountId}`
            : `user/user-site-tab/${accountId}`
        }
        keyAPiName={"site_details"}
        headerColumns={headerColumns}
      />
    </>
  );
};

export default Sites;
