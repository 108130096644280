// apiSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { printConsole } from '../../common/helper/Common';

const AccountDataSlice = createSlice({
    name: 'Account',
    initialState: {
        data: [],

        error: null,
    },
    reducers: {

        setData: (state, action) => {
            // printConsole("checking redux slice", action.payload) // print console.log
            const { accountdetail, Is_AccountList } = action.payload;
            // printConsole("i am from redux", Is_AccountList) // print console.log
            state.data = {
                ...state.data,
                accountdetail,
                Is_AccountList,
            };
        },

        setDataList: (state, action) => {
            // printConsole("checking redux slice", action.payload) // print console.log
            const { accountList } = action.payload;
            // printConsole("i am from redux", accountList) // print console.log
            state.data = {
                ...state.data,
                accountList,

            };
        },

    },
});

export const { setData, setDataList } = AccountDataSlice.actions;
export default AccountDataSlice.reducer;
