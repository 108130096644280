export const PermissionList = {
    "dashboard":1,
    "accounts-listing":1,
    "account-details":1,
    "account-edit":1,
    "site-tab":1,
    "sites-new":1,
    "site-details":1,
    "site-details-edit":1,
    "contact-tab":1,
    "contacts-new":1,
    "contact-details":1,
    "contact-details-edit":1,
    "equipment-tab":1,
    "new-aed":1,
    "aed-details":1,
    "aed-edit":1,
    "move-aed":1,
    "assign-aed":1,
    "new-accessories":1,
    "move-accessory":1,
    "new-aed-checks":1,
    "aed-check-details":1,
    "aed-service-check":1,
    "service-check-details":1,
    "training-tab":1,
    "training-new":1,
    "training-details":1,
    "edit-training":1,
    "inperson-tab":1,
    "new-inperson":1,
    "inperson-details":1,
    "edit-inperson":1,
    "pops-tab":1,
    "new-pop":1,
    "pop-details":1,
    "pop-edit":1,
    "notes-tab":1,
    "new-note":1,
    "note-details":1,
    "edit-note":1,
    "email-tab":1,
    "support-tab":1,
    "new-support":1,
    "support-details":1,
    "edit-support":1,
    "documents-tab":1,
    "new-document":1,
    "document-details":1,
    "edit-document":1,
    "rfi-tab":1,
    "new-rfi":1,
    "rfi-details":1,
    "edit-rfi":1,
    "out-of-service":1,
    "support-respond":1,
    "support-reassign":1,
    "support-close":1,
    "pop-clone":1,
    "inperson-clone":1,
    "inperson-student-tab":1,
    "inperson-certification-tab":1,
    "inperson-instructor":1,
    "inperson-broadcast":1,
    "switch-user":1,
}

export const MainDashboardTopTabLists = [
    {name: 'Details', id: 'account-details', 'order': 1},
    { name: 'Sites', id: 'site-tab', 'order': 2 },
    { name: 'Contacts', id: 'contact-tab', 'order': 7 },
    { name: 'Equipment', id: 'equipment-tab', 'order': 3 },
    { name: 'Training', id: 'training-tab', 'order': 4 },
    { name: 'Classes', id: 'classes-tab', 'order': 5 },
    { name: 'Notes', id: 'notes-tab', 'order': 8 },
    { name: 'Emails', id: 'email-tab', 'order': 9 },
    { name: 'Support', id: 'support-tab', 'order': 10 },
    { name: 'Documents', id: 'documents-tab', 'order': 11 },
    { name: 'RFI', id: 'rfi-tab', 'order': 12 },
  ];
  
  export const SiteTopTabLists = [
    // {name: 'Details', id: 'account-details', 'order': 1},
    { name: 'Sites', id: 'site-tab', 'order': 2 },
    { name: 'Contacts', id: 'contact-tab', 'order': 7 },
    { name: 'Equipment', id: 'equipment-tab', 'order': 3 },
    { name: 'Training', id: 'training-tab', 'order': 4 },
    { name: 'Inperson', id: 'inperson-tab', 'order': 5 },
    { name: 'Classes', id: 'classes-tab', 'order': 5 },
    // { name: 'POPS', id: 'pop-tab', 'order': 6 },
    // { name: 'Contacts', id: 'contact-tab', 'order': 7 },
    { name: 'Notes', id: 'notes-tab', 'order': 8 },
    { name: 'Emails', id: 'email-tab', 'order': 9 },
    { name: 'Support', id: 'support-tab', 'order': 10 },
    { name: 'Documents', id: 'documents-tab', 'order': 11 },
    { name: 'RFI', id: 'rfi-tab', 'order': 12 },
  ];
  
  export const EquipmentTopTabLists = [
    {name: 'Details', id: 'aed-details', 'order': 1},
    { name: 'Notes', id: 'notes-tab', 'order': 2 },
    // { name: 'Emails', id: 'email-tab', 'order': 9 },
    // { name: 'Support', id: 'support-tab', 'order': 10 },
    // { name: 'Documents', id: 'documents-tab', 'order': 11 },
    // { name: 'RFI', id: 'rfi-tab', 'order': 12 },
  ];

  export const AccountSiteTab = {
    Details: "Details",
    Contacts: "Contacts",
    Equipment: "Equipment",
    Inperson: "Inperson",
    // Instructor: "Instructor",
    Notes: "Notes",
    Emails: "Emails",
    Support: "Support",
    Documents: "Documents",
    RFI: "RFI",
  };
  
  export const filteredDetailsTabs = (array) => {
    return MainDashboardTopTabLists.filter(item =>
      array.some(({ name }) => name === item.name)
    );
  }
  
  export const filteredSiteTabs = (array) => {
    return SiteTopTabLists.filter(item =>
      array.some(({ name }) => name === item.name)
    );
  }
  
  export const filteredEquipmentTabs = (array) => {
    return EquipmentTopTabLists.filter(item =>
      array.some(({ name }) => name === item.name)
    );
  }
  
  export const SiteDetailsTabList = [
    { name: 'Sites', id: 'site-tab' },
  ];
  
  export const sortArrAscending = (arr, key) => {
    const result = arr.sort((a, b) => {
      const valA = a[key]?.trim().toLowerCase();
      const valB = b[key]?.trim().toLowerCase();
      return (valA < valB) ? -1 : (valA > valB) ? 1 : 0;
    });
    return result;
  }
  
  export const removeBlankObj = (arr, key) => {
    if (arr) {
      const filteredArray = arr.filter(obj => obj[key]);
      return filteredArray
    }
  }
  