import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'

import "../css/header.scss";
import { ToastContainer } from 'react-bootstrap';
function Layout({ children }) {

  return (
    <div className="d-flex flex-column min-vh-100">
      <TopBar />
      <Header />
      <main className="flex-grow-1" style={{ minHeight: '86.3vh' }}>
        {children}
      </main>
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default Layout