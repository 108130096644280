import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, Form } from "react-bootstrap";
import CustomToggleButton from "../../../common/components/toggleSwitch/CustomToggle3";
import { CallPOSTAPI } from "../../../common/services";
import {
  checkLabelsLength,
  checkPermissionById,
  removeInvisibleItems,
  showUser,
} from "../../DNDDashboard/components/Services";
import { DecryptToken } from "../../../common/helper";
import { isPermission } from "../../../common/helper/PermissionManager";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const FilterModel = ({
  handleDrawerClose,
  sections,
  setSections,
  initialDashboardState,
  fetchOnLoad,
  permission,
}) => {
  const [formData, setFormData] = useState([
    {
      id: "accounts",
      name: "Account",
      color: "#ff0000",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "sites",
      name: "Site",
      color: "#00bfff",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "contacts",
      name: "Contact",
      color: "#00ff00",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "aeds",
      name: "Aed",
      color: "#8a2be2",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "accessories",
      name: "Accessories",
      color: "#ffd700",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "aedsAssigned",
      name: "AedAssigned",
      color: "#00bfff",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "aedChecks",
      name: "AedCheckCard",
      color: "#ff0000",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "supportTickets",
      name: "Support Tickets",
      color: "",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "delinquentChecks",
      name: "Delinquent Checks",
      color: "",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "aedsOwned",
      name: "Aeds Owned",
      color: "",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "aedDistribution",
      name: "Aeds Distribution",
      color: "",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "AedTechServicing",
      name: "Aed Tech Servicing",
      color: "",
      isPickerOpen: false,
      isVisible: 1,
    },
    {
      id: "aedTechAssignments",
      name: "Aed Tech Assignments",
      color: "",
      isPickerOpen: false,
      isVisible: 1,
    },
  ]);

  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);

  const updateDashboardState = (formData, initialDashboardState) => {
    return initialDashboardState.map((section) => ({
      ...section,
      items: section.items.map((item) => {
        // Match the id with formData
        const formItem = formData.find((form) => form.id === item.id);

        // If a matching item is found, update isVisible and color
        if (formItem) {
          return {
            ...item,
            isVisible: formItem.isVisible,
            color: formItem.color,
          };
        }

        // Return the item as is if no match is found
        return item;
      }),
    }));
  };

  const handleColorChange = (index, event) => {
    const newAccounts = [...formData];
    newAccounts[index].color = event.target.value;
    setFormData(newAccounts);
  };

  const toggleActive = (index) => {
    const newAccounts = [...formData];
    newAccounts[index].isVisible = newAccounts[index].isVisible === 1 ? 0 : 1; // Toggle between 0 and 1
    setFormData(newAccounts);
  };

  const handleOkClick = (index) => {
    const newAccounts = [...formData];
    newAccounts[index].isPickerOpen = false;
    setFormData(newAccounts);
  };

  const togglePicker = (index) => {
    const newAccounts = [...formData];
    newAccounts[index].isPickerOpen = !newAccounts[index].isPickerOpen;
    setFormData(newAccounts);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      const newAccounts = formData.map((item) => ({
        ...item,
        isPickerOpen: false,
      }));
      setFormData(newAccounts);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formData]);

  function updateFormDataWithSections() {
    const updatedFormData = formData.map((item) => {
      const section = sections.find((section) =>
        section.items.some((sectionItem) => sectionItem.id === item.id)
      );
      if (section) {
        const sectionItem = section.items.find(
          (sectionItem) => sectionItem.id === item.id
        );
        if (sectionItem) {
          return {
            ...item,
            color: sectionItem.color,
            isVisible: sectionItem.isVisible,
            data: sectionItem.data,
          };
        }
      }
      return item;
    });
    setFormData(updatedFormData);
  }

  function removeDataFromSections(filterPayloadData) {
    return filterPayloadData.map((section) => ({
      ...section,
      items: section.items.map((item) => ({
        ...item,
        data: [],
      })),
    }));
  }

  useEffect(() => {
    const updateFilter = updateFormDataWithSections();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const filterPayloadData = updateDashboardState(formData, sections);
    const removedDataFromSections = removeDataFromSections(filterPayloadData);

    const payload = {
      component: removedDataFromSections,
      // component: filterPayloadData,
    };

    setSections(filterPayloadData);
    const res = await CallPOSTAPI("user/assign-dashboard-component", payload);

    setLoading(false);
    handleDrawerClose();
    await fetchOnLoad();
  };

  const resetDashboardVisibility = (sections) => {
    if (!sections || !Array.isArray(sections)) {
      return [];
    }

    return sections.map((section) => ({
      ...section,
      items: section.items.map((item) => ({
        ...item,
        isVisible: 1, // Set isVisible to true
      })),
    }));
  };

  const resetdashboard = async () => {
    handleDrawerClose();
    setLoading(true);
    const filterPayloadData = resetDashboardVisibility(sections); //updateDashboardState(formData, sections);

    const payload = {
      component: null,
    };

    // setSections(filterPayloadData);
    const res = await CallPOSTAPI("user/assign-dashboard-component", payload);
    await fetchOnLoad();
    setLoading(false);
    // handleDrawerClose();
  };

  return (
    <div ref={containerRef} style={{ background: "#000" }}>
      <DrawerHeader>
        <div className="left-btns">
          <IconButton
            onClick={handleDrawerClose}
            className="my-account-list-btn"
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </DrawerHeader>

      <div className="px-4 mt-5">
        {formData.map((item, index) =>
          Number(checkPermissionById(item, permission)) === 1 &&
          Number(showUser(item)) === 1 &&
          Number(
            isPermission({
              type: "dashboardStates",
              name: item.id,
            })
          ) === 1 ? (
            <Form.Group
              key={index}
              className="mt-3 mb-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label style={{ minWidth: "180px" }}>{item.name}</Form.Label>

              <div style={{ marginLeft: "2px" }}>
                <CustomToggleButton
                  ToggleName={item.name}
                  ToggleValue={item.isVisible}
                  changeHandler={() => toggleActive(index)}
                  // is_read_only={Number(checkLabelsLength(item)) === 1}
                  is_read_only={
                    Number(checkPermissionById(item, permission)) === 0
                  }
                />
              </div>

              {item?.color != "" ? (
                <div style={{ marginLeft: "20px" }}>
                  <input
                    type="color"
                    value={item.color}
                    onChange={(event) => handleColorChange(index, event)}
                    className="form-control"
                    id={`colorPicker-${index}`}
                    style={{ width: "40px", height: "30px", padding: 0 }}
                    onFocus={() => togglePicker(index)} // Show picker on focus
                  />
                </div>
              ) : (
                ""
              )}
              {item.isPickerOpen && (
                <button
                  onClick={() => handleOkClick(index)}
                  style={{
                    marginLeft: "15px",
                    height: "30px",
                    backgroundColor: "#0C71C3",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "5px",
                  }}
                >
                  OK
                </button>
              )}
            </Form.Group>
          ) : (
            ""
          )
        )}
        <div
          style={{
            width: "100%",
            marginTop: "40px",
            marginRight: "40px",
            gap: "20px",
            textAlign: "center",
            display: "grid",
          }}
        >
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
            disabled={loading}
            style={{ width: "100%" }}
          >
            {loading ? "Submitting..." : "Apply"}
          </Button>
          <Button
            variant=""
            type="button"
            onClick={() => resetdashboard()}
            style={{ width: "100%", textAlign: "center", color: "#fff" }}
          >
            Reset Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterModel;
