import React from 'react';
import { Skeleton } from '@mui/material';

const ServicingNotesSkeleton = ({showIssueToggle=true, heading="Servicing Notes*"}) => {
  return (
    <div className="col py-2">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <h2 className="heading">{heading}</h2>
      {showIssueToggle &&  <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "5%",
            marginRight: "1%",
          }}
        >
            <h2 className="heading">Issue</h2>
            <Skeleton variant="rectangular" width={50} height={24} />
        </div>}
      </div>
      <div
        className="service-notes-container"
        style={{
          height: "200px",
          width: "100%",
          border: `3px solid #337ab7`,
          padding: "5px",
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </div>
    </div>
  );
};

export default ServicingNotesSkeleton;