// import React from 'react'
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';

// function ConfirmAlert({show,handleClose,msg,handleOk,loading}) {
//   return (
//     <>
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>{msg}</Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" type="button" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary"  type="button" onClick={handleOk} disabled={loading}>
//             {loading ? 'Loading...' : 'Save Changes'}
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   )
// }

// export default 

import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Form } from "react-bootstrap";

function ConfirmAlert({ show, handleClose, msg, handleOk, loading }) {
    const [validated, setValidated] = React.useState(false);
    const [closingReason, setClosingReason] = React.useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        textAlign: 'center',
        boxShadow: 24,
        p: 4,
        borderRadius: 2
    };

    const handleConfirm = (e) => {
        e.preventDefault();  // Prevent default form submission
        if (!closingReason.trim()) {
            setValidated(true);
            return;
        }
        handleOk(closingReason);  // Pass the closing reason to the handleOk function
    };

    return (
        <>
            <Modal
                open={show}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Form
                        className=""
                        onSubmit={handleConfirm}
                        noValidate
                        validated={validated}
                        id="create-new-respond-support-form"
                    >
                        <h2 style={{ margin: 0, fontWeight: 600, fontSize: 30 }}>Are you sure?</h2>

                        <div className="my-modal-section" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', marginTop: '5%', marginBottom: '5%' }}>
                            <Form.Group controlId="closingReason">
                                <label
                                    htmlFor="closingReason"
                                    style={{ margin: '0 0 10px 0', fontWeight: 500, fontSize: '16px', textAlign: 'left' }}
                                >
                                    Reason for Closing
                                </label>
                                <textarea
                                    id="closingReason"
                                    value={closingReason}
                                    rows="4"
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        fontSize: '14px',
                                        borderColor: validated && !closingReason ? 'red' : '#ced4da',
                                        borderRadius: '4px',
                                        resize: 'none',
                                    }}
                                    placeholder="Enter reason for closing"
                                    onChange={(e) => setClosingReason(e.target.value)}
                                />
                                {validated && !closingReason && (
                                    <Form.Control.Feedback type="invalid" style={{ display: 'block', color: 'red' }}>
                                        The information field is required
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </div>

                        <div className="col-12 content-flex-center">
                            <button
                                className="btn text-uppercase cancel-button"
                                type="button"
                                onClick={handleClose}
                                style={{ marginLeft: '120px' }}
                            >
                                Cancel
                            </button>
                            <button
                                className={"btn btn-success"}
                                variant="success"
                                style={{ fontSize: "14px", marginLeft: '20px' }}
                                type="submit"
                                disabled={loading}  // Disable button while loading
                            >
                                {loading ? 'Loading...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                </Box>
            </Modal>
        </>
    );
}

export default ConfirmAlert;
