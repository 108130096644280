import React, { useEffect, useRef, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  fetchPieChartData,
  fetchPieChartdataValues,
  supportPermission,
} from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { Skeleton } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

function SupportHalfPieChart({ sections, setSections }) {
  const [piedata, setPiedata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(350);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const result = await fetchPieChartData(
        sections,
        "support",
        "supportTickets"
      );
      setPiedata(result);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        setChartWidth(width);
      }
    });

    resizeObserver.observe(chartContainerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const getChartSize = () => {
    const maxSize = Math.min(chartWidth * 0.8, 400);
    return { width: maxSize, height: maxSize };
  };

  const chartSize = getChartSize();

  // Only collect valid data points
  const chartData =
    piedata?.reduce((acc, item) => {
      const dataPoints = [
        {
          label: "Created Opened Ticket",
          value: item.created_opened_ticket_count,
          permission: "created_opened_ticket_count",
          color: "#FF6384",
        },
        {
          label: "Responses Needed",
          value: item.reponse_needed_ticket_count,
          permission: "reponse_needed_ticket_count",
          color: "#36A2EB",
        },
        {
          label: "Owned Open Tickets",
          value: item.open_owned_ticket_count,
          permission: "open_owned_ticket_count",
          color: "#FFCE56",
        },
        {
          label: "Owned Not Viewed",
          value: item.owned_not_viewed_count,
          permission: "owned_not_viewed_count",
          color: "#4CAF50",
        },
        {
          label: "Owned Past Due",
          value: item.owned_past_due_count,
          permission: "owned_past_due_count",
          color: "#FF9F40",
        },
        {
          label: "Closed Tickets",
          value: item.closed_ticket_count,
          permission: "closed_ticket_count",
          color: "#9966FF",
        },
      ].filter(
        (point) =>
          point.value > 0 && Number(supportPermission(point.permission)) === 1
      );

      return dataPoints;
    }, []) || [];

  // Check if there are any valid tickets
  const hasTickets = chartData.length > 0;

  const data = {
    labels: hasTickets
      ? chartData.map((item) => item.label)
      : ["No Support Tickets"],
    datasets: [
      {
        data: hasTickets ? chartData.map((item) => item.value) : [1],
        backgroundColor: hasTickets
          ? chartData.map((item) => item.color)
          : ["#D3D3D3"],
        hoverBackgroundColor: hasTickets
          ? chartData.map((item) => item.color)
          : ["#A9A9A9"],
      },
    ],
  };

  const options = {
    rotation: -90,
    circumference: 180,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: hasTickets },
    },
    maintainAspectRatio: false,
  };

  return (
    <>
      {!loading ? (
        <div
          className="support-half-pie-chart"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "1px",
            justifyContent: "center",
            boxSizing: "border-box",
            minHeight: "450px",
          }}
        >
          <h4>Support Tickets</h4>
          <div
            className="chart-content"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "5px",
              flex: 1,
              minHeight: 0,
              alignItems: "center",
            }}
          >
            <div
              ref={chartContainerRef}
              className="chart-container"
              style={{
                flex: "1 1 auto",
                minWidth: "100px",
                maxWidth: "90%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: chartSize.width,
                  height: chartSize.height,
                  minWidth: "50px",
                  minHeight: "50px",
                }}
              >
                <Pie data={data} options={options} />
              </div>
            </div>
            <div
              className="label-list"
              style={{
                flex: "1 1 auto",
                minWidth: "150px",
                maxWidth: "100%",
                maxHeight: "400px",
                overflowY: "auto",
                padding: "10px",
              }}
            >
              {hasTickets ? (
                chartData.map((item, index) => (
                  <p
                    key={index}
                    className="label-item"
                    style={{
                      color: item.color,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      margin: "4px 0",
                    }}
                    onClick={() =>
                      Number(
                        isPermission({
                          type: "dashboard",
                          name: "SupportListing",
                        })
                      ) === 1 && navigate("/account/support-listing")
                    }
                  >
                    <span
                      style={{
                        width: "12px",
                        height: "12px",
                        backgroundColor: item.color,
                        display: "inline-block",
                        borderRadius: "50%",
                      }}
                    />
                    {item.label}
                  </p>
                ))
              ) : (
                <p
                  className="label-item"
                  style={{
                    color: "#A9A9A9",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    margin: "4px 0",
                  }}
                >
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: "#A9A9A9",
                      display: "inline-block",
                      borderRadius: "50%",
                    }}
                  />
                  No Support Tickets
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
        // <Skeleton
        //   count={5}
        //   width={"100%"}
        //   height={"100%"}
        //   style={{ margin: "1px" }}
        // />
      )}
    </>
  );
}

export default SupportHalfPieChart;
