import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import { CallGETAPI } from "../../../../common/services";
import {
  addNewBreadcrumbs,
  removeBreadcrumb,
} from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
import FinalNewBreadcrumbs from "../../../../common/components/breadcrumbsComp/FinalNewBreadcrumbs";
import ScrollTopButton from "../../../../common/components/ScrollTopButton";
import { printConsole } from "../../../../common/helper/Common";

const AedServiceCheck = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [serviceCheckTblData, setServiceCheckTblData] = useState([]);
  const { accountId, siteId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedaccountRows, setselectedaccountRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const siteName = location.state ? location.state.siteName : null;
  const key = location.state ? location.state.key : null;
  const [newTblData, setNewTblData] = useState([]);

  printConsole({ location }); // print console.log

  const fetchData = async () => {
    try {
      const res = await CallGETAPI(
        `aed/get-aed-by-site-id/${accountId}/${siteId}`
      );

      const ld = res?.data?.data || [];
      const details = ld.map((el) => ({
        ...el.aed_details,
      }));
      printConsole({ details }); // print console.log
      const NewArrData = ld.map((el) => ({
        id: el.aed_details.aed_id,
        brand: el.aed_brand,
        ...el.aed_details,
      }));

      setNewTblData(NewArrData);
      setServiceCheckTblData(ld);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [accountId, siteId]);

  const toggleRowSelection = (id, accId) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
    setselectedaccountRows((prevSelected) =>
      prevSelected.includes(accId)
        ? prevSelected.filter((rowId) => rowId !== accId)
        : [...prevSelected, accId]
    );
  };

  const toggleAllRows = (checked) => {
    if (checked) {
      setSelectedRows(newTblData.map((row) => row.id));
      setselectedaccountRows(newTblData.map((row) => row.account_id));
    } else {
      setSelectedRows([]);
      setselectedaccountRows([]);
    }
  };

  const handleRemoveDispatch = () => {
    const editPageUrl =
      "/account/aed/service-check/" + accountId + "/" + siteId;
    dispatch(removeBreadcrumb(editPageUrl));
  };

  const BreadCrumbsObject = {
    title: "",
  };
  const handleServiceDispatch = (selectedRowId) => {
    printConsole("This function is executed", selectedRowId); // print console.log
    BreadCrumbsObject.title = "Service Check Form";
    // BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/aed/service-check/service1/${accountId}/${siteId}/aedId?aedId=${selectedRowId}`,
      })
    );
  };
  const handleAedDispatch = (selectedRowId) => {
    BreadCrumbsObject.title = "Aed Check Form";
    // BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/aed-details/check-select`,
        title: "Aed Check Form",
      })
    );
  };

  printConsole({ selectedRows }); // print console.log
  const handleSubmit = () => {
    try {
      if (selectedRows.length > 0) {
        const selectedRowId = selectedRows;
        const AedselectedRowId = selectedRows;
        printConsole({ selectedaccountRows }); // print console.log

        if (key == "serviceCheck") {
          handleServiceDispatch(encodeURIComponent(selectedRowId));
          navigate(
            `/account/aed/service-check/service1/${accountId}/${siteId}/aedId?aedId=${encodeURIComponent(
              selectedRowId
            )}`,
            {
              state: {
                selectedRowId: encodeURIComponent(selectedRowId),
              },
            }
          );
        }
        if (key == "aedCheck") {
          const currentId = AedselectedRowId?.shift();
          const currentaccountId = selectedaccountRows[0];
          handleAedDispatch();
          navigate(
            `/account/aed-details/check-select?aed_id=${currentId}&account_id=${accountId}`,
            {
              state: {
                siteId: siteId,
                selectedRowId: AedselectedRowId,
                selectedaccountId: selectedaccountRows,
                key: key,
              },
            }
          );
        }
      } else {
        console.error("Please select a row before submitting.");
        toast.error("Please select an AED before submitting.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Please select an AED before submitting.");
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type",
        width: "13%",
        Cell: () => <span>AED</span>,
      },
      {
        Header: "Brand/Model",
        accessor: "brand",
      },
      {
        Header: "Serial #",
        accessor: "serial_number",
      },
      {
        Header: "Placement",
        accessor: "placement",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: newTblData,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  printConsole({ tableInstance }); // print console.log
  return (
    <>
      <FinalNewBreadcrumbs />
      <div className="mt-4" style={{ width: "100%", paddingInline: "2%" }}>
        <h2 className="heading" style={{ textAlign: "center" }}>
          {siteName}
        </h2>

        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table table-bordered table-hover"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <th
                    style={{
                      width: "5%",
                      textAlign: "center",
                      backgroundColor: "rgb(153, 153, 153)",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={
                        selectedRows.length === newTblData.length &&
                        newTblData.length > 0
                      }
                      onChange={(e) => toggleAllRows(e.target.checked)}
                    />
                  </th>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ backgroundColor: "#999", width: column.width }}
                      className="tb-td"
                    >
                      <div className="d-flex align-items-center th-d">
                        {column.render("Header")}
                        <span className="ml-1">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SouthIcon size={2} />
                            ) : (
                              <NorthIcon size={2} />
                            )
                          ) : (
                            <SouthIcon size={2} style={{ opacity: 0.3 }} />
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {loading ? (
                [...Array(10)].map((_, i) => (
                  <tr key={i}>
                    {[...Array(5)].map((col, j) => (
                      <td
                        key={j}
                        style={{
                          backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                        }}
                        className="tb-td"
                      >
                        <Skeleton animation="wave" height={20} />
                      </td>
                    ))}
                  </tr>
                ))
              ) : newTblData.length === 0 ? (
                <tr
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontStyle: "italic",
                    color: "grey",
                  }}
                >
                  <td colSpan={columns.length + 1}>No data found</td>
                </tr>
              ) : (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      <td
                        style={{
                          width: "5%",
                          textAlign: "center",
                          backgroundColor:
                            row.index % 2 === 0 ? "white" : "#e4e4e4",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(row.original.id)}
                          onChange={() =>
                            toggleRowSelection(
                              row.original.id,
                              row.original.account_id
                            )
                          }
                        />
                      </td>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            backgroundColor:
                              row.index % 2 === 0 ? "white" : "#e4e4e4",
                          }}
                          className="tb-td"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>

        <div className="row pb-3 py-4" style={{ marginBottom: "6%" }}>
          <div className="col-12 content-flex-right">
            <button
              className="btn text-uppercase cancel-button"
              type="button"
              onClick={() => {
                handleRemoveDispatch();

                navigate(-1);
              }}
            >
              Cancel
            </button>
            <button
              className="btn text-uppercase ms-4 submit-button"
              type="button"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ScrollTopButton />
    </>
  );
};

export default AedServiceCheck;
