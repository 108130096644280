import React, { useEffect, useState } from "react";

const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    setIsVisible(window.scrollY > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="d-flex top-btn">
      {isVisible && (
        <img
          src="/assets/images/top-page.svg"
          width={40}
          height={40}
          onClick={scrollToTop}
          alt="scroll-top"
        />
      )}
    </div>
  );
};

export default ScrollTopButton;
