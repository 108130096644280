import * as React from 'react';
import '../../common/css/toogleSwitch.scss';
import { useState } from 'react';
import { useEffect } from 'react';

export default function CustomToggleButton({
    ToggleName,
    ToggleValue,
    changeHandler,
	is_read_only=0
}){
const disabledStyles = {
	opacity: is_read_only ? 0.5 : 1,
};

	return (
		<div  className={`toggle-button-cover ${is_read_only ? 'disabled' : ''}`} style={disabledStyles}>
			<div className="button-cover">
				<div className="button r" id="button-1">
					<input type="checkbox" className="checkbox" name={ToggleName} checked={ToggleValue || ToggleValue == 1} onChange={changeHandler}  value={1}  disabled={is_read_only} />
					<div className={`knobs ${is_read_only ? 'disabled': ''}`}></div>
					<div className="layer"></div>
				</div>
			</div>
		</div>
	);
}
